import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { cn } from '@asteria/utils-funcs/classes';

/** @type { React.FC<React.PropsWithChildren<{}>> } */
const Slider = React.memo(function Slider({ children }) {
	const [index, setIndex] = React.useState(0);
	const count = React.Children.count(children);

	const back = React.useCallback(
		() =>
			setIndex((value) => {
				const next = value - 1;
				return next < 0 ? count - 1 : next;
			}),
		[count],
	);

	const forward = React.useCallback(
		() =>
			setIndex((value) => {
				const next = value + 1;
				return next >= count ? 0 : next;
			}),
		[count],
	);

	const go = React.useCallback((index) => {
		setIndex(index);
	}, []);

	const node = children?.[index];

	return (
		<div
			className={cn(
				'flex flex-col gap-4',
				'asteria-component__carousel',
				'asteria-component__onboarding-slider',
			)}
		>
			<div
				className={cn(
					'asteria-component__carousel-content',
					'asteria-component__onboarding-slider-content',
				)}
			>
				{node}
			</div>
			{count > 1 ? (
				<div
					className={cn(
						'flex justify-center items-center gap-2',
						'asteria-component__carousel-buttons',
						'asteria-component__onboarding-slider-buttons',
					)}
				>
					<Button icon="chevron-left" size="sm" onClick={back} />
					<div className="flex items-center gap-2">
						{Array.from({ length: count }).map((_, i) => (
							<div
								key={i}
								className={cn(
									'cursor-pointer',
									'asteria-component__carousel-button',
									'asteria-component__onboarding-slider-button',
									{ 'asteria--state-active': i === index },
								)}
								onClick={() => go(i)}
							/>
						))}
					</div>
					<Button icon="chevron-right" size="sm" onClick={forward} />
				</div>
			) : null}
		</div>
	);
});

Slider.propTypes = { children: PropTypes.node };

export default Slider;
