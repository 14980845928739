import React from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { iconClasses } from '@asteria/component-core/icon';
import Slider from '@asteria/component-core/slider';
import Tooltip from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';
import {
	positionClasses,
	sizeClasses,
	stateClasses,
	statusClasses,
} from '@asteria/component-core/utils';
import * as SizeUtils from '@asteria/component-core/utils/size';

import Chip from '@asteria/component-chip';
import ComponentService from '@asteria/component-tools/contenter/service';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import ControlledWrapper from '../base/controlled';
import Error from '../base/error';
import Label from '../base/label';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { boolean } disabled
 * @property { 'sm' | 'md' | 'lg' } size
 * @property { boolean } active
 * @property { string } type
 * @property { string } name
 * @property { string } icon
 * @property { string } iconSize
 * @property { 'first' | 'last' } iconPosition
 * @property { string } placeholder
 * @property { string | { value: string, tooltip?: string } } label
 * @property { React.ReactNode | (value: unknown) => React.ReactNode } helpText
 * @property { React.MouseEventHandler } onFocus
 * @property { boolean } readonly
 * @property { React.MouseEventHandler } onIconClick
 * @property { React.MouseEventHandler } onChipDismiss
 * @property { React.ChangeEventHandler } onChange
 * @property { string | number } value
 * @property { string | number } visibleValue
 * @property { { type?: string, message?: string } } error
 * @property { string | React.ReactNode } iconTooltip
 * @property { boolean } floatingError
 * @property { boolean } autocomplete
 * @property { boolean } autoFocus
 * @property { 'vertical' | 'horizontal' } direction
 * @property { unknown } buttonRef
 * @property { unknown } fieldProps
 * @property { React.ReactNode } children
 * @property { boolean } stopPropagation
 * @property { number } step
 * @property { number } multiplier
 * @property { React.ReactNode } prefix
 * @property { React.ReactNode } postfix
 * @property { number } min
 * @property { number } max
 * @property { boolean } hideVisibleValueOnFocus
 * @property { string } formNamePrefix
 * @property { string } analyticsKey
 * @property { boolean } iconOnly
 */

const HelpText = React.memo((props) => {
	const { helpText, value } = props;

	if (!helpText) {
		return null;
	}

	if (typeof helpText === 'function') {
		return (
			<Text size="xs" className="asteria-component__input__help">
				{helpText(value)}
			</Text>
		);
	}

	return (
		<Text size="xs" className="asteria-component__input__help">
			{helpText}
		</Text>
	);
});

HelpText.displayName = 'HelpText';

HelpText.propTypes = {
	helpText: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
	size: SizeProp(),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const InputError = React.memo((props) => {
	const {
		name,
		formNamePrefix,
		error,
		floatingError,
		innerRef,
		isOpen,
		onClose,
	} = props;

	if (!error) {
		return null;
	}

	if (floatingError) {
		return (
			<Tooltip target={innerRef} open={isOpen} onClose={onClose}>
				<div className="asteria-component__input-error">
					<Error
						className="asteria-component__alert--level-flat"
						error={error}
						name={name}
						formNamePrefix={formNamePrefix}
					/>
				</div>
			</Tooltip>
		);
	}

	return (
		<div className="asteria-component__input-error">
			<Error error={error} name={name} formNamePrefix={formNamePrefix} />
		</div>
	);
});

InputError.displayName = 'InputError';

InputError.propTypes = {
	error: PropTypes.any,
	floatingError: PropTypes.bool,
	innerRef: PropTypes.any,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,

	name: PropTypes.string,
	formNamePrefix: PropTypes.string,
};

const Prefix = React.memo((props) => {
	const { value, className } = props;

	if (!value) {
		return null;
	}

	if (typeof value === 'string') {
		return (
			<Text
				className={cn(
					'asteria-component__input__wrapper__prefix',
					className,
				)}
			>
				{value}
			</Text>
		);
	}

	return value;
});

Prefix.displayName = 'Prefix';

Prefix.propTypes = {
	className: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const Inner = React.memo((props) => {
	const { type, value, prefix, postfix, onChipDismiss, inputProps } = props;

	const styles = React.useMemo(() => {
		if (type === 'range') {
			const min = inputProps?.min ?? 0;
			const max = inputProps?.max ?? 100;

			const mid = (max + min) / 2;

			const rangeValue = typeof value === 'number' ? value : mid;

			return {
				'--value': ((rangeValue - min) / (max - min)) * 100 + '%',
			};
		}

		return null;
	}, [inputProps?.max, inputProps?.min, type, value]);

	if (type === 'textarea') {
		return (
			<div className="asteria-component__input__wrapper">
				{prefix ? (
					<div className="asteria-component__input__wrapper__prefix--prefix">
						<Prefix value={prefix} />
					</div>
				) : null}

				<textarea {...inputProps}>{value}</textarea>
				{postfix ? (
					<div className="asteria-component__input__wrapper__prefix--postfix">
						<Prefix value={postfix} />
					</div>
				) : null}
			</div>
		);
	}

	if (type === 'chip' && value?.length) {
		return (
			<div className="asteria-component__input__wrapper">
				<Slider>
					{[]
						.concat(value)
						.filter(Boolean)
						.map((object) => (
							<Chip
								key={object}
								label={object}
								dismiss
								size="sm"
								onDismiss={(event) => {
									event.preventDefault();
									event.stopPropagation();
									return onChipDismiss?.(object);
								}}
							/>
						))}
				</Slider>
			</div>
		);
	}

	return (
		<div className="asteria-component__input__wrapper">
			{prefix ? (
				<div className="asteria-component__input__wrapper__prefix--prefix">
					<Prefix value={prefix} />
				</div>
			) : null}

			<input {...inputProps} value={value} type={type} style={styles} />
			{postfix ? (
				<div className="asteria-component__input__wrapper__prefix--postfix">
					<Prefix value={postfix} />
				</div>
			) : null}
		</div>
	);
});

Inner.displayName = 'Inner';

Inner.propTypes = {
	type: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChipDismiss: PropTypes.func,
	inputProps: PropTypes.object,

	prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	postfix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

/** @type { React.FC<Props> } */
const Input = React.memo(
	React.forwardRef((props, ref) => {
		const {
			className,
			disabled,
			size,
			active: propActive,
			type,
			name,
			icon,
			iconSize,
			// minLength,
			// maxLength,
			iconPosition = 'first',
			placeholder,
			label,
			helpText,

			readonly,
			onIconClick,
			onChipDismiss,
			onChange,
			value: $value,
			visibleValue,
			error,
			iconTooltip,
			floatingError,
			autocomplete = false,
			direction,

			buttonRef,
			fieldProps = {},
			children,
			stopPropagation,

			step,
			multiplier,

			prefix,
			postfix,
			autoFocus,
			min,
			max,

			iconOnly,

			hideVisibleValueOnFocus,

			formNamePrefix,

			analyticsKey,
		} = props;

		const onFocus = props?.onFocus ?? fieldProps?.onFocus;
		const onBlur = fieldProps?.onBlur;

		const [showError, setShowError] = React.useState(true);
		const [active, setActive] = React.useState(propActive);
		const innerRef = React.useRef(null);

		const onHideError = React.useCallback(() => setShowError(false), []);

		React.useEffect(() => {
			setActive(propActive);
		}, [propActive]);

		React.useEffect(() => setShowError(true), [error]);

		const handleFocus = React.useCallback(
			(event) => {
				Analytics.event('form.input.focus', {
					name: event.target.name,
					analyticsKey: analyticsKey || event.target.name,
				});

				setActive(true);
				setShowError(false);

				return onFocus?.(event);
			},
			[analyticsKey, onFocus],
		);

		const handleBlur = React.useCallback(
			(event) => {
				Analytics.event('form.input.blur', {
					name: event.target.name,
					analyticsKey: analyticsKey || event.target.name,
				});

				setActive(false);

				return onBlur?.(event);
			},
			[analyticsKey, onBlur],
		);

		const value = React.useMemo(() => {
			let value = $value;

			if (multiplier) {
				return Math.ceil(value / multiplier);
			}

			return value;
		}, [$value, multiplier]);

		const handleChange = React.useCallback(
			(event) => {
				if (multiplier) {
					event.target.value *= multiplier;
				}

				Analytics.event('form.input.change', {
					value: event.target.value,
					multiplier: multiplier,
					name: event.target.name,
					analyticsKey: analyticsKey || event.target.name,
				});

				return onChange?.(event);
			},
			[analyticsKey, multiplier, onChange],
		);

		const handleLabelClick = React.useCallback(
			(e) => {
				if (stopPropagation) {
					e.preventDefault();
					e.stopPropagation();
				}
			},
			[stopPropagation],
		);

		const inputProps = React.useMemo(
			() => ({
				name: name,
				...fieldProps,
				step: step,
				disabled: disabled,
				autoFocus: autoFocus,
				placeholder: placeholder,
				// minLength: minLength,
				// maxLength: maxLength,
				// helpText: helpText,
				readOnly: readonly,
				ref: ref,
				onChange: handleChange,
				onFocus: handleFocus,
				onBlur: handleBlur,
				autoComplete: autocomplete === false ? 'off' : autocomplete,
				min: min,
				max: max,
			}),
			[
				autocomplete,
				disabled,
				fieldProps,
				handleBlur,
				handleChange,
				handleFocus,
				max,
				min,
				name,
				placeholder,
				readonly,
				ref,
				step,
				autoFocus,
			],
		);

		return (
			<div
				className={cn(
					'asteria-component__input',
					sizeClasses(props),
					{
						[`asteria-component__input--type-${type}`]: type,
						[`asteria-component__input--icon-position-${iconPosition}`]:
							iconPosition,
						[`asteria-component__input--has-icon`]: icon,
						[`asteria-component__input--has-prefix`]: prefix,
						[`asteria-component__input--has-postfix`]: postfix,
						[`asteria-state--has-label`]: label || helpText,
						[`asteria-state--direction-${direction}`]: direction,
						'asteria--state-has-visible':
							visibleValue &&
							(!hideVisibleValueOnFocus || !active),
						'asteria--state-icon-only': iconOnly,
					},
					iconClasses('asteria-component__input', props),
					stateClasses({ ...props, active: active, error: error }),
					statusClasses({
						info: error?.type === 'info',
						success: error?.type === 'success',
						error: [
							'minLength',
							'maxLength',
							'required',
							'error',
						].includes(error?.type),
						warning: error?.type === 'warning',
					}),
					className,
				)}
				data-visible={visibleValue}
			>
				<label
					className={cn(
						className,
						'asteria-component__label__wrapper',
						{
							[`asteria-component__label__wrapper--direction-${direction}`]:
								direction,
						},
					)}
					onClick={handleLabelClick}
				>
					{label || helpText ? (
						<Group direction="horizontal" verticalAlign="center">
							<Label
								size={size}
								active={active}
								disabled={disabled}
								tooltip={label?.tooltip}
							>
								{label?.value ?? label}
							</Label>
							<HelpText
								helpText={helpText}
								size={size}
								value={value}
							/>
						</Group>
					) : null}

					<div
						className="asteria-component__input__inner"
						ref={innerRef}
						data-visible={visibleValue}
					>
						<Inner
							type={type}
							value={value}
							onChipDismiss={onChipDismiss}
							inputProps={inputProps}
							prefix={prefix}
							postfix={postfix}
						/>

						{icon && (
							<Button
								className={cn(
									'asteria-component__button--variant-input',
									positionClasses({ position: iconPosition }),
								)}
								size={
									iconSize ??
									SizeUtils.decrease(size, iconOnly ? 0 : 1, {
										min: 'sm',
									})
								}
								icon={icon}
								variant="link"
								disabled={disabled}
								onClick={onIconClick}
								ref={buttonRef}
								tooltip={iconTooltip}
							/>
						)}

						{children}
					</div>
				</label>
				<InputError
					name={name}
					error={error}
					floatingError={floatingError}
					innerRef={innerRef}
					isOpen={showError}
					onClose={onHideError}
					formNamePrefix={formNamePrefix}
				/>
			</div>
		);
	}),
);

Input.displayName = 'Input';

Input.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	size: SizeProp(),
	active: PropTypes.bool,
	type: PropTypes.string,
	name: PropTypes.string,
	icon: PropTypes.string,
	iconSize: PropTypes.string,
	iconPosition: PropTypes.oneOf(['first', 'last']),
	placeholder: PropTypes.string,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({ value: PropTypes.string, tooltip: PropTypes.string }),
	]),
	helpText: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

	onFocus: PropTypes.func,

	readonly: PropTypes.bool,
	onIconClick: PropTypes.func,
	onChipDismiss: PropTypes.func,
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	visibleValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	error: PropTypes.shape({
		type: PropTypes.string,
		message: PropTypes.string,
	}),
	iconTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	floatingError: PropTypes.bool,
	autocomplete: PropTypes.bool,
	autoFocus: PropTypes.bool,
	direction: PropTypes.oneOf(['vertical', 'horizontal']),

	buttonRef: PropTypes.object,
	fieldProps: PropTypes.object,
	children: PropTypes.node,
	stopPropagation: PropTypes.bool,

	step: PropTypes.number,
	multiplier: PropTypes.number,

	prefix: PropTypes.node,
	postfix: PropTypes.node,

	min: PropTypes.number,
	max: PropTypes.number,

	hideVisibleValueOnFocus: PropTypes.bool,

	formNamePrefix: PropTypes.string,
	analyticsKey: PropTypes.string,

	iconOnly: PropTypes.bool,
};

Input.defaultProps = {
	value: '',
	type: 'text',
	iconPosition: 'first',
	size: 'md',
};

const Component = ControlledWrapper(Input);

ComponentService.register('Input', Component, {
	className: { type: 'string' },
	disabled: { type: 'boolean' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
	active: { type: 'boolean' },
	type: { type: 'string' },
	name: { type: 'string' },
	icon: { type: 'string' },
	iconSize: { type: 'string' },
	iconPosition: { type: 'enum', options: ['first', 'last'] },
	placeholder: { type: 'string' },
	label: { type: 'string' },
	helpText: { type: 'function' },

	onFocus: { type: 'function' },

	readonly: { type: 'boolean' },
	onIconClick: { type: 'function' },
	onChipDismiss: { type: 'function' },
	onChange: { type: 'function' },
	value: { type: 'string' },
	visibleValue: { type: 'string' },
	error: {
		type: 'object',
		options: {
			type: { type: 'string' },
			message: { type: 'string' },
		},
	},
	iconTooltip: { type: 'string' },
	floatingError: { type: 'boolean' },
	autocomplete: { type: 'boolean' },
	autoFocus: { type: 'boolean' },
	direction: { type: 'enum', options: ['vertical', 'horizontal'] },

	buttonRef: { type: 'object' },
	fieldProps: { type: 'object' },
	children: { type: 'node' },
	stopPropagation: { type: 'boolean' },

	step: { type: 'number' },
	multiplier: { type: 'number' },

	prefix: { type: 'node' },
	postfix: { type: 'node' },

	min: { type: 'number' },
	max: { type: 'number' },

	hideVisibleValueOnFocus: { type: 'boolean' },

	formNamePrefix: { type: 'string' },
	analyticsKey: { type: 'string' },

	iconOnly: { type: 'boolean' },
});

export default Component;
export { Input };
