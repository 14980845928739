import React from 'react';

import { useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import * as FormatUtils from '@asteria/utils-funcs/format';

import Slider from '../../../components/slider';
import Help from '../components/help';
import { useCategoryColors, useForecasterFuture } from '../hooks';

import './styles.scss';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

const ForecastAction = (props) => {
	const { action, onAction } = props;

	const colors = useCategoryColors({
		type: action?.extra?.type,
		category: action?.tag?.category?.name,
		tag: action?.tag?.name,
	});

	const tabLabel = FormatUtils.formatTag({
		type: action?.extra?.type,
		category: action?.tag?.category?.name,
		tag: action?.tag?.name,
	});

	const translationOptions = React.useMemo(() => {
		if (!action) {
			return {};
		}

		return {
			postfix: {
				type: action?.extra?.type,
				category: action?.tag?.category?.name,
				tag: action?.tag?.name,
				zero: !action.total,
			},
			data: {
				name: tabLabel,
				impact: (action.total / action.typeTotal) * 100,
				total: action.total,
			},
		};
	}, [action, tabLabel]);

	const actionId = action?._id ?? action?.id;

	const redirectToForecast = React.useCallback(() => {
		onAction?.('card:action', {
			type: 'forecast-actions',
			data: {
				type: action?.extra?.type,
				id: action?.tag?._id,
				actionId: actionId,
			},
		});
	}, [action?.extra?.type, action?.tag?._id, actionId, onAction]);

	const close = useMutation({
		mutationFn: async () =>
			onAction?.('forecast:actions:delete', action?._id),
	});

	const check = useMutation({
		mutationFn: async () =>
			onAction?.('forecast:actions:delete', action?._id),
	});

	return (
		<div
			className={cn(
				'flex flex-col w-full gap-4 p-2 items-center',
				'asteria-component__forecast-actions__action',
			)}
		>
			<Chip
				colors={colors}
				label={tabLabel}
				variant="fill"
				onClick={redirectToForecast}
				size="sm"
			/>
			<Translation
				className="text-center"
				translationKey="card.content.forecast-actions.action.text"
				translationOptions={translationOptions}
				Component={Text}
				size="md"
			/>
			<div className="flex flex-row gap-2">
				<Button
					icon="close"
					variant="tertiary"
					onClick={close.mutate}
					disabled={close.isLoading || check.isLoading}
				/>
				<Button
					icon="check"
					variant="primary"
					onClick={check.mutate}
					disabled={close.isLoading || check.isLoading}
				/>
			</div>
		</div>
	);
};

ForecastAction.propTypes = {
	action: PropTypes.object,
	onAction: PropTypes.func,
};

/** @type { React.FC<Props> } */
const ForecastActionsCardContent = React.memo(
	function ForecastActionsCardContent(props) {
		const {
			onAction,
			query,
			onSubmit,
			translationOptions: $translationOptions,
			dataloader,
		} = props;

		const forecasterQuery = useForecasterFuture({ onSubmit, dataloader });

		const sourceScenarioData = useSelector(
			ScenarioStore.selectors.sourceScenarioData,
		);

		const translationOptions = React.useMemo(
			() => ({
				...$translationOptions,
				data: {
					...$translationOptions?.data,
					scenario: sourceScenarioData,
				},
			}),
			[$translationOptions, sourceScenarioData],
		);

		const processActions = React.useCallback((data = {}) => {
			if (!data?.actions) {
				return [];
			}

			return data?.actions;
		}, []);

		const actions = React.useMemo(() => {
			const processedActions = processActions(query?.data);

			return processedActions
				.map((action) => {
					const type = action?.extra?.type ?? '';
					const category = action?.tag?.category?.name ?? '';
					const tag = action?.tag?.name ?? '';

					const typeData =
						forecasterQuery?.data?.[type.toLowerCase()]?.data ?? {};

					const typeTotal = Object.values(typeData).reduce(
						(acc, date) => {
							return acc + date?.FORECAST?.value ?? 0;
						},
						0,
					);

					const { total, count } = Object.values(typeData).reduce(
						(acc, date) => {
							const tagData =
								date?.FORECAST?.categories?.[category]?.tags?.[
									tag
								] ?? {};

							return {
								total: acc.total + (tagData.value ?? 0),
								count: acc.count + 1,
							};
						},
						{ total: 0, count: 0 },
					);

					return {
						...action,
						total: type === 'WITHDRAW' ? total : -total,
						typeTotal: typeTotal,
						impact: total / typeTotal,
						avg:
							type === 'WITHDRAW'
								? Math.round(total / count)
								: -Math.round(total / count),
					};
				})
				.sort(({ impact: a }, { impact: b }) => b - a);
		}, [processActions, query?.data, forecasterQuery?.data]);

		return (
			<div className="flex flex-col w-full gap-4">
				<Translation
					className="text-center mb-4"
					translationKey="card.content.forecast-actions.description"
					translationOptions={translationOptions}
					Component={Text}
					size="md"
				/>

				<Slider items={actions}>
					{({ action }) => (
						<ForecastAction
							action={action}
							translationOptions={translationOptions}
							onAction={onAction}
						/>
					)}
				</Slider>

				<Help translationOptions={translationOptions} />
			</div>
		);
	},
);

ForecastActionsCardContent.displayName = 'ForecastActionsCardContent';

ForecastActionsCardContent.propTypes = {
	query: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	translationOptions: PropTypes.object,
	dataloader: PropTypes.object,
};

export default ForecastActionsCardContent;
