import React from 'react';

import { Route, Routes } from 'react-router-dom';

import PropTypes from 'prop-types';

import StepIntroduction from './introduction';
import StepPlugin from './plugin';
import StepSetup from './setup';
import StepSoftware from './software';
import StepStatus from './status';
import StepToken from './token';
import StepTray from './tray';

const VismaAdministrationPage = React.memo(function Page(props) {
	return (
		<Routes location={location}>
			<Route index element={<StepIntroduction {...props} />} />
			<Route path="software" element={<StepSoftware {...props} />} />
			<Route path=":id/software" element={<StepSoftware {...props} />} />
			<Route path="plugin" element={<StepPlugin {...props} />} />
			<Route path=":id/plugin" element={<StepPlugin {...props} />} />
			<Route path="setup" element={<StepSetup {...props} />} />
			<Route path=":id/setup" element={<StepSetup {...props} />} />
			<Route path=":id" element={<StepIntroduction {...props} />} />
			<Route path=":id/token" element={<StepToken {...props} />} />
			<Route path=":id/tray" element={<StepTray {...props} />} />
			<Route path=":id/status" element={<StepStatus {...props} />} />
		</Routes>
	);
});

VismaAdministrationPage.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default VismaAdministrationPage;
