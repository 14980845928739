import React from 'react';

import { useParams } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import BaseStep from './base';

const StepSetup = React.memo(function StepSetup(props) {
	const { onAction, onSubmit, onClose } = props;

	const { id } = useParams();

	const type = 'erp';
	const key = 'visma.administration';

	const onAbort = React.useCallback((event) => onClose?.(event), [onClose]);
	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const next = useMutation({
		mutationFn: async () => {
			if (id) {
				return id;
			}

			const response = await onSubmit?.('integrations:create', {
				type: type,
				key: key,
			});

			return response?._id ?? response?.id;
		},
		onSuccess: (id) =>
			onAction?.(
				'go',
				`/onboarding/erp/visma.administration/configuration/${id}/token`,
			),
	});

	return (
		<BaseStep
			{...props}
			step="setup"
			onAbort={onAbort}
			onBack={onBack}
			onNext={next.mutate}
			loading={next.isLoading}
		/>
	);
});

StepSetup.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

export default StepSetup;
