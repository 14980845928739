import { useSelector } from 'react-redux';

import { isBefore, parseISO, subMonths } from 'date-fns';
import { isEqual } from 'lodash-es';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

export function useIntegrationStatus(id) {
	return useSelector((store) => {
		const object = IntegrationStore.selectors.integration(store, id);

		if (!object) {
			return null;
		}

		const status = object?.status?.state;
		const errors = object?.config?.errors;
		const connected = object?.config?.connected;
		const disabled = object?.disabled;
		const lastSync = object?.lastSync;

		if (errors?.length || status === 'ERROR') {
			return 'error';
		}

		if (disabled) {
			return 'disabled';
		}

		if (!connected) {
			if (lastSync) {
				return 'importing';
			}

			return 'initiating';
		}

		if (status === 'INITIATING') {
			return 'initiating';
		}

		if (status === 'IMPORTING') {
			return 'importing';
		}

		if (lastSync) {
			if (isBefore(parseISO(object.lastSync), subMonths(new Date(), 1))) {
				return 'outdated';
			}
		}

		return 'connected';
	}, isEqual);
}
