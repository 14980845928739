import React from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { IntegrationSuccessFeedback } from '../success';

import BaseStep from './base';

const type = 'erp';
const key = 'visma.administration';

const StepStatus = React.memo(function StepStatus(props) {
	const { onAction, onClose } = props;

	const { id } = useParams();

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, id),
		(a, b) => isEqual(a, b),
	);

	const data = React.useMemo(
		() => ({ integration: integration }),
		[integration],
	);

	return (
		<BaseStep {...props} step="success" onDone={onClose} data={data}>
			<IntegrationSuccessFeedback
				type={type}
				name={key}
				onAction={onAction}
			/>
		</BaseStep>
	);
});

StepStatus.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

export default StepStatus;
