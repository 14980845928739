import React from 'react';

import PropTypes from 'prop-types';

import { Content } from '@asteria/component-core/wrapper';

import { Wrapper as FormWrapper } from '@asteria/component-form';

import IntegrationField from './field';

/** @type { React.FC<{ type: string, name: string, flow: unknown }> } */
const ConfigurationDynamic = React.memo(function ConfigurationDynamic(props) {
	const { type, name: key, flow } = props;

	return (
		<FormWrapper>
			<Content>
				{(flow?.fields ?? []).map((field) => (
					<IntegrationField
						key={field.key}
						field={field}
						type={type}
						integrationKey={key}
					/>
				))}
			</Content>
		</FormWrapper>
	);
});

ConfigurationDynamic.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
	flow: PropTypes.object,
};

export default ConfigurationDynamic;
