import React from 'react';

import { useQueries } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { DataLoader } from '@asteria/core';

import { TranslationService } from '@asteria/language';
import { defaultBulkCallback } from '@asteria/utils-graphql';
import { useQueryDoneCallback } from '@asteria/utils-hooks/useQueryDoneCallback';

import * as TranslationsAPI from '../api/translations';
import { TranslationContext } from '../context';

const DATALOADER = {
	fn: new DataLoader(defaultBulkCallback, { period: 0 }),
	context: { waiting: true },
	onSuccess: (response) => ({ response }),
};

/** @type { React.FC<React.PropsWithChildren<{ partnerId: string, language: string, enabled: boolean }>> } */
const TranslationsWrapper = React.memo(function TranslationsWrapper(props) {
	const { children, partnerId, enabled, language: defaultLanguage } = props;

	const [language, setLanguage] = React.useState(defaultLanguage);

	const queries = useQueries({
		queries: [
			{
				queryKey: ['widget', 'language', partnerId ?? 'NONE', 'en'],
				queryFn: async ({ signal }) =>
					TranslationsAPI.fetch({
						language: 'en',
						partnerId: partnerId,
						signal: signal,
						dataloader: DATALOADER,
					}),
				enabled: enabled,
			},
			{
				queryKey: ['widget', 'language', partnerId ?? 'NONE', 'sv'],
				queryFn: async ({ signal }) =>
					TranslationsAPI.fetch({
						language: 'sv',
						partnerId: partnerId,
						signal: signal,
						dataloader: DATALOADER,
					}),
				enabled: enabled,
			},
			{
				queryKey: ['widget', 'language', partnerId ?? 'NONE', language],
				queryFn: async ({ signal }) =>
					TranslationsAPI.fetch({
						language: language,
						partnerId: partnerId,
						signal: signal,
						dataloader: DATALOADER,
					}),
				enabled: enabled && !!language,
				placeholderData: null,
			},
		],
	});

	const query = queries.at(-1);

	useQueryDoneCallback(
		query,
		React.useCallback(
			(data) => {
				TranslationService.code = language;
				TranslationService.setTranslations(data);
			},
			[language],
		),
	);

	const ctx = React.useMemo(
		() => ({
			language: language,
			onChange: setLanguage,
			translations: query.data,
		}),
		[language, query.data],
	);

	if (enabled && query?.data === null) {
		return null;
	}

	return (
		<TranslationContext.Provider value={ctx}>
			{children}
		</TranslationContext.Provider>
	);
});

TranslationsWrapper.propTypes = {
	children: PropTypes.node,
	partnerId: PropTypes.string,
	language: PropTypes.string,
	enabled: PropTypes.bool,
};

export default TranslationsWrapper;
