import React from 'react';

import PropTypes from 'prop-types';

import { BadgeWrapper } from '@asteria/component-core/badge';
import Button from '@asteria/component-core/button';

import { useUnreadMessage } from '@asteria/component-support/hooks';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef Props
 * @property { string } className
 * @property { (action: string, data: unknown) => unknown } onAction
 * @property { (action: string, data: unknown) => unknown } onSubmit
 * @property { unknown } extra
 */

/** @type { React.FC<Props> } */
const ButtonHelp = React.memo(function ButtonHelp(props) {
	const { className, onAction, onSubmit, extra } = props;

	const count = useUnreadMessage({ onSubmit });

	const onClick = React.useCallback(
		() => onAction?.('support:chat:open', extra),
		[extra, onAction],
	);

	return (
		<FeatureFlag feature="onboarding-chat">
			<div className={cn('self-end', className)}>
				<BadgeWrapper badge={count} size="sm">
					<Button icon="chat" onClick={onClick} />
				</BadgeWrapper>
			</div>
		</FeatureFlag>
	);
});

ButtonHelp.displayName = 'ButtonHelp';

ButtonHelp.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	extra: PropTypes.object,
};

export default ButtonHelp;
