function formatValue(data) {
	return {
		count: data?.count ?? 0,
		total: data?.total?.display?.[0]?.total ?? 0,
	};
}

function format(response) {
	return {
		health: response?.health?.[0],
		source: {
			summary: formatValue(response?.source?.statistics?.summary),
			paid: formatValue(response?.source?.statistics?.paid),
			unpaid: formatValue(response?.source?.statistics?.unpaid),
			overdue: formatValue(response?.source?.statistics?.overdue),
			forecast: formatValue(response?.source?.statistics?.forecast),
			unsent: formatValue(response?.source?.statistics?.unsent),
			forecast_match: formatValue(
				response?.source?.statistics?.forecast_match,
			),
			credit: formatValue(response?.source_credit?.statistics?.summary),

			customers: {
				summary: formatValue(
					response?.source_customers?.statistics?.summary,
				),
				paid: formatValue(response?.source_customers?.statistics?.paid),
				unpaid: formatValue(
					response?.source_customers?.statistics?.unpaid,
				),
				overdue: formatValue(
					response?.source_customers?.statistics?.overdue,
				),
				forecast: formatValue(
					response?.source_customers?.statistics?.forecast,
				),
				unsent: formatValue(
					response?.source_customers?.statistics?.unsent,
				),
				forecast_match: formatValue(
					response?.source_customers?.statistics?.forecast_match,
				),
				credit: formatValue(
					response?.source_customers_credit?.statistics?.summary,
				),
			},
			suppliers: {
				summary: formatValue(
					response?.source_suppliers?.statistics?.summary,
				),
				paid: formatValue(response?.source_suppliers?.statistics?.paid),
				unpaid: formatValue(
					response?.source_suppliers?.statistics?.unpaid,
				),
				overdue: formatValue(
					response?.source_suppliers?.statistics?.overdue,
				),
				forecast: formatValue(
					response?.source_suppliers?.statistics?.forecast,
				),
				unsent: formatValue(
					response?.source_suppliers?.statistics?.unsent,
				),
				forecast_match: formatValue(
					response?.source_suppliers?.statistics?.forecast_match,
				),
				credit: formatValue(
					response?.source_suppliers_credit?.statistics?.summary,
				),
			},
		},
		target: {
			summary: formatValue(response?.target?.statistics?.summary),
			paid: formatValue(response?.target?.statistics?.paid),
			unpaid: formatValue(response?.target?.statistics?.unpaid),
			overdue: formatValue(response?.target?.statistics?.overdue),
			forecast: formatValue(response?.target?.statistics?.forecast),
			unsent: formatValue(response?.target?.statistics?.unsent),
			forecast_match: formatValue(
				response?.target?.statistics?.forecast_match,
			),
			credit: formatValue(response?.target_credit?.statistics?.summary),

			customers: {
				summary: formatValue(
					response?.target_customers?.statistics?.summary,
				),
				paid: formatValue(response?.target_customers?.statistics?.paid),
				unpaid: formatValue(
					response?.target_customers?.statistics?.unpaid,
				),
				overdue: formatValue(
					response?.target_customers?.statistics?.overdue,
				),
				forecast: formatValue(
					response?.target_customers?.statistics?.forecast,
				),
				unsent: formatValue(
					response?.target_customers?.statistics?.unsent,
				),
				forecast_match: formatValue(
					response?.target_customers?.statistics?.forecast_match,
				),
				credit: formatValue(
					response?.target_customers_credit?.statistics?.summary,
				),
			},
			suppliers: {
				summary: formatValue(
					response?.target_suppliers?.statistics?.summary,
				),
				paid: formatValue(response?.target_suppliers?.statistics?.paid),
				unpaid: formatValue(
					response?.target_suppliers?.statistics?.unpaid,
				),
				overdue: formatValue(
					response?.target_suppliers?.statistics?.overdue,
				),
				forecast: formatValue(
					response?.target_suppliers?.statistics?.forecast,
				),
				unsent: formatValue(
					response?.target_suppliers?.statistics?.unsent,
				),
				forecast_match: formatValue(
					response?.target_suppliers?.statistics?.forecast_match,
				),
				credit: formatValue(
					response?.target_suppliers_credit?.statistics?.summary,
				),
			},
		},
	};
}

export default format;
