import { TranslationService } from '@asteria/language';

export function onUserLoad(data) {
	TranslationService.updateGlobals({ user: data });
}

export function onCompanyLoad(data) {
	TranslationService.updateGlobals({ company: data });
}

export function onIntegrationsLoad(data) {
	TranslationService.updateGlobals({ integrations: data });
}
