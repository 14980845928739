import { useQueries, useQuery } from '@tanstack/react-query';

export function useUnreadMessage({ onSubmit }) {
	const chatQuery = useQuery({
		queryKey: ['conversations'],
		queryFn: async () =>
			onSubmit?.('conversation:list', { filters: { status: 'PENDING' } }),
		select: (response) => (response?.edges ?? []).map(({ node }) => node),

		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: false,

		placeholderData: [],
	});

	const queries = useQueries({
		queries: (chatQuery.data ?? []).map((object) => {
			const id = object?._id ?? object?.id;

			return {
				queryKey: ['chat', id],
				queryFn: async () => onSubmit?.('conversation:chat', { id }),

				select: (response) => {
					return (response ?? []).filter((message) => {
						return (
							message?.createdBy?.type !== 'UserToken' &&
							!message?.read
						);
					}).length;
				},

				refetchOnMount: true,
				refetchOnReconnect: true,
				refetchOnWindowFocus: false,

				placeholderData: [],
			};
		}),
	});

	const count = queries.reduce((acc, { data }) => acc + (data ?? 0), 0);

	if (!count) {
		return null;
	}

	if (count > 9) {
		return '9+';
	}

	return count;
}
