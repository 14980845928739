import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import { useQueries } from '@tanstack/react-query';
import {
	endOfMonth,
	format,
	formatISO,
	parseISO,
	startOfMonth,
} from 'date-fns';
import { cloneDeep } from 'lodash-es';

import { DataLoader } from '@asteria/core';

import * as AccountStore from '@asteria/datalayer/stores/accounts';
import * as AppStore from '@asteria/datalayer/stores/app';
import * as GraphStore from '@asteria/datalayer/stores/graph';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { TranslationService } from '@asteria/language';
import { decode } from '@asteria/utils-funcs/jwt';
import * as TimesizeUtils from '@asteria/utils-funcs/timesize';
import { defaultBulkCallback } from '@asteria/utils-graphql';

import * as AccountsAPI from '../api/accounts';
import * as CashflowAPI from '../api/cashflow';
import * as ClientsAPI from '../api/clients';
import * as CompanyAPI from '../api/company';
import * as CurrenciesAPI from '../api/currencies';
import * as IntegrationsAPI from '../api/integrations';
import * as ScenarioAPI from '../api/scenario';
import * as TagsAPI from '../api/tags';
import * as TransactionsAPI from '../api/transactions';
import * as UserAPI from '../api/user';

function useDoneCallback(query, callback) {
	React.useEffect(() => {
		if (query?.isFetched && !query?.isLoading) {
			callback?.(query?.data);
		}
	}, [callback, query?.data, query?.isFetched, query?.isLoading]);
}

const DATALOADER = {
	fn: new DataLoader(defaultBulkCallback, { period: 0 }),
	context: { waiting: true },
	onSuccess: (response) => ({ response }),
};

const Datalayer = React.memo(function Datalayer() {
	const dispatch = useDispatch();
	const store = useStore();

	const accessToken = useSelector(AppStore.selectors.accessToken);

	const [
		userQuery,
		companyQuery,
		accountsQuery,
		integrationsQuery,
		scenariosQuery,
		tagsQuery,
		clientsQuery,
		transactionCurrenciesQuery,
		currenciesQuery,
	] = useQueries({
		queries: [
			{
				queryKey: ['widget', accessToken, 'user'],
				queryFn: async () =>
					UserAPI.me({ accessToken, dataloader: DATALOADER }),

				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
			},
			{
				queryKey: ['widget', accessToken, 'company'],
				queryFn: async () =>
					CompanyAPI.fetch({ accessToken, dataloader: DATALOADER }),

				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
			},
			{
				queryKey: ['widget', accessToken, 'accounts'],
				queryFn: async () =>
					AccountsAPI.fetch({ accessToken, dataloader: DATALOADER }),

				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
			},
			{
				queryKey: ['widget', accessToken, 'integrations'],
				queryFn: async () =>
					IntegrationsAPI.fetch({
						accessToken,
						dataloader: DATALOADER,
					}),

				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
			},
			{
				queryKey: ['widget', accessToken, 'scenarios'],
				queryFn: async () =>
					ScenarioAPI.fetch({ accessToken, dataloader: DATALOADER }),

				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
			},
			{
				queryKey: ['widget', accessToken, 'tags'],
				queryFn: async () =>
					TagsAPI.fetch({ accessToken, dataloader: DATALOADER }),

				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
			},
			{
				queryKey: ['widget', accessToken, 'clients'],
				queryFn: async () =>
					ClientsAPI.fetch({ accessToken, dataloader: DATALOADER }),

				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
			},
			{
				queryKey: ['widget', accessToken, 'transactions', 'currencies'],
				queryFn: async () =>
					TransactionsAPI.currencies({
						accessToken,
						dataloader: DATALOADER,
					}),

				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
			},
			{
				queryKey: ['widget', accessToken, 'currencies'],
				queryFn: async () =>
					CurrenciesAPI.fetch({
						accessToken,
						dataloader: DATALOADER,
					}),

				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
			},
		],
	});

	const scenarioId = userQuery?.data?.settings?.flags?.scenarioId;

	const [, cashflowStatisticsQuery] = useQueries({
		queries: [
			{
				queryKey: [
					'widget',
					accessToken,
					'user',
					'cashflow',
					scenarioId,
				],
				queryFn: async () => {
					const startDate = formatISO(startOfMonth(new Date()), {
						representation: 'date',
					});

					const dates = store.getState()?.graph?.data?.range ?? [];
					const timeSize = store.getState()?.app?.timesize ?? 'month';

					const endDate = dates?.length
						? format(
								TimesizeUtils.endOfTime(
									parseISO(startDate),
									timeSize,
								),
								'yyyy-MM-dd',
						  )
						: formatISO(endOfMonth(new Date()), {
								representation: 'date',
						  });

					return CashflowAPI.fetch({
						startDate: startDate,
						endDate: `${endDate}T23:59:59.999Z`,
						scenarioId: scenarioId,
						accessToken: accessToken,
						dispatch: dispatch,
						skipLoading: true,
					});
				},

				refetchOnWindowFocus: false,
				refetchOnReconnect: false,

				enabled: userQuery.isFetched && !userQuery.isLoading,
			},
			{
				queryKey: [
					'widget',
					accessToken,
					'user',
					'cashflow',
					scenarioId,
					'statistics',
				],
				queryFn: async () =>
					CashflowAPI.statistics({
						scenarioId: scenarioId,
						accessToken: accessToken,
						dataloader: DATALOADER,
					}),

				refetchOnWindowFocus: false,
				refetchOnReconnect: false,

				enabled: userQuery.isFetched && !userQuery.isLoading,
			},
		],
	});

	const companyId = decode(accessToken)?.companyId ?? null;

	useDoneCallback(
		userQuery,
		React.useCallback(
			(data) => {
				dispatch?.(AppStore.setUser(cloneDeep(data)));
				dispatch?.(AppStore.setUserCompanyId(companyId));
			},
			[companyId, dispatch],
		),
	);

	useDoneCallback(
		cashflowStatisticsQuery,
		React.useCallback(
			(data) => {
				dispatch?.(GraphStore.setStatistics(cloneDeep(data)));
			},
			[dispatch],
		),
	);

	useDoneCallback(
		companyQuery,
		React.useCallback(
			(data) => {
				dispatch?.(AppStore.setCompany(cloneDeep(data)));
				TranslationService.updateGlobals({ company: data });
			},
			[dispatch],
		),
	);

	useDoneCallback(
		accountsQuery,
		React.useCallback(
			(data) => {
				const credit = Math.round(
					data.reduce(
						(total, account) =>
							total + (account?.sums?.display?.credit || 0),
						0,
					),
				);

				dispatch?.(AccountStore.setCredit(credit));
				dispatch?.(AccountStore.setAccounts(cloneDeep(data)));
			},
			[dispatch],
		),
	);

	useDoneCallback(
		integrationsQuery,
		React.useCallback(
			(data) => {
				dispatch?.(IntegrationStore.setIntegrations(cloneDeep(data)));
			},
			[dispatch],
		),
	);

	useDoneCallback(
		scenariosQuery,
		React.useCallback(
			(data) => {
				dispatch?.(ScenarioStore.setScenarios(cloneDeep(data)));
			},
			[dispatch],
		),
	);

	useDoneCallback(
		tagsQuery,
		React.useCallback(
			(data) => {
				dispatch?.(AppStore.setTags(cloneDeep(data)));
			},
			[dispatch],
		),
	);

	useDoneCallback(
		clientsQuery,
		React.useCallback(
			(data) => {
				dispatch?.(AppStore.setClients(cloneDeep(data)));
			},
			[dispatch],
		),
	);

	useDoneCallback(
		transactionCurrenciesQuery,
		React.useCallback(
			(data) => {
				if (data) {
					dispatch?.(AppStore.setCurrencies(cloneDeep(data)));
				}
			},
			[dispatch],
		),
	);

	useDoneCallback(
		currenciesQuery,
		React.useCallback(
			(data) => {
				dispatch?.(
					AppStore.setSettings({ currencies: cloneDeep(data) }),
				);
			},
			[dispatch],
		),
	);

	return null;
});

export default Datalayer;
