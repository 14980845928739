function format(response, credit) {
	return {
		PAID: {
			count: response?.PAID?.count ?? 0,
			total: response?.PAID?.sums?.original?.total ?? 0,
			currency: response?.PAID?.sums?.original?.currency ?? 'SEK',
		},
		FORECAST: {
			count: response?.FORECAST?.count ?? 0,
			total: response?.FORECAST?.sums?.original?.total ?? 0,
			currency: response?.FORECAST?.sums?.original?.currency ?? 'SEK',
			min: response?.FORECAST?.min?.original?.total,
			max: response?.FORECAST?.max?.original?.total,
		},
		UNPAID: {
			count: response?.UNPAID?.count ?? 0,
			total: response?.UNPAID?.sums?.original?.total ?? 0,
			currency: response?.UNPAID?.sums?.original?.currency ?? 'SEK',
		},
		OVERDUE: {
			count: response?.OVERDUE?.count ?? 0,
			total: response?.OVERDUE?.sums?.original?.total ?? 0,
			currency: response?.OVERDUE?.sums?.original?.currency ?? 'SEK',
		},
		CREDIT: {
			total:
				credit?.PAID?.sums?.original?.total ??
				credit?.FORECAST?.sums?.original?.total ??
				0,
			currency:
				credit?.PAID?.sums?.original?.currency ??
				credit?.FORECAST?.sums?.original?.currency ??
				'SEK',
		},
	};
}

export default format;
