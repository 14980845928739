import React from 'react';

import { useSelector, useStore } from 'react-redux';

import { useMutation, useQuery } from '@tanstack/react-query';

import {
	formatResponse,
	validateFormValues,
} from '@asteria/component-forecaster/utils';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import * as FormatUtils from '@asteria/utils-funcs/format';

export function useRequest({ onSubmit, dataloader }) {
	return useQuery({
		queryKey: ['card', 'forecast-actions'],
		queryFn: async ({ meta }) => {
			return onSubmit?.('card:fetch', {
				type: 'forecast-actions',
				dataloader: meta?.dataloader,
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		meta: { dataloader },
	});
}

export function useRemoveMutation({ onSubmit }) {
	return useMutation({
		mutationFn: async (actionId) =>
			onSubmit?.('forecast:actions:delete', actionId),
	});
}

export function useForecasterFuture({ onSubmit, dataloader }) {
	const store = useStore();

	const sourceScenario = ScenarioStore.selectors.sourceScenario(
		store.getState(),
	);

	const sourceScenarioData = ScenarioStore.selectors.sourceScenarioData(
		store.getState(),
	);

	return useQuery({
		queryKey: ['forecaster', 'future', { sourceScenario }],
		queryFn: async ({ meta }) =>
			onSubmit?.('forecaster:changes', {
				scenarioName: sourceScenario,
				dataloader: meta?.dataloader,
			})
				.then((response) => {
					return formatResponse(response, { store });
				})
				.then((data) => ({ ...data, scenario: sourceScenario }))
				.then((data) => validateFormValues(data, sourceScenarioData))
				.then((data) => {
					return data;
				}),

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		placeholderData: null,
		meta: { dataloader },
	});
}

export function useCategoryColors({ type, category, tag }) {
	const object = useSelector((store) =>
		ForecasterStore.selectors.tags.object(store, {
			type: type,
			categoryName: category,
			tagName: tag,
		}),
	);

	const tagColor = useSelector((store) =>
		ForecasterStore.selectors.tags.color(store, {
			categoryName: category,
			tagName: tag,
		}),
	);

	return React.useMemo(() => {
		const colors = [];

		if (tagColor) {
			return colors.concat([tagColor]);
		}

		if (object?.color) {
			return colors.concat(object?.color);
		}

		return colors.concat([
			type,
			FormatUtils.replace([category, tag]).join('-'),
		]);
	}, [tagColor, object?.color, type, category, tag]);
}
