import React from 'react';

import { Route, Routes } from 'react-router-dom';

import PropTypes from 'prop-types';

import StepIntroduction from './introduction';
import StepOS from './os';
import {
	StepMacOSX,
	StepMacOSXContinue,
	StepMacOSXPrint,
	StepMacOSXWaiting,
} from './os-mac';
import {
	StepWindows,
	StepWindowsAuthentication,
	StepWindowsConfigure,
	StepWindowsWaiting,
} from './os-windows';
import StepSuccess from './success';

const PrinterPage = React.memo(function PrinterPage(props) {
	return (
		<Routes>
			<Route index element={<StepIntroduction {...props} />} />
			<Route path="/os" element={<StepOS {...props} />} />
			<Route path="/success" element={<StepSuccess {...props} />} />
			<Route path="/os/windows" element={<StepWindows {...props} />} />
			<Route
				path="/os/windows/configure"
				element={<StepWindowsConfigure {...props} />}
			/>
			<Route
				path="/os/windows/authentication"
				element={<StepWindowsAuthentication {...props} />}
			/>
			<Route
				path="/os/windows/waiting"
				element={<StepWindowsWaiting {...props} />}
			/>
			<Route path="/os/macosx" element={<StepMacOSX {...props} />} />
			<Route
				path="/os/macosx/continue"
				element={<StepMacOSXContinue {...props} />}
			/>
			<Route
				path="/os/macosx/print"
				element={<StepMacOSXPrint {...props} />}
			/>
			<Route
				path="/os/macosx/waiting"
				element={<StepMacOSXWaiting {...props} />}
			/>
		</Routes>
	);
});

PrinterPage.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PrinterPage;
