import React from 'react';

import PropTypes from 'prop-types';

import { Checkbox, Input } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';

/** @type { React.FC<{ field: unknown, integrationKey: string, type: string }> } */
const IntegrationField = React.memo(function IntegrationField(props) {
	const { field, integrationKey, type } = props;

	const label = React.useMemo(() => {
		if (!field?.label) {
			return null;
		}

		const label = field?.label?.value ?? field?.label;
		const translation = TranslationService.get(
			[
				label,
				`integrations.${label}`,
				`integrations.add.${label}`,
				[type, label].join('.'),
				`integrations.${type}.${label}`,
				`integrations.${type}.add.${label}`,
				[integrationKey, label].join('.'),
				`integrations.${integrationKey}.${label}`,
				`integrations.${integrationKey}.add.${label}`,
				[type, integrationKey, label].join('.'),
				`integrations.${type}.${integrationKey}.${label}`,
				`integrations.${type}.${integrationKey}.add.${label}`,
			],
			label,
			{ ...field, type: type, key: integrationKey },
		);

		if (typeof field?.label === 'object') {
			return { ...field?.label, value: translation };
		}

		return translation;
	}, [field, integrationKey, type]);

	const placeholder = React.useMemo(() => {
		if (!field?.placeholder) {
			return null;
		}

		return TranslationService.get(
			[
				field?.placeholder,
				`integrations.${field?.placeholder}`,
				`integrations.add.${field?.placeholder}`,
				[type, field?.placeholder].join('.'),
				`integrations.${type}.${field?.placeholder}`,
				`integrations.${type}.add.${field?.placeholder}`,
				[integrationKey, field?.placeholder].join('.'),
				`integrations.${integrationKey}.${field?.placeholder}`,
				`integrations.${integrationKey}.add.${field?.placeholder}`,
				[type, integrationKey, field?.placeholder].join('.'),
				`integrations.${type}.${integrationKey}.${field?.placeholder}`,
				`integrations.${type}.${integrationKey}.add.${field?.placeholder}`,
			],
			field?.placeholder,
			{ ...field, type: type, key: integrationKey },
		);
	}, [field, integrationKey, type]);

	const InputProps = {
		name: field?.key,
		label: label,
		placeholder: placeholder,
		required: field?.flags?.required,
		readonly: field?.flags?.readonly,
		disabled: field?.flags?.disabled,
	};

	if (field?.type === 'checkbox') {
		return (
			<Checkbox
				{...InputProps}
				value={field?.value}
				iconPosition={field?.iconPosition ?? 'first'}
			/>
		);
	}

	return (
		<Input
			{...InputProps}
			direction={field?.direction ?? 'vertical'}
			type={field?.type}
		/>
	);
});

IntegrationField.propTypes = {
	type: PropTypes.string,
	integrationKey: PropTypes.string,
	field: PropTypes.shape({
		key: PropTypes.string,
		type: PropTypes.string,
		value: PropTypes.any,
		iconPosition: PropTypes.string,
		direction: PropTypes.string,
		label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		placeholder: PropTypes.string,
		flags: PropTypes.shape({
			required: PropTypes.bool,
			readonly: PropTypes.bool,
			disabled: PropTypes.bool,
		}),
	}),
};

export default IntegrationField;
