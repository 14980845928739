import React from 'react';

import PropTypes from 'prop-types';

import BaseStep from './base';

const StepIntroduction = React.memo(function StepIntroduction(props) {
	const { onAction, onClose } = props;

	const onAbort = React.useCallback((event) => onClose?.(event), [onClose]);
	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(
		async () =>
			onAction?.('go', `/onboarding/erp/printer/configuration/os`),
		[onAction],
	);

	return (
		<BaseStep
			{...props}
			step="info"
			onAbort={onAbort}
			onBack={onBack}
			onNext={onNext}
		/>
	);
});

StepIntroduction.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

export default StepIntroduction;
