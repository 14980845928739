import React from 'react';

import PropTypes from 'prop-types';

import { Content } from '@asteria/component-core/wrapper';

import { Wrapper as FormWrapper } from '@asteria/component-form';

import IntegrationField from './field';

/** @type { React.FC<{ type: string, name: string, flow: unknown }> } */
const ConfigurationBasic = React.memo(function ConfigurationBasic(props) {
	const { type, name: key } = props;

	return (
		<FormWrapper>
			<Content>
				{[
					{
						key: 'config.client.API-User',
						label: 'label.username',
						flags: { required: true },
					},
					{
						key: 'config.client.API-Key',
						label: 'label.password',
						type: 'password',
						flags: { required: true },
					},
				].map((config) => (
					<IntegrationField
						key={config.key}
						type={type}
						integrationKey={key}
						field={config}
					/>
				))}
			</Content>
		</FormWrapper>
	);
});

ConfigurationBasic.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
	flow: PropTypes.object,
};

export default ConfigurationBasic;
