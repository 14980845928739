import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ModalStore from '@asteria/datalayer/stores/modals';

export function useClose() {
	const dispatch = useDispatch();

	return useCallback(
		(event, options) => {
			dispatch(ModalStore.close({ size: options?.size }));
			dispatch(AppStore.setStatement(null));
		},
		[dispatch],
	);
}
