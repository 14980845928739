import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { Input } from '@asteria/component-form';

import * as SnackbarStore from '@asteria/datalayer/stores/snackbar';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useFlow } from '../../hooks';

/** @type { React.FC<{ integration: unknown }> } */
const CustomIntegration = React.memo(function CustomIntegration(props) {
	const { integration } = props;

	const dispatch = useDispatch();

	const [hasCopied, setCopied] = React.useState(false);

	const flow = useFlow(integration?.type, integration?.key);

	const copyToken = React.useCallback(() => {
		navigator.clipboard.writeText(
			integration?.config?.server?.integrationAccessToken,
		);

		SnackbarStore.show(dispatch, {
			title: 'snackbar.integration.token.copy.title',
			content: 'snackbar.integration.token.copy.content',
			type: 'integration-token-copy',
			variant: 'success',
			icon: 'clipboard',
			hideActions: true,
		});

		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 4000);
	}, [dispatch, integration?.config?.server?.integrationAccessToken]);

	if (flow?.flow !== 'custom') {
		return null;
	}

	return (
		<div
			className={cn(
				'flex flex-col gap-4 w-full items-center justify-center p-6 bg-slate-100 rounded-md',
				'asteria-component__onboarding-connection-custom',
			)}
		>
			<Input
				direction="vertical"
				value={integration?.config?.server?.integrationAccessToken}
				icon="clipboard"
				iconPosition="last"
				iconTooltip={TranslationService.get(
					'integrations.connecting.token.tooltip',
				)}
				onIconClick={copyToken}
				readonly
				uncontrolled
			/>
			<Translation
				translationKey={
					hasCopied
						? 'integrations.connecting.token.copied'
						: 'integrations.connecting.token.copy'
				}
				Component={Text}
				size="sm"
				align="center"
			/>
		</div>
	);
});

CustomIntegration.propTypes = { integration: PropTypes.object };

export default CustomIntegration;
