import React from 'react';

import PropTypes from 'prop-types';

import Tooltip from '@asteria/component-core/tooltip';
import { sizeClasses } from '@asteria/component-core/utils';

import { cn } from '@asteria/utils-funcs/classes';

// import useInterval from '@asteria/utils-hooks/useInterval';
import { Text } from '../../typography';

import IndeterminateCircle from './IndeterminateCircle';
import { useColor } from './utils';

import '../index.scss';

const CircleComponent = React.memo((props) => {
	const {
		className,
		progress,
		tooltip,
		// animationSpeed,
		// animateOnRender,
		color,
		children = null,
		empty = false,
		onClick,
	} = props;

	// const [renderValue, renderValueSet] = useState(
	// 	animateOnRender ? 0 : progress,
	// );
	// const [lastTarget, lastTargetSet] = useState(
	// 	animateOnRender ? 0 : progress,
	// );
	// const [lastUpdateTime, lastUpdateTimeSet] = useState(new Date().getTime());

	// useEffect(() => {
	// 	lastUpdateTimeSet(new Date().getTime() - 16);
	// 	lastTargetSet(renderValue);
	// }, [progress]);

	// useInterval(() => {
	// 	const currentTime = new Date().getTime();
	// 	const absoluteProgress =
	// 		(currentTime - lastUpdateTime) / animationSpeed;

	// 	if (absoluteProgress >= 1) {
	// 		renderValueSet(progress);
	// 	} else {
	// 		renderValueSet(
	// 			lastTarget + (progress - lastTarget) * absoluteProgress,
	// 		);
	// 	}
	// }, 16);

	const { style: colorStyle, className: colorClassName } = useColor(color);
	const style = { ...colorStyle, '--progress__value': `${progress}%` };

	const progressElement = (
		<div
			className={cn(
				'asteria-component__progress asteria-component__progress--variant-circle',
				className,
				colorClassName,
				sizeClasses(props),
			)}
			style={style}
			onClick={onClick}
		>
			<div className="asteria-component__progress__value">
				{!empty ? (
					children ? (
						children
					) : (
						<Text>
							{progress !== -1 && `${parseInt(progress, 10)}%`}
						</Text>
					)
				) : null}
			</div>
		</div>
	);

	if (tooltip) {
		return (
			<Tooltip>
				{progressElement}
				{tooltip}
			</Tooltip>
		);
	}

	return progressElement;
});

CircleComponent.displayName = 'CircleComponent';

CircleComponent.propTypes = {
	className: PropTypes.string,
	animateOnRender: PropTypes.bool,
	animationSpeed: PropTypes.number,
	progress: PropTypes.number,
	tooltip: PropTypes.node,
	color: PropTypes.shape({
		type: PropTypes.string,
		value: PropTypes.string,
	}),
	children: PropTypes.node,
	onClick: PropTypes.func,
	empty: PropTypes.bool,
};

/** @type { React.FC<{ className?: string, progress?: number, color?: string | string[] }> } */
const Circle = React.memo((props) => {
	const { className, progress, color } = props;

	if (progress === -1) {
		return <IndeterminateCircle className={className} color={color} />;
	}

	return <CircleComponent {...props} />;
});

Circle.displayName = 'Circle';

Circle.propTypes = {
	className: PropTypes.string,
	animateOnRender: PropTypes.bool,
	animationSpeed: PropTypes.number,
	progress: PropTypes.number,
	tooltip: PropTypes.node,
	color: PropTypes.shape({
		type: PropTypes.string,
		value: PropTypes.string,
	}),
	children: PropTypes.node,
	onClick: PropTypes.func,
	empty: PropTypes.bool,
};

Circle.defaultProps = {
	animateOnRender: true,
	animationSpeed: 500,
	tooltip: null,
	progress: 0,
};

export default Circle;
