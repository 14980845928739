import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { ViewportList } from 'react-viewport-list';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';
import { getItemBoundingClientRect } from '@asteria/component-core/utils/viewportList';

import Chip from '@asteria/component-chip';
import Form, { Datepicker, useFormValues } from '@asteria/component-form';
import List, { ListCell, ListFilter, ListItem } from '@asteria/component-list';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import {
	findParentByClassname,
	findScrollingParent,
} from '@asteria/utils-funcs/node';

import { useClients, useDateVariantPostfix, useStatistics } from './hooks';
import * as selectors from './selectors';
import ClientSorting from './sorting';
import ClientSplash from './splash';

import './styles.scss';

const ClientListItem = React.memo((props) => {
	const { object, onSelect, loading } = props;

	const ID = object?._id ?? object?.id;

	const onClick = React.useCallback(() => onSelect?.(ID), [ID, onSelect]);

	return (
		<ListItem>
			<ListCell className="asteria--type-name">
				<Text size="sm">
					{TranslationService.get(
						[
							'clients.list.item.label',
							'clients.list.item.name.label',
						],
						'{{{ name }}}',
						object,
					)}
				</Text>
			</ListCell>
			<ListCell className="asteria--type-revenue">
				{loading ? (
					<span className="asteria-component__skeleton" />
				) : (
					<Text size="sm">
						{TranslationService.get(
							[
								'clients.list.item.label',
								'clients.list.item.revenue.label',
							],
							'{{ revenue|number:false:false:true:true:false }}',
							object,
						)}
					</Text>
				)}
			</ListCell>
			<ListCell className="asteria--type-action">
				<Button size="sm" icon="chevron-right" onClick={onClick} />
			</ListCell>
		</ListItem>
	);
});

ClientListItem.displayName = 'ClientListItem';
ClientListItem.propTypes = {
	object: PropTypes.object,
	onSelect: PropTypes.func,
	loading: PropTypes.bool,
};

const ClientDatepicker = React.memo(function ClientDatepicker() {
	return (
		<Datepicker
			iconOnly
			skipVisibleValue
			name="date"
			size="sm"
			variant="range"
		>
			{({ open, onOpen, onClose, toggleRef }) => (
				<Button
					icon="date"
					size="sm"
					onClick={open ? onClose : onOpen}
					ref={toggleRef}
				/>
			)}
		</Datepicker>
	);
});

const QuickActions = React.memo(function QuickActions({ onAction }) {
	const date = useFormValues({ name: 'date' });

	const dateVariant = useDateVariantPostfix(date);

	const onDateChipDismiss = React.useCallback(
		() => onAction?.('chip:dismiss', { type: 'date' }),
		[onAction],
	);

	return (
		<>
			{date?.startDate ? (
				<Chip
					key="quick-date"
					label={TranslationService.getV2(
						['clients.list.quick.actions.date'],
						{ postfix: { variant: dateVariant }, data: { date } },
					)}
					size="sm"
					dismiss
					onDismiss={onDateChipDismiss}
				/>
			) : null}
		</>
	);
});

QuickActions.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const ClientList = React.memo(function ClientList(props) {
	const { className, onAction, onSubmit } = props;

	const { setValue } = useFormContext();

	const store = useStore();
	const dispatch = useDispatch();

	const ref = React.useRef(null);
	const viewportRef = React.useRef(null);

	const type = useSelector(selectors.type, (a, b) => isEqual(a, b));

	const count = useSelector(
		(store) => AppStore.selectors.clients(store).length,
	);

	const query = useStatistics({ onSubmit });
	const clients = useClients({ query });

	const hasErp = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, { type: 'erp' })
				.length,
	);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'chip:dismiss') {
				setValue('date', null);
				return;
			}

			return onAction?.(action, data);
		},
		[onAction, setValue],
	);

	const onChangeSettings = React.useCallback(
		(object) => () => {
			if (object?.sorting) {
				handleAction?.('chip:dismiss', { type: 'date' });
			}

			const settings = selectors.settings(store.getState());

			dispatch(
				AppStore.setSettings({ clients: { ...settings, ...object } }),
			);
		},
		[dispatch, handleAction, store],
	);

	const onSelect = React.useCallback(
		(ID) => onAction?.('clients:select', ID),
		[onAction],
	);

	const renderSpacer = React.useCallback(
		({ ref, style }) => (
			<div
				className={cn(
					'asteria-component__viewport-spacer',
					'asteria-component__clients-list-spacer',
				)}
				ref={ref}
				style={style}
			/>
		),
		[],
	);

	React.useLayoutEffect(() => {
		let node = findScrollingParent(ref.current);

		if (!node) {
			node = findParentByClassname(
				ref.current,
				'asteria-component__modal',
			);

			if (node) {
				node = node.querySelector(
					'.asteria-component__wrapper-content',
				);
			}
		}

		if (!node) {
			node = findParentByClassname(ref.current, 'asteria-content-view');
		}

		viewportRef.current = node ?? false;
	}, []);

	if (!clients?.length || !hasErp) {
		return <ClientSplash onAction={handleAction} />;
	}

	return (
		<Group className="asteria-component__clients-list" ref={ref}>
			{count ? (
				<Group
					direction="horizontal"
					horizontalAlign="space-between"
					className={cn(
						'asteria-component__clients-list__actions',
						'asteria-component__chip-group',
					)}
				>
					<Group
						direction="horizontal"
						horizontalAlign="left"
						className={cn(
							'asteria-component__clients-list__action',
							'asteria--type-selector',
						)}
					>
						<Chip
							size="sm"
							label={TranslationService.get([
								'clients.list.selector.label',
								'clients.list.selector.customers.label',
							])}
							onClick={onChangeSettings({ type: 'CUSTOMER' })}
							active={type === 'CUSTOMER'}
						/>
						<Chip
							size="sm"
							label={TranslationService.get([
								'clients.list.selector.label',
								'clients.list.selector.suppliers.label',
							])}
							onClick={onChangeSettings({ type: 'SUPPLIER' })}
							active={type === 'SUPPLIER'}
						/>
					</Group>
					<Group
						direction="horizontal"
						verticalAlign="center"
						horizontalAlign="center"
						className={cn(
							'asteria-component__clients-list__action',
							'asteria--type-sorting',
						)}
					>
						<ClientSorting onChange={onChangeSettings} />
						<ClientDatepicker
							onAction={handleAction}
							onSubmit={onSubmit}
						/>
					</Group>
				</Group>
			) : null}
			<Group
				direction="horizontal"
				verticalAlign="center"
				horizontalAlign="left"
			>
				<QuickActions onAction={handleAction} onSubmit={onSubmit} />
			</Group>
			<List
				className={cn(
					'asteria-component__clients-list__table',
					className,
				)}
				applyTypographySizes={false}
				size="lg"
			>
				<ListFilter>
					<ListItem>
						<ListCell className="asteria--type-name">
							<Text size="sm">
								{TranslationService.get([
									'clients.list.header.label',
									'clients.list.header.name.label',
								])}
							</Text>
						</ListCell>
						<ListCell className="asteria--type-revenue">
							<Text size="sm">
								{TranslationService.get([
									'clients.list.header.label',
									'clients.list.header.revenue.label',
								])}
							</Text>
						</ListCell>
						<ListCell className="asteria--type-action" />
					</ListItem>
				</ListFilter>

				{viewportRef.current !== false ? (
					<ViewportList
						items={clients}
						viewportRef={viewportRef}
						itemSize={40}
						overscan={10}
						scrollThreshold={40}
						getItemBoundingClientRect={getItemBoundingClientRect}
						renderSpacer={renderSpacer}
					>
						{(object) => (
							<ClientListItem
								key={object?._id ?? object?.id}
								object={object}
								onSelect={onSelect}
								loading={query.isFetching}
							/>
						)}
					</ViewportList>
				) : (
					clients.map((object) => (
						<ClientListItem
							key={object?._id ?? object?.id}
							object={object}
							onSelect={onSelect}
							loading={query.isFetching}
						/>
					))
				)}
			</List>
		</Group>
	);
});

ClientList.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

ClientList.defaultProps = {};

const ClientListForm = React.memo(function ClientListForm(props) {
	return (
		<Form values={props?.values} defaultValues={props?.defaultValues}>
			<ClientList {...props} />
		</Form>
	);
});

ClientListForm.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	values: PropTypes.object,
	defaultValues: PropTypes.object,
};

export default ClientListForm;
