import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { PERFORMANCE_STATE } from './constants';

/**
 * @typedef { import('./types').Props } Props
 */

/**
 * @param { Pick<Props, 'startDate' | 'endDate' | 'onSubmit'> } options
 */
export function useRequest({ onSubmit, startDate, endDate, dataloader }) {
	const scenario = useSelector(
		ScenarioStore.selectors.sourceScenario,
		(a, b) => isEqual(a, b),
	);

	return useQuery({
		queryKey: [
			'card',
			'forecast-status',
			{ scenario },
			{ startDate, endDate },
		],
		queryFn: async ({ meta }) => {
			return onSubmit?.('card:fetch', {
				type: 'forecast-status',
				source: {
					scenario: '$base',
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					scenario: scenario,
					startDate: startDate,
					endDate: endDate,
				},
				dataloader: meta?.dataloader,
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		placeholderData: { source: {}, target: {} },

		enabled: !!startDate && !!endDate,

		meta: { dataloader },
	});
}

export function useTrendsDirection(source, target) {
	if (1 - Math.min(source, target) / Math.max(source, target) < 0.01) {
		return 'equal';
	}

	if (source > target) {
		return 'up';
	}

	return 'down';
}

export function useAccuracy() {
	return {
		diff: { value: null, percentage: null },
		multiplier: null,
		state: null,
		reached: null,
	};
}

export function usePerformance() {
	return {
		value: null,
		percentage: null,
		state: PERFORMANCE_STATE[Math.floor(Math.random() * 4)],
	};
}
