import React from 'react';

import TextStep from './base-text';
import WaitingStep from './base-waiting';

const StepWindows = React.memo(function StepWindows(props) {
	return (
		<TextStep
			{...props}
			os="windows"
			step="os/windows/add"
			nextStep="os/windows/configure"
		/>
	);
});

const StepWindowsConfigure = React.memo(function StepWindowsConfigure(props) {
	return (
		<TextStep
			{...props}
			os="windows"
			step="os/windows/configure"
			nextStep="os/windows/authentication"
		/>
	);
});

const StepWindowsAuthentication = React.memo(function StepWindowsAuthentication(
	props,
) {
	return (
		<TextStep
			{...props}
			os="windows"
			step="os/windows/authentication"
			nextStep="os/windows/waiting"
		/>
	);
});

const StepWindowsWaiting = React.memo(function StepWindowsWaiting(props) {
	return <WaitingStep {...props} os="windows" step="os/windows/waiting" />;
});

export {
	StepWindows,
	StepWindowsAuthentication,
	StepWindowsConfigure,
	StepWindowsWaiting,
};
