import React from 'react';

import PropTypes from 'prop-types';

import Contenter from '@asteria/component-tools/contenter';

import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef Props
 * @property { string } className
 * @property { 'image' | 'contenter' } type
 * @property { string } src
 * @property { unknown } content
 */

/** @type { React.FC<Props> } */
const Placeholder = React.memo(function Placeholder(props) {
	const { type, className, src, content } = props;

	const style = React.useMemo(
		() => ({
			backgroundImage: type === 'background' ? `url(${src})` : null,
		}),
		[src, type],
	);

	const imageStyle = React.useMemo(
		() => ({ backgroundImage: `url(${src})` }),
		[src],
	);

	return (
		<div
			className={cn(
				'asteria-component__onboarding-placeholder',
				{ [`asteria--type-${type}`]: type },
				className,
			)}
			style={style}
		>
			{type === 'image' ? (
				<div
					className={cn(
						'bg-right bg-no-repeat bg-contain h-full',
						'asteria-component__onboarding-placeholder-image',
					)}
					style={imageStyle}
				/>
			) : null}
			{type === 'contenter' ? (
				<Contenter
					className="asteria-component__onboarding-placeholder-contenter"
					content={content}
				/>
			) : null}
		</div>
	);
});

Placeholder.displayName = 'Placeholder';

Placeholder.propTypes = {
	className: PropTypes.string,
	content: PropTypes.any,
	src: PropTypes.string,
	type: PropTypes.string,
};

export default Placeholder;
