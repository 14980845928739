import React from 'react';

import PropTypes from 'prop-types';

import ConversationModal from '@asteria/component-conversation';

import { TranslationService } from '@asteria/language';

import { LayoutContext } from '../context';

async function fetchChatId({ onSubmit, data }) {
	const response = await onSubmit?.('conversation:list', {
		filters: { status: 'PENDING' },
	});

	let chatId = response?.edges?.[0]?.node?._id;

	if (!chatId) {
		const response = await onSubmit?.('conversation:create', {});

		chatId = response?.data?.[0]?._id;
	}

	if (data) {
		let message = [];

		if (data?.from === 'onboarding') {
			message.push(`<b><i>Onboarding details</i></b>`);

			if (data?.step) {
				message.push(`<b>Step:</b> ${data?.step}`);
			}

			if (data?.type) {
				message.push(`<b>Type:</b> ${data?.type}`);
			}

			if (data?.key) {
				message.push(`<b>Key:</b> ${data?.key}`);
			}

			if (data?.id) {
				message.push(`<b>Integration ID:</b> ${data?.id}`);
			}
		}

		await onSubmit?.('message:send', {
			id: chatId,
			message: message.join('\n<br/>'),
			type: 'config',
		});
	}

	return chatId;
}

/** @type { React.FC<React.PropsWithChildren<{}>> } */
const ChatModalWrapper = React.memo(function ChatModalWrapper({ children }) {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const [id, setId] = React.useState(null);

	const close = React.useCallback(() => {
		setId(null);
	}, []);

	const open = React.useCallback((state) => {
		setId(state);
	}, []);

	const handleAction = React.useCallback(
		async (action, data) => {
			if (action === 'support:chat:open') {
				const id = await fetchChatId({ onSubmit, data });
				open(id);

				return;
			}

			return onAction?.(action, data);
		},
		[onAction, onSubmit, open],
	);

	const handleSubmit = React.useCallback(
		(action, data) => onSubmit?.(action, data),
		[onSubmit],
	);

	const ctx = React.useMemo(
		() => ({ onAction: handleAction, onSubmit: handleSubmit }),
		[handleAction, handleSubmit],
	);

	return (
		<LayoutContext.Provider value={ctx}>
			<ConversationModal
				onAction={handleAction}
				onSubmit={handleSubmit}
				open={id !== null}
				onClose={close}
				id={id}
				type="support"
				info={TranslationService.get('support.conversation.info.text')}
			/>
			{typeof children === 'function' ? children(ctx) : children}
		</LayoutContext.Provider>
	);
});

ChatModalWrapper.propTypes = { children: PropTypes.node };

export default ChatModalWrapper;
