import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { Title as BaseTitle } from '@asteria/component-core/typography';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { Translation } from '@asteria/language';
import { useIntervalIndex } from '@asteria/utils-hooks/useInterval';

import { useIntegrationName } from '../../hooks';

/**
 * @typedef { import('./types').Integration } Integration
 *
 * @typedef FmtIntegration
 * @property { string } id
 * @property { string } status
 * @property { boolean } connected
 */

const WAITING_TIMEOUT = 4_000;

/**
 * @param { Integration } object
 * @returns { FmtIntegration }
 */
function formatIntegrationLoading(object) {
	return {
		id: object?._id ?? object?.id,
		status: object?.status?.state,
		connected: object?.config?.connected,
	};
}

/**
 * @returns { FmtIntegration[] }
 */
function useIntegrationLoading() {
	const [integrations, setIntegrations] = React.useState([]);

	/** @type { FmtIntegration[] } */
	const available = useSelector(
		(store) => {
			const erp = IntegrationStore.selectors
				.integrations(store, {
					type: 'erp',
					filters: [
						{ status: 'IDLE', disabled: false },
						{ status: 'INITIATING', disabled: false },
					],
				})
				.map(formatIntegrationLoading);

			const bank = IntegrationStore.selectors
				.integrations(store, {
					type: 'bank',
					filters: [
						{ status: 'IDLE', disabled: false },
						{ status: 'INITIATING', disabled: false },
					],
				})
				.map(formatIntegrationLoading);

			return [].concat(erp).concat(bank);
		},
		(a, b) => isEqual(a, b),
	);

	React.useEffect(() => {
		const timeoutRef = setTimeout(() => {
			setIntegrations(
				available.filter(
					(integration) =>
						!(
							integration?.status === 'IDLE' &&
							integration?.connected === true
						),
				),
			);
		}, available.length * WAITING_TIMEOUT);

		return () => {
			clearTimeout(timeoutRef);
		};
	}, [available]);

	return integrations;
}

/** @type { React.FC<{ id: string, translationOptions: unknown }> } */
const IntegrationTitle = React.memo(function IntegrationTitle(props) {
	const { id, translationOptions } = props;

	const { state, object: integration } = useSelector(
		(store) => {
			const object = IntegrationStore.selectors.integration(store, id);

			let state = null;

			if (object?.status?.state === 'IDLE') {
				if (object?.config?.connected) {
					state = 'connected';
				} else {
					state = 'created';
				}
			} else {
				state = 'loading';
			}

			return { state, object };
		},
		(a, b) => isEqual(a, b),
	);

	const type = integration?.type;
	const key = integration?.key;

	const integrationName = useIntegrationName(type, key);

	if (!integration) {
		return null;
	}

	return (
		<Translation
			translationKey={[
				'card.content.title',
				'card.content.title.onboarding-loading',
			]}
			translationOptions={{
				...translationOptions,
				postfix: {
					...translationOptions?.postfix,
					status: state,
					'integration-key': key,
					'integration-type': type,
				},
				data: {
					...translationOptions?.data,
					integration: integration,
					integrationName: integrationName,
				},
			}}
			showWhenEmpty={false}
			Component={BaseTitle}
		/>
	);
});

IntegrationTitle.propTypes = {
	translationOptions: PropTypes.object,
	id: PropTypes.string,
};

/** @type { React.FC<{ integrations: FmtIntegration[], translationOptions: unknown }> } */
const Title = React.memo(function Title(props) {
	const { integrations, translationOptions } = props;

	const index = useIntervalIndex(integrations.length, WAITING_TIMEOUT);

	if (!integrations.length) {
		return null;
	}

	return (
		<IntegrationTitle
			id={integrations?.[index]?.id}
			translationOptions={translationOptions}
		/>
	);
});

Title.propTypes = {
	translationOptions: PropTypes.object,
	integrations: PropTypes.arrayOf(PropTypes.object),
};

export default Title;
export { IntegrationTitle, useIntegrationLoading };
