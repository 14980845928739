import React from 'react';

import PropTypes from 'prop-types';

import BaseStep from './base';

const TextStep = React.memo(function TextStep(props) {
	const { onAction, onClose, nextStep } = props;

	const onAbort = React.useCallback((event) => onClose?.(event), [onClose]);
	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(() => {
		const next = [`/onboarding/erp/printer/configuration`, nextStep]
			.filter(Boolean)
			.join('/');

		return onAction?.('go', next);
	}, [nextStep, onAction]);

	return (
		<BaseStep
			{...props}
			onAbort={onAbort}
			onBack={onBack}
			onNext={onNext}
		/>
	);
});

TextStep.propTypes = {
	nextStep: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

export default TextStep;
