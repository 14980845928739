import slice from './slice';

export { slice, slice as integrationsSlice };

export * as selectors from './selectors';

// Action creators are generated for each case reducer function
export const { setIntegrations } = slice.actions;

export default slice.reducer;
