import React from 'react';

import { useLocation } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import MissingFormWrapper from './form';
import MissingSuccess from './success';

/**
 * @typedef Props
 * @property { string } className
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { import('react').MouseEventHandler } onClose
 */

/** @type { React.FC<Props> } */
const PageMissing = React.memo(function PageMissing(props) {
	const { onSubmit } = props;

	const location = useLocation();

	const submit = useMutation({
		mutationFn: async (form) => {
			const integration = form?.customSoftware ?? form?.software;

			return onSubmit?.('integrations:missing', { title: integration });
		},
	});

	const type = location?.state?.type;

	if (submit?.data) {
		return (
			<MissingSuccess
				{...props}
				type={type}
				response={submit?.data?.erp}
			/>
		);
	}

	return (
		<MissingFormWrapper
			{...props}
			type={type}
			submit={submit.mutate}
			loading={submit.isLoading}
		/>
	);
});

PageMissing.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PageMissing;
