import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { ViewportList } from 'react-viewport-list';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { DropdownContext } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import { getItemBoundingClientRect } from '@asteria/component-core/utils/viewportList';

import Chip from '@asteria/component-chip';
import { useFeature } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import SearchSelector, { SearchSelectorItem } from './base';

import './styles.scss';

const ClientSelectorItem = React.memo((props) => {
	const { onAction, object } = props;

	const dispatch = useDispatch();

	const { close } = React.useContext(DropdownContext);

	const ID = object?._id ?? object?.id;

	const onClick = React.useCallback(() => {
		Analytics.event('search.selector.toggle', { type: 'client', id: ID });

		return onAction?.('toggleClient', {
			type: 'client',
			id: ID,
			item: object,
		});
	}, [ID, object, onAction]);

	const postfix = React.useMemo(
		() => (
			<Group verticalAlign="center" horizontalAlign="center">
				<Button
					icon="clients"
					size="sm"
					onClick={(event) => {
						close?.(event);

						return dispatch(
							ModalStore.open({
								type: ModalStore.MODAL_WINDOWS.ClientOverview,
								data: { _id: ID },
							}),
						);
					}}
				/>
			</Group>
		),
		[ID, close, dispatch],
	);

	const selected = useSelector(
		(store) => AppStore.selectors.filters(store, { id: ID }).length > 0,
	);

	return (
		<SearchSelectorItem
			active={selected}
			onClick={onClick}
			postfix={postfix}
		>
			<Chip label={object?.name} variant="simple" />
		</SearchSelectorItem>
	);
});

ClientSelectorItem.displayName = 'ClientSelectorItem';
ClientSelectorItem.propTypes = {
	onAction: PropTypes.func,
	object: PropTypes.shape({
		name: PropTypes.string,
		id: PropTypes.string,
		_id: PropTypes.string,
	}),
};

const ClientSelector = React.memo((props) => {
	const { className, onAction } = props;

	const count = useSelector(
		(store) => AppStore.selectors.filters(store, { type: 'client' }).length,
	);

	const [search, setSearch] = React.useState('');
	const hasSearchFeature = useFeature('search-clients-search');

	const objects = useSelector(AppStore.selectors.clients);

	const renderSpacer = React.useCallback(
		({ ref, style }) => (
			<div
				className={cn(
					'asteria-component__viewport-spacer',
					'asteria-component__search-selector-spacer',
				)}
				ref={ref}
				style={style}
			/>
		),
		[],
	);

	const available = objects.filter(
		(object) => !search || object?.name?.includes?.(search),
	);

	const onSearch = React.useCallback((value) => {
		setSearch(value);
	}, []);

	const searchProp = React.useMemo(() => {
		if (!hasSearchFeature) {
			return;
		}

		return {
			placeholder: TranslationService.get([
				'search.selector.clients.search.placeholder',
			]),
		};
	}, [hasSearchFeature]);

	if (objects.length < 2) {
		return null;
	}

	return (
		<SearchSelector
			className={cn('asteria--variant-client', className)}
			label={TranslationService.get(`selector.clients`)}
			onAction={onAction}
			type="client"
			count={count}
			searchOutside
			onSearch={onSearch}
			search={searchProp}
		>
			{({ ref, size }) => (
				<ViewportList
					items={available}
					viewportRef={ref}
					itemSize={40}
					overscan={10}
					scrollThreshold={40}
					getItemBoundingClientRect={getItemBoundingClientRect}
					renderSpacer={renderSpacer}
				>
					{(object) => (
						<ClientSelectorItem
							key={object?._id ?? object?.id}
							object={object}
							onAction={onAction}
							size={size}
						/>
					)}
				</ViewportList>
			)}
		</SearchSelector>
	);
});

ClientSelector.displayName = 'ClientSelector';
ClientSelector.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default ClientSelector;
