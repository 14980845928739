import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { cloneDeep, isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { Provider as FeatureProvider } from '@asteria/component-tools/featureflag/context';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as FeatureStore from '@asteria/datalayer/stores/features';

import { useQueryDoneCallback } from '@asteria/utils-hooks/useQueryDoneCallback';

import * as FeatureAPI from '../api/features';

/** @type { React.FC<React.PropsWithChildren<{ partnerId: string }>> } */
const FeaturesWrapper = React.memo(function FeaturesWrapper(props) {
	const { children, partnerId } = props;

	const dispatch = useDispatch();

	const accessToken = useSelector(AppStore.selectors.accessToken);
	const loggedIn = useSelector(
		(store) => AppStore.selectors.auth(store)?.loggedIn,
	);

	const features = useSelector(
		(store) => store?.features?.features,
		(a, b) => isEqual(a, b),
	);

	const query = useQuery({
		queryKey: [
			'widget',
			'features',
			partnerId ?? 'NONE',
			accessToken ?? 'NONE',
			{ loggedIn },
		],
		queryFn: async ({ signal }) => {
			if (loggedIn) {
				return FeatureAPI.fetchActiveFeatures({
					accessToken: accessToken,
					partnerId: partnerId,
					signal: signal,
				});
			}

			return FeatureAPI.fetchPartnerFeatures({
				partnerId: partnerId,
				signal: signal,
			});
		},
		enabled: !!loggedIn || !!partnerId,
	});

	useQueryDoneCallback(
		query,
		React.useCallback(
			($data) => {
				const data = cloneDeep($data);

				dispatch?.(FeatureStore.setFeatures(data));
			},
			[dispatch],
		),
	);

	return <FeatureProvider features={features}>{children}</FeatureProvider>;
});

FeaturesWrapper.propTypes = {
	children: PropTypes.node,
	partnerId: PropTypes.string,
};

export default FeaturesWrapper;
