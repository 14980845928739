import React from 'react';

import PropTypes from 'prop-types';

import Slider from '@asteria/component-core/slider';

import LevelNavigationItem from './item';

const LevelNavigationSlider = React.memo((props) => {
	const { config, active, visible, base, onAction, toggleable } = props;

	const ref = React.useRef(null);

	const [historicalLength, setHistoricalLength] = React.useState({});

	React.useEffect(() => {
		const previousLength = historicalLength?.[config?.id];
		const currentLength = config?.children?.length;

		if (previousLength && currentLength > previousLength) {
			ref?.current?.scrollBy?.({ left: 9999, behavior: 'smooth' });
		}

		if (previousLength !== currentLength) {
			setHistoricalLength((prev) => ({
				...prev,
				[config?.id]: currentLength,
			}));
		}
	}, [config?.children?.length, config?.id, historicalLength]);

	return (
		<Slider noScroll ref={ref}>
			{config?.children?.map?.((item) => (
				<LevelNavigationItem
					key={item?.id}
					{...item}
					active={active}
					visible={visible}
					path={base}
					onAction={onAction}
					toggleable={toggleable}
				/>
			))}
		</Slider>
	);
});

LevelNavigationSlider.displayName = 'LevelNavigationSlider';

LevelNavigationSlider.propTypes = {
	config: PropTypes.arrayOf(PropTypes.object),
	active: PropTypes.arrayOf(PropTypes.string),
	visible: PropTypes.object,
	base: PropTypes.arrayOf(PropTypes.string),
	onAction: PropTypes.func,
	toggleable: PropTypes.bool,
};

export default LevelNavigationSlider;
