import React from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import BaseStep from './base';

const StepTray = React.memo(function StepTray(props) {
	const { onAction, onClose } = props;

	const { id } = useParams();

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, id),
		(a, b) => isEqual(a, b),
	);

	const onAbort = React.useCallback((event) => onClose?.(event), [onClose]);
	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(
		() =>
			onAction?.(
				'go',
				`/onboarding/erp/visma.administration/configuration/${id}/status`,
			),
		[id, onAction],
	);

	const data = React.useMemo(
		() => ({ integration: integration }),
		[integration],
	);

	return (
		<BaseStep
			{...props}
			step="tray"
			onAbort={onAbort}
			onBack={onBack}
			onNext={onNext}
			data={data}
		/>
	);
});

StepTray.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

export default StepTray;
