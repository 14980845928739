import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { TooltipContent } from '@asteria/component-core/tooltip';
import { Text, Title } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

const ErrorTooltip = React.memo(function ErrorTooltip(props) {
	const { integration } = props;

	const title = React.useMemo(() => {
		const keys = [
			`integration.error.tooltip.title`,
			`integration.error.${integration?.type}.tooltip.title`,
			`integration.error.${integration?.key}.tooltip.title`,
			`integration.error.${integration?.type}.${integration?.key}.tooltip.title`,
		];

		if (!TranslationService.hasKey(keys)) {
			return null;
		}

		return (
			<Title>
				{TranslationService.get(keys, undefined, {
					integration: integration,
				})}
			</Title>
		);
	}, [integration]);

	const content = React.useMemo(() => {
		const keys = [
			`integration.error.tooltip.content`,
			`integration.error.${integration?.type}.tooltip.content`,
			`integration.error.${integration?.key}.tooltip.content`,
			`integration.error.${integration?.type}.${integration?.key}.tooltip.content`,
		];

		if (!TranslationService.hasKey(keys)) {
			return null;
		}

		return (
			<Text>
				{TranslationService.get(keys, undefined, {
					integration: integration,
				})}
			</Text>
		);
	}, [integration]);

	if (!integration?.config?.errors?.length) {
		return null;
	}

	return (
		<TooltipContent className="asteria-component__onboarding__error-tooltip">
			{title}
			{content}

			{(integration?.config?.errors ?? []).map((error) => {
				const { code, message } = error;

				return (
					<Group
						key={code}
						verticalAlign="top"
						horizontalAlign="center"
						direction="horizontal"
					>
						<Text weight="bold">
							{TranslationService.get(
								[
									`integration.error.code`,
									`integration.error.${code}.code`,
									`integration.error.${integration?.type}.code`,
									`integration.error.${code}.${integration?.type}.code`,
									`integration.error.${integration?.key}.code`,
									`integration.error.${code}.${integration?.key}.code`,
									`integration.error.${integration?.type}.${integration?.key}.code`,
									`integration.error.${code}.${integration?.type}.${integration?.key}.code`,
								],
								code,
								{ error: error },
							)}
						</Text>
						<Text>
							{TranslationService.get(
								[
									`integration.error.message`,
									`integration.error.${code}.message`,
									`integration.error.${integration?.type}.message`,
									`integration.error.${code}.${integration?.type}.message`,
									`integration.error.${integration?.key}.message`,
									`integration.error.${code}.${integration?.key}.message`,
									`integration.error.${integration?.type}.${integration?.key}.message`,
									`integration.error.${code}.${integration?.type}.${integration?.key}.message`,
									message,
								],
								message,
								{ error: error },
							)}
						</Text>
					</Group>
				);
			})}
		</TooltipContent>
	);
});

ErrorTooltip.displayName = 'ErrorTooltip';

ErrorTooltip.propTypes = {
	className: PropTypes.string,
	integration: PropTypes.object,
};

export default ErrorTooltip;
