import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import ComponentService from '@asteria/component-tools/contenter/service';

import Analytics from '@asteria/utils-analytics';
import { useAnalyticsData } from '@asteria/utils-funcs/analytics';
import { cn } from '@asteria/utils-funcs/classes';
import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

import { SizeProp } from '../PropTypes';
import Badge from '../badge';
import Icon, { hasIcon, iconClasses } from '../icon';
import Spinner from '../spinner';
import { TooltipWrapper } from '../tooltip';
import Text from '../typography';
import { positionClasses, sizeClasses, stateClasses } from '../utils';
import * as SizeUtils from '../utils/size';

import './index.scss';

function getSize(options = {}) {
	const { size, iconSize, hasIconOnly } = options;

	if (iconSize) {
		return iconSize;
	}

	if (hasIconOnly) {
		return size;
	}

	return SizeUtils.decrease(size, 1, { min: 'sm' });
}

const ButtonPropTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	size: SizeProp(),
	iconSize: SizeProp(),
	badge: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
	variant: PropTypes.string,
	disabled: PropTypes.bool,
	active: PropTypes.bool,
	loading: PropTypes.bool,
	icon: PropTypes.string,
	iconTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	iconActive: PropTypes.string,
	iconPosition: PropTypes.oneOf(['first', 'last']),
	icons: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			icon: PropTypes.string,
			iconPosition: PropTypes.oneOf(['first', 'last']),
			className: PropTypes.string,
		}),
	]),
	onIconClick: PropTypes.func,
	iconBadge: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
	onClick: PropTypes.func,
	stopPropagation: PropTypes.bool,
	tooltipClassName: PropTypes.string,
	children: PropTypes.node,
	href: PropTypes.string,
	tooltip: PropTypes.oneOfType([
		PropTypes.string,
		// PropTypes.shape({ ...Tooltip.propTypes }), // THIS LINE causes issues in webapp ?!?! Tooltip depends on Button, and Button on Tooltip
		PropTypes.node,
	]),
	type: PropTypes.oneOf(['button', 'submit', 'reset']),
	analyticsKey: PropTypes.string,
	tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	analytics: PropTypes.object,
};

/**
 * @type { React.ForwardRefExoticComponent<Partial<typeof ButtonPropTypes> & { ref?: React.RefObject }> }
 */
const Button = React.forwardRef((props, ref) => {
	const {
		className,
		children,
		label,
		disabled,
		icon,
		iconActive = icon,
		iconPosition,
		iconSize,
		iconTooltip,
		iconBadge,
		icons: $icons,
		onIconClick,
		variant,
		size,
		onClick,
		href,
		loading,
		stopPropagation,
		tooltip,
		type = 'button',
		active,
		analyticsKey,
		tooltipClassName,
		tabIndex,
		badge,
		analytics: $analytics,
		...buttonProps
	} = props;

	const icons = ($icons ?? []).filter((value) =>
		hasIcon(value?.icon ?? value),
	);

	const analytics = useDeepMemo(() => $analytics, [$analytics]);

	const analyticsDataRef = useAnalyticsData({
		disabled: disabled,
		label: label,
		className: className,
		href: href,
		analyticsKey: analyticsKey,
		...analytics,
	});

	const onButtonClicked = useMemo(
		() =>
			AsteriaCore.utils.throttle((event) => {
				if (stopPropagation) {
					event.stopPropagation();
				}

				if (disabled) {
					return;
				}

				if (analyticsKey) {
					Analytics.event('button.click', analyticsDataRef.current);
				}

				return onClick?.(event);
			}, 0),
		[stopPropagation, analyticsKey, disabled, onClick, analyticsDataRef],
	);

	const TooltipProps = useMemo(() => {
		if (
			AsteriaCore.utils.isObject(tooltip) &&
			!React.isValidElement(tooltip)
		) {
			return tooltip;
		}

		return {
			tooltip: tooltip,
			className: tooltipClassName,
		};
	}, [tooltip, tooltipClassName]);

	const As = href ? 'a' : 'button';

	const hasIconOnly = icon && !label && !children;

	const ButtonIconProps = {
		...props,
		label: null,
		children: null,
		iconPosition: null,
		loading: null,
		size: getSize({
			iconSize: iconSize,
			size: size,
			hasIconOnly: hasIconOnly,
		}),
		icons: null,
		onClick: onIconClick,
		onMouseEnter: null,
		onMouseLeave: null,
		tooltip: iconTooltip,
		badge: iconBadge,
	};

	const iconExists = hasIcon(icon);

	return (
		<TooltipWrapper {...TooltipProps} targetRef={ref}>
			<As
				ref={ref}
				className={cn(
					className,
					'asteria-component__button',
					sizeClasses(props),
					{
						[`asteria-component__button--variant-${variant}`]:
							variant,
						[`asteria-component__button--icon-only`]:
							iconExists && hasIconOnly,
					},
					iconExists
						? iconClasses('asteria-component__button', props)
						: false,
					stateClasses({ ...props, active: active }),
					{ 'asteria--state-has-href': !!href },
				)}
				// disabled={disabled}
				onClick={onButtonClicked}
				href={href}
				type={type}
				tabIndex={tabIndex}
				{...buttonProps}
			>
				{children}
				{badge ? (
					typeof badge === 'object' &&
					!React.isValidElement(badge) ? (
						<Badge {...badge} />
					) : (
						<Badge>
							<Text>{badge}</Text>
						</Badge>
					)
				) : null}
				{label ? (
					<Text
						component="button-text"
						size={size}
						tabIndex={tabIndex}
					>
						{label}
					</Text>
				) : null}
				{icons?.length
					? icons.map((value) => {
							let $icon = value,
								$iconActive = value;

							if (typeof value === 'object') {
								$icon = value?.icon;
								$iconActive = value?.iconActive ?? $icon;
							}

							return (
								<Icon
									key={$icon}
									className={cn(
										positionClasses({
											position:
												value?.iconPosition ??
												iconPosition,
										}),
										value?.className,
									)}
									size={getSize({
										iconSize: value?.iconSize ?? iconSize,
										size: size,
										hasIconOnly: hasIconOnly,
									})}
									icon={active ? $iconActive : $icon}
									tabIndex={tabIndex}
								/>
							);
					  })
					: null}
				{iconExists ? (
					<>
						{icon && !hasIconOnly ? (
							<Button
								{...ButtonIconProps}
								className={positionClasses({
									position: iconPosition,
								})}
							/>
						) : null}
						{hasIconOnly ? (
							<Icon
								className={cn(
									positionClasses({ position: iconPosition }),
								)}
								size={getSize({
									iconSize: iconSize,
									size: size,
									hasIconOnly: hasIconOnly,
								})}
								icon={active ? iconActive : icon}
								tabIndex={tabIndex}
							/>
						) : null}
					</>
				) : null}

				{loading ? <Spinner /> : null}
			</As>
		</TooltipWrapper>
	);
});

Button.propTypes = ButtonPropTypes;

Button.defaultProps = {
	iconPosition: 'first',
	size: 'md',
};

Button.displayName = 'Button';

ComponentService.register('Button', Button, {
	className: { type: 'string' },
	label: { type: 'string' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
	iconSize: { type: 'enum', options: ['lg', 'md', 'sm'] },
	badge: { type: 'node' },
	variant: { type: 'string' },
	disabled: { type: 'boolean' },
	active: { type: 'boolean' },
	loading: { type: 'boolean' },
	icon: { type: 'string' },
	iconTooltip: { type: 'string' },
	iconActive: { type: 'string' },
	iconPosition: { type: 'enum', options: ['first', 'last'] },
	icons: {
		type: 'array',
		of: {
			type: 'object',
			options: {
				icon: { type: 'string' },
				iconPosition: { type: 'enum', options: ['first', 'last'] },
				className: { type: 'string' },
			},
		},
	},
	onIconClick: { type: 'function' },
	iconBadge: { type: 'node' },
	onClick: { type: 'function' },
	stopPropagation: { type: 'boolean' },
	tooltipClassName: { type: 'string' },
	children: { type: 'node' },
	href: { type: 'string' },
	tooltip: { type: 'string' },
	type: { type: 'enum', options: ['button', 'submit', 'reset'] },
	analyticsKey: { type: 'string' },
	tabIndex: { type: 'number' },
	analytics: { type: 'object' },
});

export default React.memo(Button);
