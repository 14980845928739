import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip';
import { Input } from '@asteria/component-form';
import {
	Service as FeatureService,
	useFeature,
} from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

/**
 * @typedef Props
 * @property { string } className
 * @property { string } search
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { 'erp' | 'bank' } active
 * @property { 'sm' | 'md' } size
 * @property { 'erp' | 'bank' } type
 */

/** @type { React.FC<Props> } */
const Search = React.memo(function Search(props) {
	const { className, search, onAction, active, size, type } = props;

	const hasBankFeature = useFeature('bank-integrations');

	const onSearch = React.useCallback(
		({ value }) => onAction?.('onboarding:search', value),
		[onAction],
	);

	const onERPClick = React.useCallback(
		() => onAction?.('onboarding:search:type', 'erp'),
		[onAction],
	);

	const onBankClick = React.useCallback(
		() => onAction?.('onboarding:search:type', 'bank'),
		[onAction],
	);

	return (
		<div
			className={cn(
				'flex flex-col gap-2',
				'asteria-component__onboarding-search',
				className,
			)}
		>
			<Input
				uncontrolled
				icon="magnifier"
				value={search}
				onChange={onSearch}
				placeholder={TranslationService.getV2(
					['onboarding.modal.search.placeholder'],
					{ postfix: { bank: hasBankFeature } },
				)}
				size={size}
			/>
			{hasBankFeature && !type ? (
				<div
					className={cn(
						'flex gap-2',
						'asteria-component__chip-group',
					)}
				>
					<Chip
						size={size}
						label={TranslationService.getV2(
							'onboarding.modal.filter',
							{ postfix: { type: 'erp' } },
						)}
						active={active === 'erp'}
						onClick={onERPClick}
					/>
					<Chip
						size={size}
						label={TranslationService.getV2(
							'onboarding.modal.filter',
							{ postfix: { type: 'bank' } },
						)}
						active={active === 'bank'}
						onClick={onBankClick}
					/>
				</div>
			) : null}
		</div>
	);
});

Search.displayName = 'Search';

Search.propTypes = {
	className: PropTypes.string,
	active: PropTypes.string,
	onAction: PropTypes.string,
	search: PropTypes.string,
	size: PropTypes.string,
	type: PropTypes.string,
};

/** @type { React.FC<React.PropsWithChildren<Props>> } */
const SearchWrapper = React.memo(function SearchWrapper(props) {
	const {
		className,
		children,
		onAction,
		size,
		search: initialSearch = '',
		type = null,
		active: initialActive = type,
	} = props;

	const hasBankFeature = useFeature('bank-integrations');

	const [{ search, active }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'SET_SEARCH':
					return { ...state, search: action?.payload };

				case 'SET_TYPE': {
					if (state.active === action?.payload) {
						return { ...state, active: null };
					}

					return { ...state, active: action?.payload };
				}

				default:
					return state;
			}
		},
		{ search: initialSearch, active: initialActive },
	);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'onboarding:search') {
				return dispatch({ type: 'SET_SEARCH', payload: data });
			}

			if (action === 'onboarding:search:type') {
				return dispatch({ type: 'SET_TYPE', payload: data });
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	const available = useConfig(`integrations`, {
		callback: React.useCallback(
			(response) => {
				return (response ?? []).filter((object) => {
					const byFeatureFlag =
						!object?.feature ||
						FeatureService.isActive(object?.feature);

					const byIntegrationType =
						(hasBankFeature || object?.type !== 'bank') &&
						(!active || object?.type === active);

					const bySearch =
						!search ||
						object?.name
							?.toLowerCase?.()
							?.includes?.(search?.toLowerCase?.());

					return byFeatureFlag && byIntegrationType && bySearch;
				});
			},
			[active, hasBankFeature, search],
		),
	});

	const connected = useSelector((store) => {
		if (!available?.length) {
			return [];
		}

		return IntegrationStore.selectors.integrations(store, {
			filters: available
				.map((flow) => ({ type: flow?.type, key: flow?.key }))
				.concat({ type: 'erp', key: 'printer' }),
		});
	}, isEqual);

	return (
		<div
			className={cn(
				'flex flex-col gap-4 overflow-y-hidden',
				'asteria-component__onboarding-search-wrapper',
				className,
			)}
		>
			<Search
				onAction={handleAction}
				search={search}
				active={active}
				size={size}
				type={type}
			/>
			{typeof children === 'function'
				? children({ available, connected })
				: children}
		</div>
	);
});

SearchWrapper.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	onAction: PropTypes.func,
	size: PropTypes.string,
	search: PropTypes.string,
	active: PropTypes.string,
	type: PropTypes.string,
};

export default Search;
export { SearchWrapper };
