import React from 'react';

import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import Accordion, { AccordionItem } from '@asteria/component-core/accordion';
import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Contenter from '@asteria/component-tools/contenter';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import { useFlow } from '../../hooks';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { import('react').MouseEventHandler } onClose
 */

const DEFAULT_LINK = 'https://www.asteria.ai/sv/data-processing-agreement-sv/';

/** @type { React.FC<Props> } */
const PageAgreement = React.memo(function PageAgreement(props) {
	const { className, onClose, onAction } = props;

	const { type, key } = useParams();

	const flow = useFlow(type, key);

	const content = useConfig(['integrations.access'], {
		deep: true,
		callback: (data) => data?.info?.content,
	});

	const handleConnectClick = React.useCallback(
		() => onAction?.('go', `/onboarding/${type}/${key}/configuration`),
		[key, onAction, type],
	);

	return (
		<Wrapper
			className={cn(
				'asteria-component__onboarding-step',
				'asteria--variant-agreement',
				'asteria-component__onboarding-access-data',
				className,
			)}
		>
			<Header onClose={onClose}>
				{TranslationService.getV2(['onboarding.modal.title'], {
					postfix: { variant: 'access-data' },
				})}
			</Header>
			<Content className="gap-4">
				<Contenter
					content={content}
					translationData={{ data: { integration: flow } }}
				/>
				<Accordion>
					<AccordionItem
						label={TranslationService.getV2(
							'integrations.access.data.info.accordion.title',
						)}
					>
						<div className="flex flex-col gap-4">
							<Translation
								translationKey="integrations.access.data.info.accordion.text.1"
								Component={Text}
							/>
							<Translation
								translationKey="integrations.access.data.info.accordion.text.2"
								Component={Text}
							/>
							<div>
								<Button
									size="sm"
									variant="link"
									href={TranslationService.getV2(
										'integrations.access.data.info.accordion.link.href',
										{ default: DEFAULT_LINK },
									)}
									label={TranslationService.getV2(
										'integrations.access.data.info.accordion.link.label',
									)}
								/>
							</div>
						</div>
					</AccordionItem>
				</Accordion>
			</Content>
			<Footer>
				<FooterSection position="first">
					<Button
						variant="tertiary"
						onClick={onClose}
						label={TranslationService.getV2(
							['onboarding.modal.action'],
							{
								postfix: {
									variant: 'access-data',
									action: 'close',
								},
							},
						)}
						analyticsKey="onboarding.modal.access-data.action.close"
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant="primary"
						onClick={handleConnectClick}
						label={TranslationService.getV2(
							['onboarding.modal.action'],
							{
								postfix: {
									variant: 'access-data',
									action: 'next',
								},
							},
						)}
						analyticsKey="onboarding.modal.access-data.action.close"
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

PageAgreement.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PageAgreement;
