function findAvailableNode(node) {
	const children = [...node.childNodes].map((node) =>
		node.getBoundingClientRect(),
	);

	if (children?.every?.((value) => !value?.height && !value?.width)) {
		return [...node.childNodes].find((node) => findAvailableNode(node));
	}

	return node;
}

export function getItemBoundingClientRect(element) {
	const parent = findAvailableNode(element);

	const nodes = [...parent.childNodes].map((node) =>
		node.getBoundingClientRect(),
	);

	if (!nodes?.length) {
		return new DOMRectReadOnly(0, 0, 0, 0);
	}

	return nodes.reduce((acc, rect) => {
		const x = Math.min(acc.left, rect.left),
			y = Math.min(acc.top, rect.top),
			width = Math.max(acc.right, rect.right) - x,
			height = Math.max(acc.bottom, rect.bottom) - y;

		return new DOMRectReadOnly(x, y, width, height);
	});
}
