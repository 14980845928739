import React from 'react';

import PropTypes from 'prop-types';

import Splash from '@asteria/component-splash';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const ClientSplash = (props) => {
	const { className, onAction } = props;

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'erp:create') {
				return onAction?.('go', {
					path: '/onboarding/connect',
					state: { type: 'erp' },
				});
			}

			onAction(action, data);
		},
		[onAction],
	);

	const actions = React.useMemo(
		() => ({ onAction: handleAction }),
		[handleAction],
	);

	return (
		<Splash
			className={cn(
				'asteria-component__client-splash',
				'asteria--variant-client',
				className,
			)}
			path="pages.client.splash"
			actions={actions}
		/>
	);
};

ClientSplash.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

ClientSplash.defaultProps = {};

export default ClientSplash;
