// import '@asteria/component-core/utils/why-did-you-render';
import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';

import { addMonths, format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import Form from '@asteria/component-form';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import LoadingScreen from '@asteria/layout/loading';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';
import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

import { HeaderSimple } from './components/header';
import { useLoading } from './hooks';
import Layout from './layout';
import Context from './logic/context';
import CategoryPage from './pages/category';
import HomePage from './pages/home';
import { validateFormValues } from './utils';
import { recalculate as recalculateTag } from './utils/adjustTag';
import { recalculate as recalculateType } from './utils/adjustType';
import applyFormChanges, { revertFormChanges } from './utils/applyFormChanges';
import {
	remove as resetRemove,
	tag as resetTag,
	type as resetType,
} from './utils/reset';
import {
	validateFormClients,
	validateFormTags,
} from './utils/validateFormValues';
import validateState from './utils/validateState';

const selectors = {
	tags: createSelector(
		(state) => state?.app?.tags ?? [],
		(value) => value.length,
	),
	clients: createSelector(
		(state) => state?.app?.clients ?? [],
		(value) => value.length,
	),

	direction: createSelector(
		(store) => store?.app?.user?.settings?.flags?.forecasterDirection,
		(value) => value ?? 'horizontal',
	),
};

const ForecasterLoader = () => {
	const loading = useLoading();

	if (loading === 'integration') {
		return <LoadingScreen blur type="forecaster.loading.integration" />;
	}

	if (loading === 'saving') {
		return (
			<LoadingScreen blur type={['forecaster', 'forecaster.loading']} />
		);
	}

	if (loading === 'loading') {
		return <LoadingScreen blur type="forecaster" />;
	}

	return null;
};

function useTagChanges() {
	const count = useSelector(selectors.tags);
	const ref = React.useRef(null);
	const store = useStore();

	const { getValues, setValue, unregister, reset } = useFormContext();

	React.useEffect(() => {
		if (ref?.current !== null && ref?.current > count) {
			const form = getValues();

			const changes = validateFormTags({ form: form, store: store });

			if (changes.length) {
				applyFormChanges({
					changes: changes,
					event: 'validate:clients',
					getValues: getValues,
					setValue: setValue,
					unregister: unregister,
					reset: reset,
				});
			}
		}

		ref.current = count;
	}, [count, getValues, reset, setValue, store, unregister]);
}

function useClientChanges() {
	const count = useSelector(selectors.clients);
	const ref = React.useRef(null);
	const store = useStore();

	const { getValues, setValue, unregister, reset } = useFormContext();

	React.useEffect(() => {
		if (ref?.current !== null && ref?.current > count) {
			const form = getValues();

			const changes = validateFormClients({ form: form, store: store });

			if (changes.length) {
				applyFormChanges({
					changes: changes,
					event: 'validate:clients',
					getValues: getValues,
					setValue: setValue,
					unregister: unregister,
					reset: reset,
				});
			}
		}

		ref.current = count;
	}, [count, getValues, reset, setValue, store, unregister]);
}

const ForecasterAction = React.memo((props) => {
	const { onAction } = props;

	const currentAction = useSelector(ForecasterStore.selectors.flags.action);

	const sourceScenarioData = useSelector(
		ScenarioStore.selectors.sourceScenarioData,
	);

	const { getValues, setValue, unregister, reset } = useFormContext();

	const dispatch = useDispatch();

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'draft:open') {
				return handleAction('forecaster:abort');
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	const handleResetType = React.useCallback(
		({ type }) => {
			const revertChanges = [].concat(
				resetType({ form: getValues(), type: type }),
			);

			revertFormChanges({
				changes: revertChanges,
				setValue,
				getValues,
				unregister,
			});

			const form = getValues();

			const applyChanges = []
				.concat(recalculateType({ form: form, type: type }))
				.concat(validateState({ form: form, type: type }));

			applyFormChanges({
				changes: applyChanges,
				setValue,
				getValues,
				unregister,
				reset,
				event: 'reset:type',
			});

			dispatch(ForecasterStore.navigation.back());
		},
		[dispatch, getValues, setValue, unregister, reset],
	);

	const handleResetTag = React.useCallback(
		({ type, category, tag }) => {
			if (!category || category === '$type') {
				return handleResetType({ type: type });
			}

			const revertChanges = [].concat(
				resetTag({
					form: getValues(),
					type: type,
					category: category,
					tag: tag,
				}),
			);

			revertFormChanges({
				changes: revertChanges,
				setValue,
				getValues,
				unregister,
			});

			const form = getValues();

			const applyChanges = []
				.concat(
					recalculateTag({
						form: form,
						type: type,
						category: category,
						tag: tag,
					}),
				)
				.concat(
					validateState({
						form: form,
						type: type,
						category: category,
						tag: tag,
					}),
				)
				.concat(
					validateState({
						form: form,
						type: type,
					}),
				);

			applyFormChanges({
				changes: applyChanges,
				setValue,
				getValues,
				unregister,
				reset,
				event: 'reset:tag',
			});

			dispatch(ForecasterStore.navigation.back());
		},
		[dispatch, getValues, handleResetType, setValue, unregister, reset],
	);

	const handleResetRemove = React.useCallback(
		({ type, category, tag }) => {
			const revertChanges = [].concat(
				resetRemove({
					form: getValues(),
					type: type,
					category: category,
					tag: tag,
				}),
			);

			revertFormChanges({
				changes: revertChanges,
				setValue,
				getValues,
				unregister,
			});

			const form = getValues();

			const applyChanges = []
				.concat(
					recalculateTag({
						form: form,
						type: type,
						category: category,
						tag: tag,
					}),
				)
				.concat(
					validateState({
						form: form,
						type: type,
						category: category,
						tag: tag,
					}),
				)
				.concat(
					validateState({
						form: form,
						type: type,
					}),
				);

			applyFormChanges({
				changes: applyChanges,
				setValue,
				getValues,
				unregister,
				reset,
				event: 'reset:remove',
			});

			dispatch(
				ForecasterStore.tags.select({
					type: type,
					categoryName: category,
					tagName: tag,
				}),
			);
		},
		[dispatch, getValues, setValue, unregister, reset],
	);

	React.useEffect(() => {
		switch (currentAction?.event) {
			case ForecasterStore.constants.ACTIONS.ResetTag: {
				const type = currentAction?.type;
				const category = currentAction?.category;
				const tag = currentAction?.tag;

				handleResetTag({ type: type, category: category, tag: tag });

				break;
			}

			case ForecasterStore.constants.ACTIONS.ResetRemove: {
				const type = currentAction?.type;
				const category = currentAction?.category;
				const tag = currentAction?.tag;

				handleResetRemove({ type: type, category: category, tag: tag });

				break;
			}

			case ForecasterStore.constants.ACTIONS.ResetType: {
				const type = currentAction?.type;

				handleResetType({ type: type });

				break;
			}

			case ForecasterStore.constants.ACTIONS.Abort: {
				break;
			}

			case ForecasterStore.constants.ACTIONS.Close: {
				onAction?.('forecaster:save', {
					scenario: {
						_id: sourceScenarioData?._id,
						name: sourceScenarioData?.name,
					},
					data: getValues?.(),
					noRedirect: true,
				});

				handleAction?.('draft:open');
				break;
			}
		}

		if (currentAction?.event) {
			dispatch(ForecasterStore.action.reset());
		}
	}, [currentAction?.event]);

	useTagChanges();
	useClientChanges();

	return null;
});

ForecasterAction.displayName = 'ForecasterAction';

ForecasterAction.propTypes = {
	onAction: PropTypes.func,
};

const Forecaster = React.memo((props) => {
	const { children, className } = props;

	return (
		<Form className={cn('asteria-component__forecaster__form', className)}>
			{children}
		</Form>
	);
});

Forecaster.displayName = 'Forecaster';

Forecaster.propTypes = {
	forecast: PropTypes.object,
	history: PropTypes.object,
	scenario: PropTypes.object,
	onAction: PropTypes.func,

	children: PropTypes.node,
	className: PropTypes.string,
};

const ForecasterNavigation = (props) => {
	const { forecast, scenario, history, onAction, onSubmit } = props;

	const store = useStore();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { reset } = useFormContext();

	React.useEffect(() => {
		if (forecast) {
			reset(validateFormValues(forecast, scenario), {
				keepDefaultValues: false,
				keepDirty: false,
				keepDirtyValues: false,
				keepErrors: false,
				keepIsSubmitSuccessful: false,
				keepIsSubmitted: false,
				keepIsValid: false,
				keepSubmitCount: false,
				keepTouched: false,
				keepValues: false,
			});
		}
	}, [forecast, reset]);

	const ref = React.useRef(null);

	React.useLayoutEffect(() => {
		findScrollingParent(ref.current)?.scroll?.({
			behavior: 'smooth',
			top: 0,
		});
	}, []);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'navigate') {
				handleAction('forecaster:graph:center');

				const node = findScrollingParent(ref.current);

				if (node) {
					node.scroll({ behavior: 'smooth', top: 0 });
				}

				if (data?.event === 'action') {
					return navigate(`/forecaster/action/${data?.id}`);
				}

				if (data === null) {
					return navigate(`/forecaster/`);
				}

				if (data?.variant === '$custom') {
					return navigate(`/forecaster/category`);
				}

				return navigate(data);
			}

			if (action === 'forecaster:graph:center') {
				dispatch(
					ForecasterStore.navigation.setDate(
						format(new Date(), 'yyyy-MM-01'),
					),
				);
			}

			if (action === 'forecaster:graph:next') {
				const currentDate =
					store.getState()?.forecaster?.navigation?.date ??
					new Date();
				const nextInterval = addMonths(
					currentDate instanceof Date
						? currentDate
						: parseISO(currentDate),
					1,
				);

				dispatch(
					ForecasterStore.navigation.setDate(
						format(nextInterval, 'yyyy-MM-dd'),
					),
				);
			}

			if (action === 'forecaster:graph:previous') {
				const currentDate =
					store.getState()?.forecaster?.navigation?.date ??
					new Date();
				const previousInterval = addMonths(
					currentDate instanceof Date
						? currentDate
						: parseISO(currentDate),
					-1,
				);

				dispatch(
					ForecasterStore.navigation.setDate(
						format(previousInterval, 'yyyy-MM-dd'),
					),
				);
			}

			return onAction?.(action, data);
		},
		[dispatch, navigate, onAction, store],
	);

	const contextData = useDeepMemo(() => ({ history }), [history]);

	return (
		<Context.Provider value={contextData}>
			<ForecasterAction onAction={handleAction} />
			<FeatureFlag feature="forecaster-header-outside">
				<HeaderSimple onAction={handleAction} />
			</FeatureFlag>
			<Layout onAction={handleAction}>
				<div
					className="asteria-component__forecaster-page__wrapper"
					ref={ref}
				>
					<Routes>
						<Route
							index
							element={
								<HomePage
									onSubmit={onSubmit}
									onAction={handleAction}
								/>
							}
						/>
						<Route
							path="action/:actionId"
							element={
								<CategoryPage
									onSubmit={onSubmit}
									onAction={handleAction}
									variant="action"
								/>
							}
						/>
						<Route
							path="category/*"
							element={
								<CategoryPage
									onSubmit={onSubmit}
									onAction={handleAction}
									variant="category"
								/>
							}
						/>
					</Routes>
				</div>
			</Layout>
		</Context.Provider>
	);
};

ForecasterNavigation.displayName = 'ForecasterNavigation';
ForecasterNavigation.propTypes = {
	forecast: PropTypes.object,
	history: PropTypes.object,
	scenario: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const ForecasterProvider = (props) => {
	return [
		<ForecasterLoader key="loader" />,

		<Forecaster
			key="forecaster-dashboard"
			{...props}
			className="asteria-component__forecaster-v2"
		>
			<ForecasterNavigation {...props} />
		</Forecaster>,
	];
};

export default ForecasterProvider;
