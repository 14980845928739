import { useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { useSubscription as useBaseSubscription } from '@asteria/utils-websocket/hooks';

export function useSubscription({ onAction, onSubmit }) {
	const queryClient = useQueryClient();

	const accessToken = useSelector(AppStore.selectors.accessToken);
	const loggedIn = useSelector(
		(store) => AppStore.selectors.auth(store)?.loggedIn,
	);

	const idle = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, { status: 'IDLE' })
				.length,
	);

	const { refetch } = useQuery({
		queryKey: ['widget', 'integrations'],
		queryFn: async () => onSubmit?.('integrations:list'),

		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		refetchInterval: 30_000,

		enabled: !!loggedIn,
	});

	useEffect(() => {
		queryClient.invalidateQueries({
			predicate: (query) => {
				const streamline = ['card', 'streamline'].includes(
					query.queryKey[0],
				);

				const widget = [
					'accounts',
					'scenarios',
					'tags',
					'clients',
					'currencies',
				].some((key) => query.queryKey.includes(key));

				return streamline || widget;
			},
		});
	}, [idle, queryClient]);

	return useBaseSubscription({
		token: accessToken,
		query: `
			subscription IntegrationUpdated {
				integrationUpdated {
					_id
				}
			}
		`,
		enabled: !!loggedIn,
		onNext: useCallback(
			(response) => {
				refetch();

				const id = response?.data?.integrationUpdated?._id;

				if (id) {
					onAction?.('askForFeedback', {
						feedbackKey: `integration-updated-${id}`,
					});
				}
			},
			[onAction, refetch],
		),
	});
}
