import { useQuery } from '@tanstack/react-query';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/**
 * @param { Props } options
 */
export function useRequest({ onSubmit, startDate, endDate, dataloader }) {
	return useQuery({
		queryKey: ['card', 'profit', { startDate, endDate }],
		queryFn: async ({ meta }) => {
			return onSubmit?.('card:fetch', {
				type: 'profit',
				source: {
					variant: 'incoming',
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					variant: 'outgoing',
					startDate: startDate,
					endDate: endDate,
				},
				dataloader: meta?.dataloader,
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate,

		meta: { dataloader },
	});
}
