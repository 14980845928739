import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import ErrorTooltip from './error-tooltip';

const ErrorButton = React.memo(function ErrorButton(props) {
	const { integration, size } = props;

	if (!integration?.config?.errors?.length) {
		return null;
	}

	return (
		<Button
			icon="help"
			size={size}
			tooltip={<ErrorTooltip integration={integration} />}
		/>
	);
});

ErrorButton.displayName = 'ErrorTooltip';

ErrorButton.propTypes = {
	className: PropTypes.string,
	integration: PropTypes.object,
	size: PropTypes.string,
};

export default ErrorButton;
