import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

export function useUserRoles() {
	return useSelector(
		(store) => [].concat(store?.app?.user?.roles ?? ['LIMITED_VIEW']),
		isEqual,
	);
}

/**
 * @param { string | string[] } roles
 * @returns { boolean }
 */
export function useACL(roles) {
	const userRoles = useUserRoles();

	const result = userRoles.some((role) => [].concat(roles).includes(role));

	return useMemo(() => result, [result]);
}
