import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { useQueryDoneCallback } from '@asteria/utils-hooks/useQueryDoneCallback';

import * as PartnerAPI from '../api/partner';

/** @type { React.FC<React.PropsWithChildren<{ partnerId: string }>> } */
const PartnerWrapper = React.memo(function PartnerWrapper(props) {
	const { children, partnerId } = props;

	const dispatch = useDispatch();
	const accessToken = useSelector(AppStore.selectors.accessToken);
	const loggedIn = useSelector(
		(store) => AppStore.selectors.auth(store)?.loggedIn,
	);

	const query = useQuery({
		queryKey: [
			'widget',
			'partner',
			partnerId ?? 'NONE',
			accessToken ?? 'NONE',
			{ loggedIn },
		],
		queryFn: async () => PartnerAPI.fetch({ accessToken, partnerId }),
		enabled: !!loggedIn && !!partnerId,
	});

	useQueryDoneCallback(
		query,
		React.useCallback(
			($data) => {
				const data = cloneDeep($data);

				TranslationService.updateGlobals({ partner: data });
				dispatch?.(AppStore.setPartner(data));
			},
			[dispatch],
		),
	);

	return <>{children}</>;
});

PartnerWrapper.propTypes = {
	children: PropTypes.node,
	partnerId: PropTypes.string,
};

export default PartnerWrapper;
