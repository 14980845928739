import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { GuideContext } from '../context';

/**
 * @typedef Props
 * @property { string } className
 * @property { React.ReactNode[] } placeholder
 */

/** @type { React.FC<React.PropsWithChildren<Props>> } */
const OnboardingWrapper = React.memo(function OnboardingWrapper(props) {
	const { className, placeholder, children } = props;

	const guided = React.useContext(GuideContext);

	return (
		<div
			className={cn(
				'asteria-component__onboarding-wrapper',
				{
					'asteria--variant-placeholder':
						guided.state && !!placeholder,
					'asteria--variant-placeholder-multi':
						guided.state && placeholder?.length > 1,
				},
				className,
			)}
		>
			{children}
		</div>
	);
});

OnboardingWrapper.displayName = 'OnboardingWrapper';

OnboardingWrapper.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.arrayOf(PropTypes.node),
	children: PropTypes.node,
};

export default OnboardingWrapper;
