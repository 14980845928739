import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { getRedirectURI, openWindow } from './utils';

export function useActions(integration) {
	const [actions, setActions] = useState([]);

	const URI = getRedirectURI(integration);
	const sandbox = integration?.config?.client?.mode === 'sandbox';

	const hasActions = !!(integration?.actions ?? []).length;

	useEffect(() => {
		if (!URI || sandbox || hasActions) {
			return;
		}

		openWindow(URI, {
			onError: () => {
				setActions([
					{ action: 'browser.enable.popup', status: 'PENDING' },
				]);
			},
		});
	}, [URI, hasActions, sandbox]);

	return useMemo(() => [actions, setActions], [actions]);
}

export function useIntegrationState(id) {
	const { connected, hasErrors, hasActions, status } = useSelector(
		(store) => {
			const object = IntegrationStore.selectors.integration(store, id);

			const connected = object?.config?.connected ?? false;
			const errors = object?.config?.errors;
			const actions = (object?.actions ?? []).filter(
				({ status }) => status === 'PENDING',
			);
			const status = object?.status?.state ?? 'IDLE';

			return {
				connected: connected,
				hasErrors: !!errors?.length,
				hasActions: !!actions?.length,
				status: status,
			};
		},
		isEqual,
	);

	return useMemo(() => {
		if (!hasActions) {
			if (status !== 'ERROR' && connected) {
				return 'success';
			}

			if (status === 'ERROR' || hasErrors) {
				return 'error';
			}
		}

		return 'loading';
	}, [connected, hasActions, hasErrors, status]);
}
