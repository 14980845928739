import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { Configuration } from './configuration';

/**
 * @typedef Props
 * @property { string } className
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { import('react').MouseEventHandler } onClose
 */

/** @type { React.FC<Props> } */
const PageDetails = React.memo(function PageDetails(props) {
	const { id } = useParams();

	const location = useLocation();

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, id),
		isEqual,
	);

	const type = integration?.type;
	const key = integration?.key ?? integration?.name;

	const form = React.useMemo(
		() => ({ $id: id, config: { client: integration?.config?.client } }),
		[id, integration?.config?.client],
	);

	if (type === 'erp') {
		if (key === 'visma.administration') {
			return (
				<Navigate
					to={`/onboarding/erp/visma.administration/configuration/${id}`}
					state={location?.state}
					replace
				/>
			);
		}

		if (key === 'printer') {
			return (
				<Navigate
					to={`/onboarding/erp/printer/configuration`}
					state={location?.state}
					replace
				/>
			);
		}
	}

	return <Configuration {...props} type={type} name={key} form={form} />;
});

PageDetails.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PageDetails;
