import AsteriaCore from '@asteria/core';

import { SupportService } from '@asteria/backend-utils-services';

SupportService.issue.extend({
	getQuery: () => `
		mutation Request($input: AssistanceInputType!) {
			response: requestAssistance(input: $input) {
				ok
			}
		}
	`,
	key: `requestAssistance`,
	loggerMethod: `services.supportService.requestAssistance`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.supportService.requestAssistance`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

export async function requestAssistance({ accessToken, input }) {
	return SupportService.issue.extension.requestAssistance(
		{ input: input },
		{ token: accessToken },
	);
}

export async function report({ accessToken, input }) {
	const response = await SupportService.issue.reportIssues(
		{ fields: 'reportId', input: input },
		{ token: accessToken },
	);

	return response?.[0]?.reportId;
}

SupportService.issue.extend({
	getQuery: () => `
	query Chats(
		$pageFilters: PageInput = { first: 0 }
		$filters: ChatFilters = {}
		$companyId: ObjectId
	) {
		chats(pageFilters: $pageFilters, filters: $filters, companyId: $companyId) {
			edges {
				node {
					id
					_id
					companyId
					name
					status
					createdBy {
						type
						id
					}
					createdAt
					updatedAt
					messages(filters: { type: null }) {
						edges {
							node {
								id
								_id
								companyId
								chatId
								content
								read
								createdBy {
									type
									id
								}
								createdAt
								updatedAt
								deletedAt
							}
						}
					}
				}
			}
		}
	}
		`,
	key: `chats`,
	loggerMethod: `services.supportService.chats`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.supportService.chats`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

SupportService.issue.extend({
	getQuery: () => `
		query Chat($messagePageFilters: PageInput = { first: 0 }, $id: ID!, $companyId: ObjectId) {
			chat(id: $id, companyId: $companyId) {
				id
				_id
				companyId
				name
				status
				createdBy {
					id
					type
				}
				createdAt
				updatedAt
				messages(filters: { type: null }, pageFilters: $messagePageFilters) {
					edges {
						node {
							id
							_id
							companyId
							chatId
							content
							read
							createdBy {
								type
								id
							}
							createdAt
							updatedAt
							deletedAt
						}
					}
				}
			}
		}
	`,
	key: `chat`,
	loggerMethod: `services.supportService.chat`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.supportService.chat`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

SupportService.issue.extend({
	getQuery: () => `
	mutation CreateChats($input: [ChatCreateInput!]!, $companyId: ObjectId) {
		createChats(input: $input, companyId: $companyId) {
			ok
			error
			data {
				id
				_id
				companyId
			}
		}
	}
	`,
	key: `createChats`,
	loggerMethod: `services.supportService.createChats`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.supportService.createChats`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

SupportService.issue.extend({
	getQuery: () => `
	mutation UpdateChats($input: [ChatUpdateInput!]!, $companyId: ObjectId) {
		updateChats(input: $input, companyId: $companyId) {
			ok
			error
			data {
				id
				_id
			}
		}
	}
		`,
	key: `updateChats`,
	loggerMethod: `services.supportService.updateChats`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.supportService.updateChats`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

SupportService.issue.extend({
	getQuery: () => `
	mutation DeleteChats($input: [ChatDeleteInput!]!, $companyId: ObjectId) {
		deleteChats(input: $input, companyId: $companyId) {
			ok
			error
		}
	}
	`,
	key: `deleteChats`,
	loggerMethod: `services.supportService.deleteChats`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.supportService.deleteChats`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

SupportService.issue.extend({
	getQuery: () => `
	mutation CreateChatMessages(
		$input: [ChatMessageCreateInput!]!
		$companyId: ObjectId
	) {
		createChatMessages(input: $input, companyId: $companyId) {
			ok
			error
			data {
				id
				_id
				chatId
			}
		}
	}
	`,
	key: `createChatMessages`,
	loggerMethod: `services.supportService.createChatMessages`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.supportService.createChatMessages`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

SupportService.issue.extend({
	getQuery: () => `
	mutation UpdateChatMessages(
		$input: [ChatMessageUpdateInput!]!
		$companyId: ObjectId
	) {
		updateChatMessages(input: $input, companyId: $companyId) {
			ok
			error
			data {
				id
				_id
				chatId
			}
		}
	}
	`,
	key: `updateChatMessages`,
	loggerMethod: `services.supportService.updateChatMessages`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.supportService.updateChatMessages`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

SupportService.issue.extend({
	getQuery: () => `
	mutation DeleteChatMessages(
		$input: [ChatMessageDeleteInput!]!
		$companyId: ObjectId
	) {
		deleteChatMessages(input: $input, companyId: $companyId) {
			ok
			error
		}
	}
	`,
	key: `deleteChatMessages`,
	loggerMethod: `services.supportService.deleteChatMessages`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.supportService.deleteChatMessages`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

export async function chats({ accessToken, filters, companyId }) {
	const response = await SupportService.issue.extension.chats(
		{ companyId: companyId, filters: filters },
		{ token: accessToken },
	);

	return response;
}

export async function chat({ accessToken, companyId, id }) {
	const response = await SupportService.issue.extension.chat(
		{ companyId: companyId, id: id },
		{ token: accessToken },
	);

	return response;
}

export async function createChats({ accessToken, input, companyId }) {
	const response = await SupportService.issue.extension.createChats(
		// input {name: 'ChatName', status: ["PENDING", "RESOLVED", "DELETED"]}
		{ companyId: companyId, input: input },
		{ token: accessToken },
	);

	return response;
}
export async function updateChats({ accessToken, input, companyId }) {
	// input {id: 'chatId', name: 'ChatName', status: ["PENDING", "RESOLVED", "DELETED"]}

	const response = await SupportService.issue.extension.updateChats(
		{ companyId: companyId, input: input },
		{ token: accessToken },
	);

	return response;
}
export async function deleteChats({ accessToken, input, companyId }) {
	// input {id: 'chatId'}

	const response = await SupportService.issue.extension.deleteChats(
		{ companyId: companyId, input: input },
		{ token: accessToken },
	);

	return response;
}
export async function createChatMessages({ accessToken, input, companyId }) {
	// input {id: 'chatId', content: 'string', read: 'boolean'}
	const response = await SupportService.issue.extension.createChatMessages(
		{ companyId: companyId, input: input },
		{ token: accessToken },
	);

	return response;
}
export async function updateChatMessages({ accessToken, input, companyId }) {
	// input {id: 'messageId???', content: 'string', read: 'boolean'}
	const response = await SupportService.issue.extension.updateChatMessages(
		{ companyId: companyId, input: input },
		{ token: accessToken },
	);

	return response;
}
export async function deleteChatMessages({ accessToken, input, companyId }) {
	// input {id: 'messageId???'}
	const response = await SupportService.issue.extension.deleteChatMessages(
		{ companyId: companyId, input: input },
		{ token: accessToken },
	);

	return response;
}
