import React from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import { OnboardingGatewayModal } from '@asteria/component-integrations-v2/gateway';

import { useBackgroundLocation } from '@asteria/utils-hooks/navigation';
import { AuthValidate, Redirect } from '@asteria/widget-base';

import BehaviorValidation from './behavior';
import { LayoutContext } from './context';
import AccountsPage from './pages/accounts';
import AuthPage from './pages/auth';
import CashflowPage from './pages/cashflow';
import ClientsPage from './pages/clients';
import FinancialPage from './pages/financial';
import ForecasterPage from './pages/forecaster';
import OnboardingPage from './pages/onboarding';
import OverviewPage from './pages/overview';
import SettingsPage from './pages/settings';
import StatementPage from './pages/statement';
import StreamlinePage from './pages/streamline';
import SwedbankPage from './pages/swedbank';
import WelcomePage from './pages/welcome';

const routes = [
	{
		type: 'cashflow',
		index: true,
		page: <CashflowPage />,
	},
	{
		type: 'forecaster',
		path: 'forecaster/*',
		page: <ForecasterPage />,
	},
	{
		type: 'financial',
		path: 'financial/*',
		page: <FinancialPage />,
	},
	{
		type: 'onboarding',
		path: 'onboarding/*',
		page: <OnboardingPage />,
	},
	{
		type: 'settings',
		path: 'settings/*',
		page: <SettingsPage />,
	},
	{
		type: 'accounts',
		path: 'accounts/*',
		page: <AccountsPage />,
	},
	{
		type: 'overview',
		path: 'overview/*',
		page: <OverviewPage />,
	},
	{
		type: 'statement',
		path: 'statement/*',
		page: <StatementPage />,
	},
	{
		type: 'clients',
		path: 'clients/*',
		page: <ClientsPage />,
	},
	{
		type: 'welcome',
		path: 'welcome/*',
		page: <WelcomePage />,
	},
	{
		type: 'streamline',
		path: 'insights/*',
		page: <StreamlinePage />,
	},
	{
		type: 'swedbank',
		path: 'swedbank/*',
		page: <SwedbankPage />,
	},
].map((options, index) => (
	<Route
		key={options?.path ?? index}
		index={options?.index}
		path={options?.path}
		element={
			<AuthValidate>
				<BehaviorValidation type={options?.type}>
					{options?.page}
				</BehaviorValidation>
			</AuthValidate>
		}
	/>
));

const ModalGateway = React.memo(function ModalGateway() {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const location = useLocation();

	return (
		<>
			<OnboardingGatewayModal
				open={location?.pathname?.includes?.('/onboarding')}
				onAction={onAction}
				onSubmit={onSubmit}
			/>
		</>
	);
});

const Routing = React.memo(function Routing({ auth, partnerId }) {
	const location = useLocation();
	const backgroundLocation = useBackgroundLocation();

	return (
		<>
			<Routes location={backgroundLocation}>
				{routes}
				<Route
					path="recover/*"
					element={<Redirect to="/auth/recover" />}
				/>
				<Route
					path="auth/*"
					element={
						<AuthPage {...(auth ?? {})} partnerId={partnerId} />
					}
				/>
				<Route path="*" element={<Redirect to="/" />} />
			</Routes>
			{location?.state?.backgroundLocation ? <ModalGateway /> : null}
		</>
	);
});

Routing.propTypes = { auth: PropTypes.object, partnerId: PropTypes.string };

export default Routing;
