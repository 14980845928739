import { useQuery } from '@tanstack/react-query';

import { getRequest } from './utils';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/**
 * @param { Props } options
 */
export function useRequest({ onSubmit, startDate, endDate, dataloader }) {
	return useQuery(getRequest({ startDate, endDate, onSubmit, dataloader }));
}
