import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { Translation, TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import { OnboardingGuideSwitch } from '../../components/integration-guide';
import OnboardingWrapper from '../../components/onboarding-wrapper';
import { GuideContext } from '../../context';
import { useIntegrationName, usePlaceholder } from '../../hooks';

import IntegrationSuccessBox from './box';
import IntegrationSuccessFeedback from './feedback';
import { useAnalytics } from './hooks';

/**
 * @typedef Props
 * @property { string } className
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { import('react').MouseEventHandler } onClose
 */

/** @type { React.FC<Props> } */
const PageSuccess = React.memo(function PageSuccess(props) {
	const { onClose, onAction } = props;

	const { id } = useParams();
	const location = useLocation();

	const guided = React.useContext(GuideContext);

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, id),
		isEqual,
	);

	const analyticsFlow = useAnalytics(integration);

	const close = React.useCallback(
		(event) => {
			Analytics.endFlow(analyticsFlow);

			return onClose?.(event, { size: Infinity });
		},
		[analyticsFlow, onClose],
	);

	const done = React.useCallback(
		(event) => {
			Analytics.endFlow(analyticsFlow);

			return onClose?.(event, { size: Infinity });
		},
		[analyticsFlow, onClose],
	);

	const type = integration?.type ?? location?.state?.integration?.type;
	const key =
		integration?.key ??
		integration?.name ??
		location?.state?.integration?.key;

	const integrationName = useIntegrationName(type, key);
	const placeholder = usePlaceholder(type, key, 'success');

	if (!integration) {
		return (
			<Navigate
				to={`/onboarding/${type}/${key}`}
				state={location.state}
				replace
			/>
		);
	}

	return (
		<Wrapper
			className={cn(
				'asteria-component__onboarding-step',
				'asteria--variant-success',
			)}
		>
			<Header onClose={close}>
				<div
					className={cn(
						'flex w-full justify-between flex-col tablet:flex-row items-start tablet:items-center gap-4',
					)}
				>
					<Translation
						translationKey={[
							`integrations.add.title`,
							`integrations.${type}.add.title`,
							`integrations.${key}.add.title`,
							`integrations.${type}.${key}.add.title`,
							`integrations.success.title`,
							`integrations.${type}.success.title`,
							`integrations.${key}.success.title`,
							`integrations.${type}.${key}.success.title`,
						]}
						data={{ name: integrationName }}
						Component={Title}
					/>

					{placeholder ? (
						<OnboardingGuideSwitch
							onClick={guided.toggle}
							active={guided.state}
						/>
					) : null}
				</div>
			</Header>
			<OnboardingWrapper placeholder={placeholder}>
				<Content className="gap-4">
					<IntegrationSuccessBox
						integration={integration}
						integrationName={integrationName}
						type={type}
					/>
					<IntegrationSuccessFeedback
						type={type}
						name={key}
						onAction={onAction}
					/>
				</Content>
				<Footer>
					<FooterSection position="last">
						<Button
							analyticsKey={`integrations.${key}.done`}
							variant="primary"
							label={TranslationService.get(
								[
									'action.done',
									'button.done',
									'onboarding.modal.action',
									'onboarding.modal.action.done',
									'onboarding.modal.flow-success.action',
									'onboarding.modal.flow-success.action.done',
									`onboarding.modal.${integration?.type}.action`,
									`onboarding.modal.${integration?.type}.action.done`,
									`onboarding.modal.${integration?.type}.flow-success.action`,
									`onboarding.modal.${integration?.type}.flow-success.action.done`,
									`onboarding.modal.${integration?.key}.action`,
									`onboarding.modal.${integration?.key}.action.done`,
									`onboarding.modal.${integration?.key}.flow-success.action`,
									`onboarding.modal.${integration?.key}.flow-success.action.done`,
									`onboarding.modal.${integration?.type}.${integration?.key}.action`,
									`onboarding.modal.${integration?.type}.${integration?.key}.action.done`,
									`onboarding.modal.${integration?.type}.${integration?.key}.flow-success.action`,
									`onboarding.modal.${integration?.type}.${integration?.key}.flow-success.action.done`,
								],
								undefined,
								{ name: integrationName },
							)}
							onClick={done}
						/>
					</FooterSection>
				</Footer>
				{guided.state && !!placeholder ? (
					<div className="asteria-component__onboarding-placeholder-wrapper">
						{placeholder}
					</div>
				) : null}
			</OnboardingWrapper>
		</Wrapper>
	);
});

PageSuccess.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PageSuccess;
export { IntegrationSuccessFeedback };
