import React from 'react';

import PropTypes from 'prop-types';

import { Content } from '@asteria/component-core/wrapper';

import { Wrapper as FormWrapper } from '@asteria/component-form';

import IntegrationField from './field';

/** @type { React.FC<{ type: string, name: string, flow: unknown }> } */
const ConfigurationCode = React.memo(function ConfigurationCode(props) {
	const { type, name: key, flow } = props;

	const fieldConfiguration = React.useMemo(() => {
		let fields = flow?.fields ?? [];

		if (Array.isArray(fields)) {
			fields = fields[0];
		}

		return {
			key: fields?.key ?? 'config.client.authorizationCode',
			label: fields?.label ?? 'label.API-Key',
		};
	}, [flow]);

	return (
		<FormWrapper>
			<Content>
				{[
					{
						key: fieldConfiguration?.key,
						label: fieldConfiguration?.label,
						flags: { required: true },
					},
				].map((config) => (
					<IntegrationField
						key={config.key}
						type={type}
						integrationKey={key}
						field={config}
					/>
				))}
			</Content>
		</FormWrapper>
	);
});

ConfigurationCode.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
	flow: PropTypes.object,
};

export default ConfigurationCode;
