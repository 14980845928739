import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import OnboardingGuideSwitch from './switch';

const OnboardingGuide = React.memo(function OnboardingGuide(props) {
	const { className, show, flow } = props;

	const style = React.useMemo(
		() => ({
			'--image': `url(${flow?.steps?.configuration?.placeholder?.src})`,
		}),
		[flow?.steps?.configuration?.placeholder?.src],
	);

	if (!show) {
		return null;
	}

	return (
		<div
			className={cn(
				'flex justify-end',
				'asteria-component__onboarding-step__content__guide',
				className,
			)}
		>
			<div
				className={cn('bg-contain bg-no-repeat h-96 w-96 bg-right')}
				style={style}
			/>
		</div>
	);
});

OnboardingGuide.displayName = 'OnboardingGuide';

OnboardingGuide.propTypes = {
	className: PropTypes.string,
	show: PropTypes.bool,
	flow: PropTypes.object,
};

export default OnboardingGuide;
export { OnboardingGuideSwitch };
