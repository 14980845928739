import { TransactionService } from '@asteria/backend-utils-services';

import { setSettings } from '@asteria/datalayer/stores/app';

export async function fetch({ accessToken, dispatch, dataloader }) {
	const response = await TransactionService.transaction
		.fetchCurrencies({ dataloader }, { token: accessToken })
		.catch(() => ['SEK', 'EUR', 'USD']);

	dispatch?.(setSettings({ currencies: response }));

	return response;
}
