import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import * as AppStore from '@asteria/datalayer/stores/app';

import { useQueryDoneCallback } from '@asteria/utils-hooks/useQueryDoneCallback';

import * as UserAPI from '../api/user';

/** @type { React.FC<React.PropsWithChildren<{ accessToken: string }>> } */
const AuthWrapper = React.memo(function AuthWrapper(props) {
	const { children, accessToken } = props;

	const dispatch = useDispatch();

	const token = useSelector(
		(store) => AppStore.selectors.accessToken(store) ?? accessToken,
		(a, b) => isEqual(a, b),
	);

	const loggedIn = useSelector(
		(store) => AppStore.selectors.auth(store)?.loggedIn,
		(a, b) => isEqual(a, b),
	);

	const query = useQuery({
		queryKey: ['widget', 'auth'],
		queryFn: async ({ signal }) => {
			if (!token) {
				return false;
			}

			try {
				const response = await UserAPI.me({
					accessToken: token,
					fields: `_id authRecord`,
					signal: signal,
					raise: true,
				});

				return {
					token: token,
					'2fa': response?.authRecord?.twoAuth?.active === false,
				};
			} catch (err) {
				return false;
			}
		},
		enabled: !loggedIn,
	});

	useQueryDoneCallback(
		query,
		React.useCallback(
			(data) => {
				if (!data) {
					dispatch(AppStore.setLoginStatus(false));
					return;
				}

				if (data?.['2fa']) {
					dispatch(AppStore.register2FA(true));
				}

				dispatch(AppStore.setLoginStatus(true));
				dispatch(AppStore.setAccessToken(data?.token));
			},
			[dispatch],
		),
	);

	if (loggedIn === null) {
		return null;
	}

	return <>{children}</>;
});

AuthWrapper.propTypes = {
	children: PropTypes.node,
	accessToken: PropTypes.string,
};

export default AuthWrapper;
