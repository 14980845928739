import React from 'react';

import PropTypes from 'prop-types';
import {
	CartesianGrid,
	ComposedChart,
	Line,
	ReferenceLine,
	ResponsiveContainer,
} from 'recharts';

import { cn } from '@asteria/utils-funcs/classes';

import { LOADING_POINTS } from './constants';

const HISTORY_DOT = { r: 5 };
const FORECAST_DOT = { strokeDasharray: 0, r: 5 };

/** @type { React.FC<{ animated: boolean }> } */
const Loader = React.memo(function Loader(props) {
	const { animated } = props;

	return (
		<div className="h-60">
			<ResponsiveContainer width="100%" height="100%">
				<ComposedChart data={LOADING_POINTS}>
					<CartesianGrid
						stroke="var(--color-graph-yaxis-part-line-normal-background,var(--color-graph-part-line-background,#cfcfcf))"
						strokeWidth={0.5}
						vertical={false}
					/>

					<ReferenceLine
						y={0}
						stroke="var(--color-graph-yaxis-part-line-normal-zero, var(--color-graph-part-line-zero, var(--color-zero-normal, var(--color-zero, rgba(207, 207, 207, 1)))))"
						// strokeWidth={2}
					/>
					<Line
						type="bump"
						dataKey="value"
						stroke="var(--color-skeleton-normal-background, var(--color-progress-background, rgba(235, 231, 226, 1)))"
						className={cn({
							'animate-pulse': animated,
						})}
						strokeWidth={3}
						dot={HISTORY_DOT}
						animationDuration={1_500}
						animationEasing="linear"
						isAnimationActive={false}
					/>
					<Line
						type="bump"
						dataKey="forecast"
						stroke="var(--color-skeleton-normal-background, var(--color-progress-background, rgba(235, 231, 226, 1)))"
						className={cn({
							'animate-pulse': animated,
						})}
						strokeWidth={3}
						strokeDasharray={8}
						dot={FORECAST_DOT}
						animationDuration={1_500}
						animationEasing="linear"
						isAnimationActive={false}
					/>
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	);
});

Loader.propTypes = { animated: PropTypes.bool };

export default Loader;
