import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text } from '@asteria/component-core/typography';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import * as FormatUtils from '@asteria/utils-funcs/format';
import { useBackgroundLocation } from '@asteria/utils-hooks/navigation';

import './styles.scss';

function useBreadcrumbs() {
	const { pathname } = useBackgroundLocation();

	const parts = pathname.split('/').filter(Boolean);

	const [, , forecasterActionId] = parts;
	const [, , forecasterType, forecasterTagId] = parts;
	const [, , financialSimulationId] = parts;

	const forecasterAction = useSelector((store) =>
		ForecasterStore.selectors.actions.value(store, forecasterActionId),
	);

	const forecasterCategory = useSelector((store) =>
		AppStore.selectors.tag(store, forecasterTagId),
	);

	const financialSimulation = useSelector((store) =>
		ScenarioStore.selectors.scenario(store, financialSimulationId),
	);

	if (pathname.includes('/welcome')) {
		return [];
	}

	if (pathname.includes('forecaster/action')) {
		return []
			.concat(parts.slice(0, 1))
			.concat(
				parts.slice(2, 3).map((actionId) => ({
					type: 'forecaster-action',
					path: actionId,
					data: forecasterAction,
					label: FormatUtils.formatTag({
						type: forecasterAction?.extra?.type,
						category: forecasterAction?.tag?.category?.name,
						tag: forecasterAction?.tag?.name,
					}),
				})),
			)
			.concat(parts.slice(3));
	}

	if (pathname.includes('forecaster/category')) {
		return []
			.concat(parts.slice(0, 3))
			.concat(
				parts.slice(3, 4).map((tagId) => {
					if (tagId === '$adjusted') {
						return {
							type: 'forecaster-category',
							path: tagId,
							data: {
								type: forecasterType,
								category: { name: '$forecaster' },
								name: '$adjusted',
							},
							label: FormatUtils.formatTag({
								type: forecasterType,
								category: '$forecaster',
								tag: '$adjusted',
							}),
						};
					}

					return {
						type: 'forecaster-category',
						path: tagId,
						data: forecasterCategory,
						label: FormatUtils.formatTag({
							type: forecasterType,
							category: forecasterCategory?.category?.name,
							tag: forecasterCategory?.name,
						}),
					};
				}),
			)
			.concat(parts.slice(4));
	}

	if (
		pathname.includes('financial/credit') ||
		pathname.includes('financial/loan')
	) {
		if (!financialSimulationId) {
			return parts;
		}

		return [].concat(parts.slice(0, 1)).concat(
			parts.slice(2, 3).map((simulationId) => {
				return {
					type: 'financial-simulation',
					path: simulationId,
					data: financialSimulation,
					label: financialSimulation?.name,
				};
			}),
		);
	}

	return parts;
}

const Breadcrumbs = React.memo((props) => {
	const { className, onAction, icon = 'chevron-right' } = props;

	const parts = useBreadcrumbs();
	const hasSeparatorFeature = useFeature('breadcrumbs-separator-translation');

	const onRedirect = React.useCallback(
		(uri) => () => onAction?.('redirect', uri),
		[onAction],
	);

	if (!parts.length) {
		return null;
	}

	return (
		<Group
			className={cn('asteria-component__breadcrumbs', className)}
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="left"
		>
			<Button
				variant="link"
				label={TranslationService.get([
					'widget.navigation.cashflow',
					'breadcrumbs.label',
					'breadcrumbs.cashflow.label',
					'breadcrumbs.initial.label',
					'breadcrumbs.initial.cashflow.label',
				])}
				size="sm"
				onClick={onRedirect('/')}
			/>
			{parts.map((part, index, arr) => {
				const pathname = part?.path ?? part;

				const before = arr.slice(0, index);
				const path = before.concat(pathname);
				const uri = '/' + path.join('/');

				return (
					<>
						{hasSeparatorFeature ? (
							<Text className="asteria-component__breadcrumbs-separator">
								{TranslationService.get(
									'breadcrumbs.separator',
								)}
							</Text>
						) : (
							<Icon
								key={[pathname, 'icon'].join('-')}
								icon={icon}
								size="xs"
							/>
						)}
						{arr.length - 1 === index ? (
							<Text size="sm">
								{TranslationService.get(
									[
										`widget.navigation.${pathname}`,
										part?.label,
										'breadcrumbs.label',
										`breadcrumbs.${pathname}.label`,
										`breadcrumbs.${path.join('.')}.label`,
									],
									part?.label,
								)}
							</Text>
						) : (
							<Button
								key={[pathname, 'button'].join('-')}
								variant="link"
								label={TranslationService.get(
									[
										`widget.navigation.${pathname}`,
										part?.label,
										'breadcrumbs.label',
										`breadcrumbs.${pathname}.label`,
										`breadcrumbs.${path.join('.')}.label`,
									],
									part?.label,
								)}
								size="sm"
								onClick={onRedirect(uri)}
							/>
						)}
					</>
				);
			})}
		</Group>
	);
});

Breadcrumbs.displayName = 'Breadcrumbs';

Breadcrumbs.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	icon: PropTypes.string,
};

export default Breadcrumbs;
