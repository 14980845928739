import React from 'react';

import { useSelector, useStore } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { Translation, TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import ButtonHelp from '../../components/button-help';
import { OnboardingGuideSwitch } from '../../components/integration-guide';
import OnboardingWrapper from '../../components/onboarding-wrapper';
import { GuideContext } from '../../context';
import { useIntegrationName, usePlaceholder } from '../../hooks';

import IntegrationErrorBox from './box';
import IntegrationErrorBoxContent from './box-content';
import IntegrationErrorFeedback from './feedback';
import { useAnalytics } from './hooks';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { import('react').MouseEventHandler } onClose
 */

/** @type { React.FC<Props> } */
const PageError = React.memo(function PageError(props) {
	const { onAction, onSubmit, onClose } = props;

	const { id } = useParams();
	const location = useLocation();

	const store = useStore();

	const guided = React.useContext(GuideContext);

	const integration = useSelector((store) =>
		IntegrationStore.selectors.integration(store, id),
	);

	const analyticsFlow = useAnalytics(integration);

	const close = React.useCallback(
		(event) => {
			Analytics.endFlow(analyticsFlow);

			return onClose?.(event, { size: Infinity });
		},
		[analyticsFlow, onClose],
	);

	const abort = React.useCallback(
		(event) => {
			const integration = IntegrationStore.selectors.integration(
				store.getState(),
				id,
			);

			if (!integration?.actions?.length) {
				onSubmit?.('integrations:delete', integration);
			}

			Analytics.endFlow(analyticsFlow);

			return onClose?.(event);
		},
		[analyticsFlow, id, onClose, onSubmit, store],
	);

	const back = React.useCallback(() => {
		const integration = IntegrationStore.selectors.integration(
			store.getState(),
			id,
		);

		const shouldDelete = !integration?.actions?.length;

		if (shouldDelete) {
			onSubmit?.('integrations:delete', integration);
		}

		const type = integration?.type;
		const key = integration?.key ?? integration?.name;

		if (shouldDelete) {
			return onAction?.('go', {
				path: `/onboarding/${type}/${key}/configuration`,
				state: {
					form: {
						config: { client: integration?.config?.client },
					},
					from: null,
				},
				replace: true,
			});
		}

		return onAction?.('go', {
			path: `/onboarding/${id}`,
			state: {
				form: {
					$id: id,
					config: { client: integration?.config?.client },
				},
			},
			replace: true,
		});
	}, [store, id, onAction, onSubmit]);

	const type = integration?.type ?? location?.state?.integration?.type;
	const key =
		integration?.key ??
		integration?.name ??
		location?.state?.integration?.key;

	const integrationName = useIntegrationName(type, key);
	const placeholder = usePlaceholder(type, key, 'error');

	if (!integration) {
		return (
			<Navigate
				to={`/onboarding/${type}/${key}`}
				state={location.state}
				replace
			/>
		);
	}

	return (
		<Wrapper
			className={cn(
				'asteria-component__onboarding-step',
				'asteria--variant-error',
			)}
			variant="custom"
		>
			<Header onBack={back} onClose={close}>
				<div
					className={cn(
						'flex w-full justify-between flex-col tablet:flex-row items-start tablet:items-center gap-4',
					)}
				>
					<Translation
						translationKey={[
							`integrations.add.title`,
							`integrations.${type}.add.title`,
							`integrations.${key}.add.title`,
							`integrations.${type}.${key}.add.title`,
							`integrations.error.title`,
							`integrations.${type}.error.title`,
							`integrations.${key}.error.title`,
							`integrations.${type}.${key}.error.title`,
						]}
						data={{ name: integrationName }}
						Component={Title}
					/>

					{placeholder ? (
						<OnboardingGuideSwitch
							onClick={guided.toggle}
							active={guided.state}
						/>
					) : null}
				</div>
			</Header>
			<OnboardingWrapper placeholder={placeholder}>
				<Content className="gap-4">
					<IntegrationErrorBox
						integration={integration}
						integrationName={integrationName}
					>
						<IntegrationErrorBoxContent
							integrationName={integrationName}
							integration={integration}
						/>
					</IntegrationErrorBox>
					<IntegrationErrorFeedback
						name={integration?.key}
						onAction={onAction}
					/>
				</Content>
				<Footer>
					<FooterSection position="first">
						<Button
							analyticsKey={`integrations.${integration.key}.abort`}
							variant="tertiary"
							label={TranslationService.get(
								[
									'action.abort',
									'button.abort',
									'onboarding.modal.action',
									'onboarding.modal.action.abort',
									'onboarding.modal.flow-error.action',
									'onboarding.modal.flow-error.action.abort',
									`onboarding.modal.${type}.action`,
									`onboarding.modal.${type}.action.abort`,
									`onboarding.modal.${type}.flow-error.action`,
									`onboarding.modal.${type}.flow-error.action.abort`,
									`onboarding.modal.${key}.action`,
									`onboarding.modal.${key}.action.abort`,
									`onboarding.modal.${key}.flow-error.action`,
									`onboarding.modal.${key}.flow-error.action.abort`,
									`onboarding.modal.${type}.${key}.action`,
									`onboarding.modal.${type}.${key}.action.abort`,
									`onboarding.modal.${type}.${key}.flow-error.action`,
									`onboarding.modal.${type}.${key}.flow-error.action.abort`,
								],
								undefined,
								{ name: integrationName },
							)}
							onClick={abort}
						/>
					</FooterSection>
					<FooterSection position="last">
						<ButtonHelp
							onAction={onAction}
							onSubmit={onSubmit}
							extra={{
								from: 'onboarding',
								step: 'error',
								type,
								key,
								id,
							}}
						/>
					</FooterSection>
				</Footer>
				{guided.state && !!placeholder ? (
					<div className="asteria-component__onboarding-placeholder-wrapper">
						{placeholder}
					</div>
				) : null}
			</OnboardingWrapper>
		</Wrapper>
	);
});

PageError.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PageError;
