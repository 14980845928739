import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

/** @type { React.FC<{ className: string, count: boolean | number, animated: boolean, size?: 'sm' | 'md' }> } */
const Skeleton = React.memo(function Skeleton(props) {
	const { className, count = 1, animated = true, size } = props;

	return (
		<span
			className={cn(
				'flex flex-col gap-2 w-full',
				'asteria-component__skeleton',
				className,
			)}
		>
			{Array.from({ length: +count }).map((value, index) => (
				<span
					key={index}
					className={cn(
						'bg-skeleton-normal-background h-2 w-full rounded-full',
						{ 'h-1': size == 'sm' },
						{ 'animate-pulse': animated },
					)}
				/>
			))}
		</span>
	);
});

Skeleton.displayName = 'Skeleton';

Skeleton.propTypes = {
	className: PropTypes.string,
	animated: PropTypes.bool,
	count: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	size: PropTypes.string,
};

export default Skeleton;
