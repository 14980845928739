import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const IntegrationErrorBox = React.memo(function IntegrationErrorBox(props) {
	const { integration, integrationName, type, children } = props;

	return (
		<div
			className={cn(
				'flex flex-col bg-alert-error-normal-background gap-4 p-4 rounded-lg',
				'asteria-component__onboarding-status-alert',
				'asteria--status-error',
			)}
		>
			<div className="flex flex-row items-center gap-2 justify-center">
				<Icon
					icon="alert"
					className="bg-alert-error-icon-normal-background h-8 w-8 p-2 rounded-full"
				/>
				<Text size="md">
					{TranslationService.get(
						[
							`integrations.error.title`,
							`integrations.${type}.error.title`,
							`integrations.${type}.${integration.key}.error.title`,
							`integrations.error.alert.title`,
							`integrations.${type}.error.alert.title`,
							`integrations.${type}.${integration.key}.error.title`,
						],
						undefined,
						{
							integration: integration,
							name: integrationName,
						},
					)}
				</Text>
			</div>
			{children}
		</div>
	);
});

IntegrationErrorBox.propTypes = {
	type: PropTypes.string,
	integration: PropTypes.object,
	integrationName: PropTypes.string,
	error: PropTypes.shape({
		code: PropTypes.number,
		message: PropTypes.string,
	}),
	children: PropTypes.node,
};

export default IntegrationErrorBox;
