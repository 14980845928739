import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import * as AppStore from '@asteria/datalayer/stores/app';

/** @type { React.FC<{ otherwise?: React.ReactNode }> } */
const AuthValidate = React.memo(function AuthValidate({ children, otherwise }) {
	const location = useLocation();
	const [search] = useSearchParams();

	const valid = useSelector(
		(store) => AppStore.selectors.auth(store)?.loggedIn,
		(a, b) => isEqual(a, b),
	);

	if (valid === null) {
		return null;
	}

	if (valid) {
		if (location.pathname === '/auth/login') {
			return (
				<Navigate
					to={['/', search.toString()].filter(Boolean).join('?')}
				/>
			);
		}

		return children;
	}

	return (
		otherwise ?? (
			<Navigate
				to={['/auth/login', search.toString()]
					.filter(Boolean)
					.join('?')}
			/>
		)
	);
});

AuthValidate.displayName = 'AuthValidate';
AuthValidate.propTypes = {
	children: PropTypes.node,
	otherwise: PropTypes.node,
};

export default AuthValidate;
