import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';

import { useIntegrationName } from '../../hooks';

/** @type { React.FC<{ type: string, name: string, flow: unknown }> } */
const ConfigurationCustom = React.memo(function ConfigurationCustom(props) {
	const { type, name: key } = props;

	const integrationName = useIntegrationName(type, key);

	return (
		<div className="flex flex-col">
			<FeatureFlag feature="integrations.custom.external" invert>
				<Button
					analyticsKey={`integrations.${key}.help`}
					className="asteria-component__onboarding-step__help"
					variant="assist"
					label={TranslationService.get(
						[
							`integrations.add.download`,
							`integrations.${type}.download`,
							`integrations.${type}.${key}.download`,
						],
						undefined,
						{ name: integrationName },
					)}
					href="https://dev-api.asteria.ai/assets/asteria/files/AsteriaIntegrations.exe"
					target="_blank"
				/>
				<Button
					analyticsKey={`integrations.${key}.help`}
					className="asteria-component__onboarding-step__help"
					variant="assist"
					label={TranslationService.get(
						[
							`integrations.add.download`,
							`integrations.${type}.plugin.download`,
							`integrations.${type}.${key}.plugin.download`,
						],
						undefined,
						{ name: integrationName },
					)}
					href="https://dev-api.asteria.ai/assets/asteria/files/AsteriaIntegrationsVisma.asar"
					target="_blank"
				/>
			</FeatureFlag>

			<FeatureFlag feature="integrations.custom.external">
				<Button
					analyticsKey={`integrations.${key}.help`}
					className="asteria-component__onboarding-step__help"
					variant="assist"
					label={TranslationService.get(
						[
							`integrations.add.external`,
							`integrations.${type}.add.external`,
							`integrations.${type}.${key}.add.external`,
						],
						undefined,
						{ name: integrationName },
					)}
					href="https://www.asteria.ai/en/integrations/accounting-software/visma-administration"
					target="_blank"
				/>
			</FeatureFlag>
		</div>
	);
});

ConfigurationCustom.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
	flow: PropTypes.object,
};

export default ConfigurationCustom;
