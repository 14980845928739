import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';
import Contenter from '@asteria/component-tools/contenter';
import {
	useBulkFeatures,
	useFeature,
} from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';

import { CATEGORIES } from '../../constants';
import WelcomeContext from '../../context';
import { getTagLabel, replace, shuffle } from '../../utils';

import './styles.scss';

const DoneBank = (props) => {
	const { onAction } = props;

	const { placeholder: ctxPlaceholder } = React.useContext(WelcomeContext);
	const config = ctxPlaceholder?.done?.bank;

	const actions = React.useMemo(() => ({ onAction: onAction }), [onAction]);

	return (
		<Contenter
			className="asteria-view__welcome__placeholder-content"
			content={config}
			actions={actions}
		/>
	);
};

DoneBank.displayName = 'DoneBank';
DoneBank.propTypes = { onAction: PropTypes.func };

const DoneERP = (props) => {
	const { onAction } = props;

	const { placeholder: ctxPlaceholder } = React.useContext(WelcomeContext);
	const config = ctxPlaceholder?.done?.erp;

	const actions = React.useMemo(() => ({ onAction: onAction }), [onAction]);

	return (
		<Contenter
			className="asteria-view__welcome__placeholder-content"
			content={config}
			actions={actions}
		/>
	);
};

DoneERP.displayName = 'DoneERP';
DoneERP.propTypes = { onAction: PropTypes.func };

const DoneBoth = () => {
	const hasERPFeature = useFeature('welcome-actions-action-erp');

	const hasWelcomeForecaster = useBulkFeatures([
		['forecaster', 'widget-welcome-page-forecaster-redirect'],
	]);

	const { placeholder: ctxPlaceholder } = React.useContext(WelcomeContext);
	const config = ctxPlaceholder?.done?.both;

	const categories = React.useMemo(() => {
		let data = [].concat(CATEGORIES.BANK);

		if (hasERPFeature) {
			data = data.concat(CATEGORIES.ERP);
		}

		return shuffle(data).slice(0, 5);
	}, [hasERPFeature]);

	return (
		<div className="asteria-view__welcome__placeholder-content">
			<Contenter content={config} />
			<Text>
				{TranslationService.get(
					[
						'welcome.placeholder.done.both.categories',
						!hasWelcomeForecaster &&
							'welcome.placeholder.done.both.categories.cashflow',
						hasWelcomeForecaster &&
							'welcome.placeholder.done.both.categories.forecaster',
					].filter(Boolean),
				)}
			</Text>
			<Group
				direction="horizontal"
				verticalAlign="center"
				horizontalAlign="left"
				className="asteria-view__welcome__placeholder-content__categories"
			>
				{categories.map((value) => (
					<Chip
						key={value}
						label={getTagLabel(value)}
						colors={replace(value)}
						variant="simple"
					/>
				))}
			</Group>
		</div>
	);
};

DoneBoth.displayName = 'DoneBoth';
DoneBoth.propTypes = { onAction: PropTypes.func };

const Done = (props) => {
	const { className, onAction } = props;

	const hasERPFeature = useFeature('welcome-actions-action-erp');

	const type = useSelector(
		(store) => {
			const objects = IntegrationStore.selectors.integrations(store, {
				connected: true,
			});

			const hasERP =
				!hasERPFeature || objects.some(({ type }) => type === 'erp');

			const hasBank = objects.some(({ type }) => type === 'bank');

			if (hasERP && hasBank) {
				return 'both';
			}

			if (hasERP) {
				return 'erp';
			}

			return 'bank';
		},
		(a, b) => isEqual(a, b),
	);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'connect') {
				return onAction?.('go', {
					path: '/onboarding/connect',
					state: { type: data },
				});
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	return (
		<div
			className={className}
			direction="horizontal"
			verticalAlign="baseline"
		>
			{type === 'both' ? (
				<DoneBoth onAction={handleAction} />
			) : type === 'erp' ? (
				<DoneERP onAction={handleAction} />
			) : (
				<DoneBank onAction={handleAction} />
			)}
		</div>
	);
};

Done.displayName = 'Done';
Done.propTypes = { onAction: PropTypes.func, className: PropTypes.string };

export default React.memo(Done);
