import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text } from '@asteria/component-core/typography';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { Translation, TranslationService } from '@asteria/language';

import './styles.scss';

const Onboarding = React.memo(function Onboarding({ onAction }) {
	const hasBankFeature = useFeature('bank-integrations');

	const status = useSelector((store) => {
		const erp = !!IntegrationStore.selectors.integrations(store, {
			type: 'erp',
		}).length;

		const bank =
			!hasBankFeature ||
			!!IntegrationStore.selectors.integrations(store, {
				type: 'bank',
			}).length;

		if (!erp && !bank) {
			return 'NONE';
		}

		if (erp && bank) {
			return 'BOTH';
		}

		if (erp) {
			return 'ERP';
		}

		return 'BANK';
	});

	const onERPClick = React.useCallback(
		() =>
			onAction?.('go', {
				path: '/onboarding/connect',
				state: { type: 'erp' },
			}),
		[onAction],
	);

	const onBankClick = React.useCallback(
		() =>
			onAction?.('go', {
				path: '/onboarding/connect',
				state: { type: 'bank' },
			}),
		[onAction],
	);

	if (status === 'BOTH') {
		return null;
	}

	return (
		<div className="asteria-component__navigation-companies-onboarding">
			<Translation
				translationKey={[
					'navigation.companies.onboarding.description',
					status === 'NONE'
						? 'navigation.companies.onboarding.empty.description'
						: null,
					status === 'ERP'
						? 'navigation.companies.onboarding.erp.description'
						: null,
					status === 'BANK'
						? 'navigation.companies.onboarding.bank.description'
						: null,
					status === 'BOTH'
						? 'navigation.companies.onboarding.both.description'
						: null,
				]}
				Component={Text}
				size="xs"
			/>
			{['NONE', 'ERP'].includes(status) ? (
				<div className="truncate inline-flex">
					<Button
						size="sm"
						label={TranslationService.get([
							'navigation.companies.onboarding.action.label',
							'navigation.companies.onboarding.action.bank.label',
						])}
						variant="primary"
						onClick={onBankClick}
					/>
				</div>
			) : null}
			{['NONE', 'BANK'].includes(status) ? (
				<div className="truncate inline-flex">
					<Button
						size="sm"
						label={TranslationService.get([
							'navigation.companies.onboarding.action.label',
							'navigation.companies.onboarding.action.erp.label',
						])}
						variant="primary"
						onClick={onERPClick}
					/>
				</div>
			) : null}
		</div>
	);
});

Onboarding.propTypes = { onAction: PropTypes.func };

const Companies = React.memo(function Companies(props) {
	const { open, onSubmit, onAction } = props;

	const company = useSelector(
		(store) => {
			const object = AppStore.selectors.company(store);

			return {
				id: object?._id ?? object?.id,
				name: object?.name ?? TranslationService.get('header.title'),
			};
		},
		(a, b) => isEqual(a, b),
	);

	const companies = useSelector(
		(store) => AppStore.selectors.user(store)?.companies ?? [],
		(a, b) => isEqual(a, b),
	);

	const toggle = React.useMemo(() => {
		if (open) {
			return {
				icon: 'chevron-down',
				iconActive: 'chevron-up',
				iconPosition: 'last',
				label: company?.name,
			};
		}

		return {
			icon: ['companies', 'navigation-companies'],
		};
	}, [company?.name, open]);

	const onClick = React.useMemo(
		() => (id) => async () => {
			await onSubmit?.('auth:profile:change', { companyId: id });
		},
		[onSubmit],
	);

	return (
		<div className="asteria-component__navigation-companies">
			<Dropdown
				toggle={toggle}
				className="asteria-component__navigation-companies-dropdown"
			>
				{companies.map((object) => {
					const ID = object?._id ?? object?.id;
					const active = ID === company?.id;

					return (
						<DropdownItem
							key={ID}
							postfix={
								<Group
									verticalAlign="center"
									horizontalAlign="center"
								>
									<Icon icon="check" />
								</Group>
							}
							active={active}
							onClick={!active ? onClick(ID) : null}
						>
							{object?.name}
						</DropdownItem>
					);
				})}
				<Onboarding onAction={onAction} />
			</Dropdown>
		</div>
	);
});

Companies.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	open: PropTypes.bool,
};

export default Companies;
