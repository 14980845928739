import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';
import { useLocation, useOutletContext } from 'react-router-dom';

import CashflowView from '@asteria/view-cashflow';

import * as AppStore from '@asteria/datalayer/stores/app';

import { LayoutContext } from '../../context';
import { applyCashflowSettings } from '../../layout/utils/settings';
import * as selectors from '../../selectors';

import './styles.scss';

const CashflowPage = () => {
	const { onAction, onSubmit } = React.useContext(LayoutContext);
	const { wrapperRef, feedback } = useOutletContext();
	const location = useLocation();
	const accessToken = useSelector(AppStore.selectors.accessToken);
	const dispatch = useDispatch();
	const store = useStore();
	const [settingsApplied, setsettingsApplied] = React.useState(false);

	React.useEffect(() => {
		const restoreSettings = {
			activeBars: selectors.activeBars(store.getState()),
			currentDate: selectors.currentDate(store.getState()),
			selectedDate: selectors.selectedDate(store.getState()),
			graphTypes: selectors.graphTypes(store.getState()),
			graphOptions: selectors.graphOptions(store.getState()),
			graphActiveGroups: selectors.graphActiveGroups(store.getState()),
			filters: [],
		};

		applyCashflowSettings({
			settings: location?.state?.settings,
			state: store.getState(),
			dispatch: dispatch,
		});

		setsettingsApplied(true);

		return () => {
			applyCashflowSettings({
				settings: restoreSettings,
				state: store.getState(),
				dispatch: dispatch,
			});
		};
	}, [dispatch, location.state, store]);

	if (!settingsApplied) {
		return null;
	}

	return (
		<CashflowView
			onAction={onAction}
			onSubmit={onSubmit}
			onFetch={onSubmit}
			feedback={feedback}
			wrapperRef={wrapperRef}
			accessToken={accessToken}
		/>
	);
};

CashflowPage.displayName = 'CashflowPage';

CashflowPage.propTypes = {};

export default CashflowPage;
