import React from 'react';

import PropTypes from 'prop-types';

import { IntegrationSuccessFeedback } from '../success';

import BaseStep from './base';

const type = 'erp';
const key = 'printer';

const StepSuccess = React.memo(function StepSuccess(props) {
	const { onAction, onClose } = props;

	return (
		<BaseStep {...props} step="success" onDone={onClose}>
			<IntegrationSuccessFeedback
				type={type}
				name={key}
				onAction={onAction}
			/>
		</BaseStep>
	);
});

StepSuccess.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

export default StepSuccess;
