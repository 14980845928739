import React from 'react';

import PropTypes from 'prop-types';

import { useFlow } from '../../hooks';

import ConfigurationBasic from './basic';
import ConfigurationCode from './code';
import ConfigurationCustom from './custom';
import ConfigurationDynamic from './dynamic';

/** @type { React.FC<{ type: string, name: string }> } */
const ConfigurationGateway = React.memo(function ConfigurationGateway(props) {
	const { type, name: key } = props;

	const flow = useFlow(type, key);

	if (flow?.flow === 'code') {
		return <ConfigurationCode {...props} flow={flow} />;
	}

	if (flow?.flow === 'basic') {
		return <ConfigurationBasic {...props} flow={flow} />;
	}

	if (flow?.flow === 'custom') {
		return <ConfigurationCustom {...props} flow={flow} />;
	}

	// TODO: Implement Custom flow

	return <ConfigurationDynamic {...props} flow={flow} />;
});

ConfigurationGateway.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
};

export default ConfigurationGateway;
