import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { useFeature } from '@asteria/component-tools/featureflag';

import { Redirect } from '@asteria/widget-base';

import * as selectors from './selectors';

/** @type { React.FC<{ type: string }> } */
const BehaviorValidation = React.memo(function BehaviorValidation(props) {
	const { children, type } = props;

	const hasWelcomeFeature = useFeature('widget-welcome-page');
	const hasForecaster = useFeature('forecaster');
	const hasStreamlinedFeature = useFeature('streamlined-first-page');

	const isStreamlinedShownRef = React.useRef(null);
	const isWelcomeShown = useSelector(selectors.isWelcomeShown);
	const isForecasterShown = useSelector(selectors.isForecasterShown);

	if (type !== 'welcome') {
		if (hasWelcomeFeature) {
			if (!isWelcomeShown) {
				return <Redirect to="/welcome" />;
			}
		}

		if (type !== 'forecaster') {
			if (hasForecaster) {
				if (!isForecasterShown) {
					return <Redirect to="/forecaster" />;
				}
			}
		}

		if (!isStreamlinedShownRef?.current) {
			isStreamlinedShownRef.current = true;

			if (type === 'cashflow') {
				if (hasStreamlinedFeature) {
					return <Redirect to="/insights" />;
				}
			}
		}
	}

	return children;
});

BehaviorValidation.displayName = 'RedirectWrapper';
BehaviorValidation.propTypes = {
	children: PropTypes.node,
	type: PropTypes.string,
};

export default BehaviorValidation;
