import { useLayoutEffect, useMemo } from 'react';

function defaultCompare(ref, event) {
	return ref?.current?.contains?.(event?.target);
}

const useClickOutside = (
	ref,
	onClickOutside,
	parent,
	enabled = true,
	compare = defaultCompare,
) => {
	const node = useMemo(() => parent ?? document, [parent]);

	function onClick(event) {
		const refs = [].concat(ref);

		if (refs.every((ref) => ref?.current)) {
			const isPartOfRef = refs.some((ref) =>
				compare(ref, event, { default: defaultCompare }),
			);

			if (!isPartOfRef) {
				onClickOutside?.(event);
			}
		}
	}

	useLayoutEffect(() => {
		if (!enabled) {
			return;
		}

		node.addEventListener('click', onClick);

		return () => {
			node.removeEventListener('click', onClick);
		};
	}, [enabled, node]);
};

export default useClickOutside;
