import { formatISO, subMonths } from 'date-fns';

import { TranslationService } from '@asteria/language';
import { parseDate } from '@asteria/utils-funcs/normalize';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/**
 * @param { Props & { dataloader?: unknown } } options
 */
export function getRequest({ startDate, endDate, onSubmit, dataloader }) {
	return {
		queryKey: ['card', 'account', { startDate, endDate }],
		queryFn: async ({ meta }) => {
			return onSubmit?.('card:fetch', {
				type: 'account',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					startDate: formatISO(subMonths(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subMonths(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
				dataloader: meta?.dataloader,
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate,

		meta: { dataloader },
	};
}

export function formatData(data, dates) {
	const value =
		(data?.source?.PAID?.total ?? 0) + (data?.source?.FORECAST?.total ?? 0);

	return {
		value: data?.source?.PAID?.total ? value : null,
		forecast: data?.source?.FORECAST?.total ? value : null,
		credit: data?.source?.CREDIT?.total ?? 0,
		area: [
			data?.source?.FORECAST?.min ?? value,
			data?.source?.FORECAST?.max ?? value,
		],
		label: TranslationService.getV2(['card.content.chart.x-axis.label'], {
			data: { date: dates?.startDate ?? dates?.endDate },
			default: '{{ date | date:"MMMM" | capitalize }}',
		}),

		data: data?.source,
		dates: dates,
	};
}
