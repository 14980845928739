import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { CompleteScreen } from '@asteria/component-alert';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef Props
 * @property { string } className
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { import('react').MouseEventHandler } onClose
 * @property { string } type
 * @property { unknown } response
 */

/** @type { React.FC<Props> } */
const MissingSuccess = React.memo(function MissingSuccess(props) {
	const { type = 'both', response, onClose } = props;

	React.useLayoutEffect(() => {
		Analytics.startFlow('integrations.missing.success', {
			integrationType: type,
			response: response,
			integration: response?.title,
		});

		return () => {
			Analytics.endFlow('integrations.missing.success');
		};
	}, [type]);

	const handleClose = React.useCallback(
		(event) => {
			Analytics.endFlow('integrations.missing.success');

			return onClose?.(event);
		},
		[onClose],
	);

	const integration = response?.title;

	return (
		<Wrapper
			className={cn(
				'asteria-component__onboarding-step',
				'asteria--variant-missing',
				'asteria--step-success',
			)}
		>
			<Header onClose={handleClose}>
				{TranslationService.get([
					'integrations.missing.success.header',
					`integrations.missing.${type}.success.header`,
				])}
			</Header>
			<Content scroll>
				<CompleteScreen
					level="success"
					icon="check"
					iconPosition="first"
					title={TranslationService.get(
						[
							'integrations.missing.success.title',
							`integrations.missing.${type}.success.title`,
							`integrations.missing.success.title.${integration}`,
							`integrations.missing.${type}.success.title.${integration}`,
						],
						undefined,
						{
							integration: integration,
							key: integration,
							name: integration,
							response: response,
						},
					)}
				>
					<Title type="title" size="xs" align="center">
						{TranslationService.get(
							[
								'integrations.missing.success.subtitle',
								`integrations.missing.${type}.success.subtitle`,
								`integrations.missing.success.subtitle.${integration}`,
								`integrations.missing.${type}.success.subtitle.${integration}`,
							],
							undefined,
							{
								integration: integration,
								key: integration,
								name: integration,
								response: response,
							},
						)}
					</Title>
					<Text
						type="text"
						size="lg"
						weight="semibold"
						align="center"
						className="asteria-component__alert--highlight"
					>
						{TranslationService.get(
							[
								'integrations.missing.success.integration',
								`integrations.missing.${type}.success.integrations`,
								`integrations.missing.success.integrations.${integration}`,
								`integrations.missing.${type}.success.integrations.${integration}`,
							],
							'{{ name | cap }}',
							{
								integration: integration,
								key: integration,
								name: integration,
								response: response,
							},
						)}
					</Text>
					<Text align="center" size="sm">
						{TranslationService.get(
							[
								'integrations.missing.success.content',
								`integrations.missing.${type}.success.content`,
								`integrations.missing.success.content.${integration}`,
								`integrations.missing.${type}.success.content.${integration}`,
							],
							undefined,
							{
								integration: integration,
								key: integration,
								name: integration,
								response: response,
							},
						)}
					</Text>
				</CompleteScreen>
			</Content>
			<Footer>
				<FooterSection position="last">
					<Button
						variant="primary"
						label={TranslationService.get('action.done')}
						onClick={handleClose}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

MissingSuccess.propTypes = {
	type: PropTypes.string,
	response: PropTypes.object,
	onClose: PropTypes.func,
};

export default MissingSuccess;
