import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { differenceInMonths } from 'date-fns';
import { isEqual } from 'lodash-es';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { parseDate } from '@asteria/utils-funcs/normalize';

import { getDateVariant } from './utils';

export function useDateVariant({ startDate, endDate }) {
	return getDateVariant({ startDate, endDate });
}

/**
 * @typedef UseForecastAccuracyOptions
 * @property { 'account' | 'incoming' | 'outgoing' } type
 * @property { import('@tanstack/react-query').UseQueryResult } query
 *
 * @param { UseForecastAccuracyOptions } options
 */
export function useForecastAccuracy({ query, type }) {
	let paid = query?.data?.source?.PAID?.total ?? 0;
	let forecast = query?.data?.source?.FORECAST?.total ?? 0;

	if (type === 'invoices') {
		paid = query?.data?.source?.paid?.total;
		forecast = query?.data?.source?.forecast?.total;
	}

	let state = null;

	if (paid === forecast) {
		state = 'equal';
	} else if (paid > forecast) {
		state = 'above';
	} else {
		state = 'below';
	}

	const diffValue = Math.abs(paid - forecast);
	const diffPercentage = Math.abs(((paid - forecast) / forecast) * 100);

	const multiplier =
		Math.max(Math.abs(paid), Math.abs(forecast)) /
		Math.min(Math.abs(paid), Math.abs(forecast));

	const reached = (paid / forecast) * 100;

	return {
		diff: {
			value: Number.isFinite(diffValue) ? diffValue : 0,
			percentage: Number.isFinite(diffPercentage) ? diffPercentage : 0,
		},
		multiplier: Number.isFinite(multiplier) ? multiplier : 0,
		state: state,
		reached: Number.isFinite(reached) ? reached : 0,
	};
}

/**
 * @typedef UseAmountFlagsOptions
 * @property { import('@tanstack/react-query').UseQueryResult } query
 *
 * @param { UseAmountFlagsOptions } options
 */
export function useAmountFlags({ query }) {
	const hasUnpaid = !!query?.data?.source?.UNPAID?.total;
	const hasOverdue = !!query?.data?.source?.OVERDUE?.total;
	const hasForecast = !!query?.data?.source?.FORECAST?.total;
	const hasPaid = !!query?.data?.source?.PAID?.total;
	const hasCredit = !!query?.data?.source?.CREDIT?.total;

	return useMemo(
		() => ({
			unpaid: hasUnpaid,
			overdue: hasOverdue,
			forecast: hasForecast,
			paid: hasPaid,
			credit: hasCredit,
		}),
		[hasCredit, hasForecast, hasOverdue, hasPaid, hasUnpaid],
	);
}

/**
 * @typedef UseCreditValuesOptions
 * @property { import('@tanstack/react-query').UseQueryResult } query
 *
 * @typedef UseCreditValuesResponse
 * @property { number } approved
 * @property { number } balance
 * @property { number } utilized
 * @property { number } remaining
 *
 * @param { UseCreditValuesOptions } options
 * @returns { UseCreditValuesResponse }
 */
export function useCreditValues({ query }) {
	const paid = query?.data?.source?.PAID?.total ?? 0;
	const credit = Math.abs(query?.data?.source?.CREDIT?.total ?? 0);

	let utilized = 0;

	if (paid < 0) {
		utilized = credit - Math.abs(paid);
	}

	return {
		approved: credit,
		balance: paid,
		utilized: utilized,
		remaining: credit - utilized,
	};
}

export function useOnboardingState(connect) {
	const { erp, bank } = useSelector(
		(store) => {
			const erp =
				!!IntegrationStore.selectors
					.integrations(store, {
						type: 'erp',
					})
					.filter(({ config: { connected } }) => connected).length >
					0 || connect?.onboarding === 'erp';

			const bank =
				!!IntegrationStore.selectors
					.integrations(store, {
						type: 'bank',
					})
					.filter(({ config: { connected } }) => connected).length >
					0 || connect?.onboarding === 'bank';

			return { erp, bank };
		},
		(a, b) => isEqual(a, b),
	);

	if (!erp && !bank) {
		return 'none';
	}

	if (erp && bank) {
		return 'both';
	}

	if (erp) {
		return 'erp';
	}

	return 'bank';
}

/**
 * @typedef UseOnboardingStatusOptions
 * @property { 'bank' | 'erp' } [type]
 *
 * @param { UseOnboardingStatusOptions } options
 */
export function useOnboardingStatus({ type } = {}) {
	return useSelector((store) => {
		const integrations = IntegrationStore.selectors.integrations(store, {
			type: type,
		});

		if (!integrations.length) {
			return 'none';
		}

		if (
			integrations.every(
				(object) =>
					object?.config?.connected &&
					['IDLE', 'IMPORTING'].includes(object?.status?.state) &&
					!Math.abs(
						differenceInMonths(
							parseDate(object?.lastSync),
							new Date(),
						),
					),
			)
		) {
			return 'connected';
		}

		const { error, disabled, loading, outdated } = integrations.reduce(
			(acc, object) => {
				const config = object?.config;
				const errors = config?.errors ?? [];
				const status = object?.status?.state;
				const disabled = object?.disabled ?? false;
				const lastSync = object?.lastSync ?? null;

				if (!acc.error) {
					acc.error = !!errors?.length || ['ERROR'].includes(status);
				}

				if (!acc.disabled) {
					acc.disabled = disabled;
				}

				if (!acc.loading) {
					acc.loading = ['INITIATING', 'IMPORTING'].includes(status);
				}

				if (!acc.outdated && lastSync) {
					acc.outdated = !!Math.abs(
						differenceInMonths(parseDate(lastSync), new Date()),
					);
				}

				return acc;
			},
			{ error: false, disabled: false, loading: false, outdated: false },
		);

		if (error) {
			return 'error';
		}

		if (disabled) {
			return 'disabled';
		}

		if (loading) {
			return 'loading';
		}

		if (outdated) {
			return 'outdated';
		}

		return 'unknown';
	});
}

/**
 * @typedef UseDiffOptions
 * @property { import('@tanstack/react-query').UseQueryResult } query
 *
 * @param { UseDiffOptions } options
 */
export function useDiff({ query }) {
	let diff = {};

	for (const key in query?.data?.source ?? {}) {
		diff[key] = { ...query?.data?.source?.[key] };

		diff[key].total =
			Math.abs(query?.data?.source?.[key]?.total ?? 0) -
			Math.abs(query?.data?.target?.[key]?.total ?? 0);

		diff[key].count =
			Math.abs(query?.data?.source?.[key]?.count ?? 0) -
			Math.abs(query?.data?.target?.[key]?.count ?? 0);
	}

	return diff;
}

export function useZero({ type, query, diff, future }) {
	if (type === 'profit') {
		if (future) {
			return !(diff?.FORECAST?.total - diff?.PAID?.total);
		}

		return !diff?.PAID?.total;
	}

	if (type === 'client-performance') {
		return !Object.values(query?.data?.source ?? {}).reduce(
			(acc, object) => acc + (object?.invoices?.total?.actual ?? 0),
			0,
		);
	}

	if (type === 'supplier-performance' || type === 'customer-performance') {
		return !query?.data?.source?.invoices?.total?.actual;
	}

	if (future) {
		return !query?.data?.source?.FORECAST?.total;
	}

	return !query?.data?.source?.PAID?.total;
}

/**
 * @typedef UseTranslationDataOptions
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { 'up' | 'down' } trends
 * @property { 'account' | 'incoming' | 'outgoing' } type
 * @property { boolean } [past]
 * @property { boolean } [today]
 * @property { boolean } [future]
 *
 * @param { UseTranslationDataOptions } options
 */
export function useTranslationData({
	query,
	trends,

	type,

	startDate,
	endDate,

	past,
	today,
	future,

	connect,

	extra,
	loading,
	version,
}) {
	const accuracy = useForecastAccuracy({
		type: type,
		query: query,
	});

	const flags = useAmountFlags({ query });
	const credit = useCreditValues({ query });

	const onboarding = useOnboardingState(connect);

	const onboardingStatus = {
		both: useOnboardingStatus({ type: null }),
		bank: useOnboardingStatus({ type: 'bank' }),
		erp: useOnboardingStatus({ type: 'erp' }),
	};

	const diff = useDiff({ query });
	const zero = useZero({ diff, type, past, today, future, query });

	return {
		postfix: {
			variant: type,

			past: past,
			today: today,
			future: future,

			trends: trends,
			accuracy: accuracy?.state,

			unpaid: flags.unpaid,
			overdue: flags.overdue,
			forecast: flags.forecast,
			paid: flags.paid,
			credit: flags.credit,
			loading: loading,

			level: Array.from({
				length: Math.max(
					Math.min(Math.ceil(accuracy.reached / 25), 10),
					0,
				),
			})
				.map((_, index) => index)
				.slice(0, 10),

			onboarding: onboarding,
			'onboarding-status-both':
				onboardingStatus.both && !connect?.onboarding,
			'onboarding-status-erp':
				onboardingStatus.erp && connect?.onboarding !== 'erp',
			'onboarding-status-bank':
				onboardingStatus.bank && connect?.onboarding !== 'bank',

			zero: zero,
			version: version,

			...extra?.postfix,
		},
		data: {
			data: query?.data?.source,
			source: query?.data?.source,
			target: query?.data?.target,
			...(query?.data ?? {}),
			diff: diff,
			accuracy: accuracy,
			credit: credit,

			startDate,
			endDate,

			...extra?.data,
		},
	};
}

/**
 * @typedef UseTrendDirectionOptions
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { 'past' | 'today' | 'future' } type
 * @property { string } card
 *
 * @param { UseTrendDirectionOptions } options
 */
export function useTrendDirection({ card, query, type }) {
	if (card === 'invoices') {
		const source = query?.data?.source?.summary?.count ?? 0;
		const target = query?.data?.target?.summary?.count ?? 0;

		if (source > target) {
			return 'up';
		}

		return 'down';
	}

	if (card === 'health') {
		if (query?.data?.source?.trend > 0) {
			return 'up';
		}

		return 'down';
	}

	if (card === 'client-performance') {
		const source = Object.values(query?.data?.source ?? {}).reduce(
			(acc, object) => acc + (object?.invoices?.total?.actual ?? 0),
			0,
		);

		const target = Object.values(query?.data?.target ?? {}).reduce(
			(acc, object) => acc + (object?.invoices?.total?.actual ?? 0),
			0,
		);

		if (source === target) {
			return 'equal';
		}

		if (source > target) {
			return 'up';
		}

		return 'down';
	}

	if (card === 'customer-performance' || card === 'supplier-performance') {
		const source = Math.abs(
			query?.data?.source?.invoices?.total?.actual ?? 0,
		);
		const target = Math.abs(
			query?.data?.target?.invoices?.total?.actual ?? 0,
		);

		if (source === target) {
			return 'equal';
		}

		if (source > target) {
			return 'up';
		}

		return 'down';
	}

	if (type === 'future') {
		const source =
			(query?.data?.source?.FORECAST?.total ?? 0) +
			(query?.data?.source?.PAID?.total ?? 0);

		const target =
			(query?.data?.target?.FORECAST?.total ?? 0) +
			(query?.data?.target?.PAID?.total ?? 0);

		if (source > target) {
			return 'up';
		}

		return 'down';
	}

	const source = query?.data?.source?.PAID?.total ?? 0;
	const target = query?.data?.target?.PAID?.total ?? 0;

	if (source > target) {
		return 'up';
	}

	return 'down';
}

/**
 * @typedef UseCardVisibilityOptions
 * @property { string } type
 *
 * @param { UseCardVisibilityOptions } options
 */
export function useCardVisibility({ type }) {
	const onboarding = useOnboardingState();

	return useSelector(
		(store) => {
			const settings = AppStore.selectors.user(store)?.settings;
			const flags = settings?.flags?.['card:close'];
			const value = flags?.[type]?.[onboarding];

			return !value ?? true;
		},
		(a, b) => isEqual(a, b),
	);
}

/**
 * @typedef UseRequiredIntegrationsOptions
 * @property { string | string } required
 * @property { string | string } optional
 *
 * @param { UseRequiredIntegrationsOptions } options
 */

export function useRequiredIntegrations(options) {
	const onboarding = useOnboardingState();

	if (onboarding === 'both') {
		return { required: true, optional: true };
	}

	return {
		required:
			!options?.required ||
			(Array.isArray(options?.required)
				? options.required
				: [options?.required]
			).some((key) => onboarding === key),
		optional:
			!options?.optional ||
			(Array.isArray(options?.optional)
				? options.optional
				: [options?.optional]
			).some((key) => onboarding === key),
	};
}

/**
 * @typedef UseInitiatingIntegrationOptions
 * @property { string } type
 *
 * @param { UseInitiatingIntegrationOptions } options
 */
export function useInitiatingIntegration({ type }) {
	const { erp, bank } = useSelector(
		(store) => {
			const erp =
				IntegrationStore.selectors
					.integrations(store, {
						type: 'erp',
					})
					.filter((object) => object?.status?.state === 'INITIATING')
					.length > 0;

			const bank =
				IntegrationStore.selectors
					.integrations(store, {
						type: 'bank',
					})
					.filter((object) => object?.status?.state === 'INITIATING')
					.length > 0;

			return { erp, bank };
		},
		(a, b) => isEqual(a, b),
	);

	if (type === 'both' && (erp || bank)) {
		return true;
	} else if (type === 'erp' && erp) {
		return true;
	} else if (type === 'bank' && bank) {
		return true;
	}

	return false;
}

/**
 * @typedef UseCardPinningOptions
 * @property { string } type
 *
 * @param { UseCardPinningOptions } options
 * @returns { boolean }
 */
export function useCardPinning({ type }) {
	return useSelector(
		(store) =>
			AppStore.selectors.user(store)?.settings?.flags?.['card:pinned']?.[
				type
			] ?? false,
		(a, b) => isEqual(a, b),
	);
}

export function useUpdatedBy({ updatedBy }) {
	const companyName = useSelector(
		(store) => AppStore.selectors.company(store)?.name,
		isEqual,
	);

	const fullName = [updatedBy?.firstName, updatedBy?.lastName]
		.filter(Boolean)
		.join(' ');

	if (fullName) {
		return fullName;
	}

	if (companyName) {
		return companyName;
	}

	return TranslationService.getV2('user.name.default');
}
