import React from 'react';

import { useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { useToggleState } from '@asteria/utils-funcs/state';

import { useFlow } from '../hooks';

import IntegrationRemoveModal from './modal-remove';

/** @type { React.FC<{ id: string, onSubmit: () => void, onAction: () => void, onOpen: (options: { flow: unknown, integration: unknown }) => void }> } */
const IntegrationActions = React.memo(function IntegrationActions(props) {
	const { id, onAction, onSubmit, onOpen } = props;

	const [removeModalOpen, removeModalActions] = useToggleState(false);

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, id),
		isEqual,
	);

	const flow = useFlow(integration?.type, integration?.key);

	const mutations = {
		edit: useMutation({
			mutationFn: async () => onOpen?.({ flow, integration }),
		}),
		toggle: useMutation({
			mutationFn: async () => {
				if (integration.disabled) {
					return onSubmit?.('integrations:enable', integration);
				}

				return onSubmit?.('integrations:disable', integration);
			},
		}),
		fetch: useMutation({
			mutationFn: async () => {
				return onSubmit?.('integrations:import', integration);
			},
		}),
	};

	const ToggleProps = React.useMemo(() => ({ icon: 'cog' }), []);

	if (!integration) {
		return null;
	}

	return (
		<>
			<IntegrationRemoveModal
				open={removeModalOpen}
				onClose={removeModalActions.close}
				id={id}
				onSubmit={onSubmit}
				onAction={onAction}
			/>
			<Dropdown
				toggle={ToggleProps}
				title={TranslationService.get([
					'integrations.actions.title',
					'integrations.actions.dropdown.title',
				])}
				analyticsKey="integration.options"
			>
				<DropdownItem
					onClick={removeModalActions.open}
					analyticsKey="integration.options.item.delete"
				>
					{TranslationService.get(
						[
							'integrations.list.action.delete',
							`integrations.list.action.${integration?.name}.delete`,
						],
						undefined,
						integration,
					)}
				</DropdownItem>
				<FeatureFlag feature="integrations-edit">
					<DropdownItem
						onClick={mutations.edit.mutate}
						analyticsKey="integration.options.item.edit"
					>
						{TranslationService.get(
							[
								'integrations.list.action.edit',
								`integrations.list.action.${integration?.name}.edit`,
							],
							undefined,
							integration,
						)}
					</DropdownItem>
				</FeatureFlag>
				<DropdownItem
					onClick={mutations.toggle.mutate}
					analyticsKey="integration.options.item.toggle"
				>
					{integration?.disabled
						? TranslationService.get(
								[
									'integrations.list.action.enable',
									`integrations.list.action.${integration?.name}.enable`,
								],
								undefined,
								integration,
						  )
						: TranslationService.get(
								[
									'integrations.list.action.disable',
									`integrations.list.action.${integration?.name}.disable`,
								],
								undefined,
								integration,
						  )}
				</DropdownItem>
				{!integration?.disabled ? (
					<DropdownItem
						onClick={mutations.fetch.mutate}
						analyticsKey="integration.options.item.fetch"
					>
						{TranslationService.get(
							[
								'integrations.list.action.fetch',
								`integrations.list.action.${integration?.name}.fetch`,
							],
							undefined,
							integration,
						)}
					</DropdownItem>
				) : null}
			</Dropdown>
		</>
	);
});

IntegrationActions.propTypes = {
	id: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	onOpen: PropTypes.func,
};

export default IntegrationActions;
