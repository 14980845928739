import React from 'react';

import PropTypes from 'prop-types';

import Progress from '@asteria/component-core/progress';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './loading.scss';

const Loading = (props) => {
	const {
		type = 'app.loading',
		className,
		title,
		subtitle,
		blur,
		empty,
	} = props;

	const types = [].concat(type);

	return (
		<div
			className={cn('asteria-component__loading', className, {
				'asteria-component__loading--blur': blur,
			})}
		>
			{!empty ? (
				<>
					{title ? (
						title
					) : (
						<Text
							align="center"
							className="asteria-component__loading__title"
						>
							{TranslationService.get(
								types.map((type) => `${type}.title`),
							)}
						</Text>
					)}

					<Progress progress={-1} />
					{subtitle ? (
						<Text
							align="center"
							className="asteria-component__loading__subtitle"
						>
							{TranslationService.get(subtitle)}
						</Text>
					) : null}
				</>
			) : null}
		</div>
	);
};

Loading.propTypes = {
	type: PropTypes.oneOfType(
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	),
	title: PropTypes.node,
	subtitle: PropTypes.string,
	className: PropTypes.string,

	blur: PropTypes.bool,
	empty: PropTypes.bool,
};

export default Loading;
