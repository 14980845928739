import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import BaseStep from './base';

const WaitingStep = (props) => {
	const { onAction, onClose, nextStep } = props;

	const hasIntegration = useSelector(
		(store) =>
			IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				key: 'printer',
				status: 'IDLE',
				connected: true,
			}).length > 0,
	);

	const onAbort = React.useCallback((event) => onClose?.(event), [onClose]);
	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	React.useEffect(() => {
		if (hasIntegration) {
			let next = [`/onboarding/erp/printer/configuration`, `success`];

			if (nextStep) {
				next = [`/onboarding/erp/printer/configuration`, nextStep];
			}

			next = next.filter(Boolean).join('/');

			onAction?.('go', next);
		}
	}, [hasIntegration, nextStep, onAction]);

	return (
		<BaseStep
			{...props}
			onAction={onAction}
			onAbort={onAbort}
			onBack={onBack}
		/>
	);
};

WaitingStep.displayName = 'WaitingStep';

WaitingStep.propTypes = {
	className: PropTypes.string,
	nextStep: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

export default WaitingStep;
