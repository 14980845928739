import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import {
	useDynamicFormData,
	useProperties,
} from '@asteria/component-forecaster/hooks';
import { useFormValues } from '@asteria/component-form';
import Prefix from '@asteria/component-prefix';

import { Translation } from '@asteria/language';

/**
 * @typedef ForecastAction
 * @property { unknown } data
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */
/** @type { React.FC<ForecastAction> } */
const ForecastAction = React.memo(function ForecastAction({ data, onAction }) {
	const { getValues } = useFormContext();

	const formData = useDynamicFormData({
		category: data?.tag?.category?.name,
		tag: data?.tag?.name,

		useForm: useFormValues,
		useStaticForm: getValues,

		useCurrentBalance: true,
	});

	const typeFormData = useDynamicFormData({
		category: '$type',

		useForm: useFormValues,
		useStaticForm: getValues,

		useCurrentBalance: true,
	});

	const actionId = data?._id ?? data?.id;

	const redirectToForecast = React.useCallback(() => {
		onAction?.('card:action', {
			type: 'forecast-actions',
			data: {
				type: data?.extra?.type,
				id: data?.tag?._id,
				actionId: actionId,
			},
		});
	}, [actionId, data?.extra?.type, data?.tag?._id, onAction]);

	const handleRemove = React.useCallback(() => {
		onAction?.('card:promotion:dismiss', {
			type: 'forecast-action',
			id: data?._id,
		});
	}, [data?._id, onAction]);

	const handleApply = React.useCallback(() => {
		onAction?.('forecast:actions:delete', data?._id);
	}, [data?._id, onAction]);

	const { colors, label } = useProperties({
		type: data?.extra?.type,
		category: data?.tag?.category?.name,
		tag: data?.tag?.name,

		useForm: useFormValues,
		useStaticForm: getValues,
	});

	const translationOptions = React.useMemo(() => {
		const totalTag = formData?.[data?.extra?.type?.toUpperCase?.()]?.total;
		const totalType =
			typeFormData?.[data?.extra?.type?.toUpperCase?.()]?.total;

		return {
			postfix: {
				type: data?.extra?.type,
				category: data?.tag?.category?.name,
				tag: data?.tag?.name,
				zero: !totalTag,
			},
			data: {
				name: label,
				impact: (totalTag / totalType) * 100,
				total: totalTag,
			},
		};
	}, [
		data?.extra?.type,
		data?.tag?.category?.name,
		data?.tag?.name,
		formData,
		label,
		typeFormData,
	]);

	if (!data) {
		return null;
	}

	return (
		<div className="flex p-4 gap-1 justify-between flex-row relative border border-solid border-wrapper-normal-border rounded-md">
			<Group direction="vertical">
				<Group direction="horizontal" flex verticalAlign="baseline">
					<Prefix size="lg" colors={colors} />
					<Button
						className="asteria--type-redirect"
						variant="link"
						label={label}
						onClick={redirectToForecast}
					/>
				</Group>
				<Translation
					translationKey="card.content.forecast-actions.action.text"
					translationOptions={translationOptions}
					Component={Text}
					size="sm"
				/>
			</Group>
			<Group direction="horizontal" verticalAlign="center">
				<Button
					icon="check"
					iconSize="md"
					size="sm"
					onClick={handleApply}
				/>
				<Button
					icon="chevron-right"
					iconSize="md"
					size="sm"
					onClick={redirectToForecast}
				/>
			</Group>
			<Button
				className="absolute right-1 top-1"
				size="xs"
				iconSize="sm"
				icon="close"
				onClick={handleRemove}
			/>
		</div>
	);
});

ForecastAction.propTypes = {
	data: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default ForecastAction;
