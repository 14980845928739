import React from 'react';

import PropTypes from 'prop-types';

import Splash from '@asteria/component-splash';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const NeedInput = (props) => {
	const { className, onAction } = props;

	const handleAction = React.useCallback(
		(action) => {
			if (action === 'erp:create') {
				return onAction?.('go', {
					path: '/onboarding/connect',
					state: { type: 'erp' },
				});
			}
		},
		[onAction],
	);

	const actions = React.useMemo(
		() => ({ onAction: handleAction }),
		[handleAction],
	);

	return (
		<Splash
			className={cn(
				'asteria-component__statement-splash',
				'asteria--variant-erp',
				className,
			)}
			path="pages.statement.splash.erp"
			actions={actions}
		/>
	);
};

NeedInput.displayName = 'NeedInput';

NeedInput.propTypes = {
	className: PropTypes.string,
	erp: PropTypes.object,
};

export default NeedInput;
