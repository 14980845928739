import React from 'react';

import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import BaseStep from './base';

const StepPlugin = React.memo(function StepPlugin(props) {
	const { onAction, onClose } = props;

	const { id } = useParams();

	const onAbort = React.useCallback((event) => onClose?.(event), [onClose]);
	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(() => {
		const next = [
			'/onboarding/erp/visma.administration/configuration',
			id,
			'setup',
		]
			.filter(Boolean)
			.join('/');

		return onAction?.('go', next);
	}, [id, onAction]);

	return (
		<BaseStep
			{...props}
			step="installation-plugin"
			onAbort={onAbort}
			onBack={onBack}
			onNext={onNext}
		/>
	);
});

StepPlugin.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

export default StepPlugin;
