import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { getAbbreviation } from '../utils';

import '../index.scss';

const Messages = (props) => {
	const { messages, type } = props;

	const user = useSelector(AppStore.selectors.user, (a, b) => isEqual(a, b));

	const username =
		[user?.firstName, user?.lastName].filter(Boolean).join(' ') ||
		user?.username ||
		user?.id;

	return messages.map((object) => {
		const userLabel =
			object?.createdBy?.type === 'UserToken'
				? TranslationService.get(
						[
							'conversation.modal.message.user.label',
							`conversation.modal.${type}.message.user.label`,
							'conversation.modal.message.user.USER.label',
							`conversation.modal.${type}.message.user.USER.label`,
						],
						username,
						{ username: username },
				  )
				: TranslationService.get([
						'conversation.modal.message.user.label',
						`conversation.modal.${type}.message.user.label`,
						'conversation.modal.message.user.ADMIN.label',
						`conversation.modal.${type}.message.user.ADMIN.label`,
				  ]);

		return (
			<Group
				key={object.id}
				direction="vertical"
				className={cn('asteria-component__message-line-wrapper', {
					'asteria--type-user':
						object?.createdBy?.type === 'UserToken',
					'asteria--type-admin':
						object?.createdBy?.type === 'AdminToken',
				})}
			>
				<Text size="sm">{userLabel}</Text>
				<Group
					direction="horizontal"
					horizontalAlign="left"
					className={cn('asteria-component__message-line', {
						'asteria--type-user':
							object?.createdBy?.type === 'UserToken',
						'asteria--type-admin':
							object?.createdBy?.type === 'AdminToken',
					})}
				>
					<div className={cn('asteria-component__message-bubble')}>
						<Text
							className={cn('asteria-component__message-bubble')}
							weight="semibold"
						>
							{getAbbreviation(userLabel)}
						</Text>
					</div>

					<div className={cn('asteria-component__message-message')}>
						<Text
							dangerouslySetInnerHTML={{
								__html: object?.content,
							}}
						/>
						<Text
							size="xs"
							className="asteria-component__message-date"
						>
							{TranslationService.get(
								[
									'conversation.modal.message.created.label',
									`conversation.modal.${type}.message.created.label`,
								],
								undefined,
								{
									date: object?.createdAt,
								},
							)}
						</Text>
					</div>
				</Group>
			</Group>
		);
	});
};

Messages.displayName = 'Messages';

Messages.propTypes = {
	id: PropTypes.string,
};

export default Messages;
