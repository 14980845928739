import React from 'react';

import { useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

/**
 * @typedef Props
 * @property { string } className
 * @property { 'erp' | 'bank' } type
 * @property { string } name
 * @property { 'sm' | 'md' } size
 * @property { (options: { flow: unknown, integration?: unknown }) => void } onOpen
 * @property { string } status
 * @property { string } id
 * @property { (action: string, data: unknown) => unknown } onAction
 * @property { (action: string, data: unknown) => unknown } onSubmit
 */
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useFlow, useIntegrationName } from '../../../hooks';
import IntegrationActions from '../../actions';
import FixButton from '../../button-fix';

import Status from './status';

/** @type { React.FC<Props> } */
const IntegrationBox = React.memo(function IntegrationBox(props) {
	const {
		className,
		type,
		name: key,
		size = 'md',
		onOpen,
		status,
		id,
	} = props;

	const store = useStore();
	const flow = useFlow(type, key);

	const integrationName = useIntegrationName(type, key);

	const open = React.useCallback(() => {
		const integration = IntegrationStore.selectors.integration(
			store.getState(),
			id,
		);

		return onOpen?.({ flow, integration });
	}, [flow, id, onOpen, store]);

	const defaultImageStyles = React.useMemo(
		() => ({
			backgroundImage: `url('${flow?.info?.logo?.default?.path}')`,
			width: 120,
			backgroundPositionY: 'center',
		}),
		[flow?.info?.logo?.default?.path],
	);

	const smallImageStyles = React.useMemo(
		() => ({
			backgroundImage: `url('${flow?.info?.logo?.small?.path}')`,
			backgroundPositionY: 'center',
		}),
		[flow?.info?.logo?.small?.path],
	);

	const connected = !!id;

	return (
		<div
			className={cn(
				'flex flex-col gap-6 rounded-md border border-solid border-border-normal relative',
				{ 'cursor-pointer': !status },
				{
					'p-4 md:p-6': size === 'md',
					'p-4': size === 'sm',
				},
				'asteria-component__onboarding-box',
				{
					[`asteria--size-${size}`]: size,
					[`asteria--status-${status}`]: status,
				},

				className,
			)}
			onClick={!id ? open : null}
		>
			<div className="flex flex-wrap gap-2 items-center justify-between">
				<Status {...props} className="order-2" />
				<div className="flex flex-row items-center">
					<FixButton {...props} />
					<IntegrationActions {...props} />
				</div>
			</div>
			<div
				className={cn(
					'flex justify-between gap-2 items-center',
					'asteria-component__onboarding-box-header',
				)}
			>
				<div
					className={cn(
						'h-10 bg-contain bg-no-repeat',
						{
							'hidden md:block': size === 'md',
							hidden: size === 'sm',
						},
						'asteria-component__onboarding-box-logo',
					)}
					style={defaultImageStyles}
				/>
				<div
					className={cn('gap-2 items-center h-full w-full', {
						'flex md:hidden': size === 'md',
						flex: size === 'sm',
					})}
				>
					<div
						className="w-8 h-8 bg-contain bg-no-repeat"
						style={smallImageStyles}
					/>
					<Translation
						className="flex-1"
						translationKey="onboarding.box.title"
						data={{ integration: integrationName }}
						defaultText="{{{ integration | capitalize }}}"
						translationOptions={{
							postfix: { type, key, connected },
						}}
						Component={Title}
						size="xxs"
					/>
				</div>

				{!id ? (
					<Button icon="chevron-right" className="shrink-0" />
				) : null}
			</div>

			<TextGroup
				className={cn(
					'flex-col gap-2',
					{
						'hidden md:flex': size === 'md',
						hidden: size === 'sm',
					},
					'asteria-component__onboarding-box-content',
				)}
			>
				<Translation
					translationKey="onboarding.box.title"
					data={{ integration: integrationName }}
					defaultText="{{{ integration | capitalize }}}"
					translationOptions={{ postfix: { type, key, connected } }}
					Component={Title}
					size="xs"
				/>
				<Translation
					translationKey="onboarding.box.content"
					data={{ integration: integrationName }}
					Component={Text}
					translationOptions={{ postfix: { type, key, connected } }}
				/>
			</TextGroup>
		</div>
	);
});

IntegrationBox.displayName = 'IntegrationBox';

IntegrationBox.propTypes = {
	className: PropTypes.string,

	type: PropTypes.string,
	name: PropTypes.string,
	id: PropTypes.string,

	onOpen: PropTypes.func,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	size: PropTypes.string,
	status: PropTypes.string,
};

export default IntegrationBox;
