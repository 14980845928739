import React from 'react';

import { Provider } from 'react-redux';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import store from '@asteria/datalayer';

import Routing from './components/routing';
import * as hooks from './hooks';
import AnalyticsWrapper from './wrapper/analytics';
import AuthWrapper from './wrapper/auth';
import ConfigurationWrapper from './wrapper/configuration';
import DatalayerWrapper from './wrapper/datalayer';
import FeaturesWrapper from './wrapper/features';
import PartnerWrapper from './wrapper/partner';
import ThemeWrapper from './wrapper/theme';
import TranslationsWrapper from './wrapper/translations';

/**
 * @typedef { import('./types').Props } Props
 */

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,

			staleTime: 1000 * 60, // 1 minute
			cacheTime: 1000 * 60 * 10, // 10 minutes
			gcTime: 1000 * 60 * 10, // 10 minutes
		},
	},
});

/** @type { React.FC<Props> } */
const AsteriaWidgetContent = React.memo(function AsteriaWidgetContent(props) {
	const {
		accessToken,
		partnerId,
		languageCode,
		analytics,
		theme,

		routing,
		datalayer,
		configuration = true,
		translations = true,
	} = props;

	hooks.useDevicePixelRatio();
	hooks.useInvalidateOnUnmount();

	return (
		<ConfigurationWrapper partnerId={partnerId} enabled={configuration}>
			<PartnerWrapper partnerId={partnerId}>
				<AnalyticsWrapper analytics={analytics}>
					<AuthWrapper accessToken={accessToken}>
						<FeaturesWrapper partnerId={partnerId}>
							<ThemeWrapper theme={theme} partnerId={partnerId}>
								<TranslationsWrapper
									language={languageCode}
									partnerId={partnerId}
									enabled={translations}
								>
									<DatalayerWrapper
										partnerId={partnerId}
										{...datalayer}
									>
										<Routing {...routing} />
									</DatalayerWrapper>
								</TranslationsWrapper>
							</ThemeWrapper>
						</FeaturesWrapper>
					</AuthWrapper>
				</AnalyticsWrapper>
			</PartnerWrapper>
		</ConfigurationWrapper>
	);
});

AsteriaWidgetContent.propTypes = {
	accessToken: PropTypes.string,
	partnerId: PropTypes.string,
	languageCode: PropTypes.string,
	analytics: PropTypes.bool,
	theme: PropTypes.string,

	routing: PropTypes.object,
	datalayer: PropTypes.object,

	configuration: PropTypes.bool,
	translations: PropTypes.bool,
};

const AsteriaWidget = React.memo(function AsteriaWidget(props) {
	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<AsteriaWidgetContent {...props} />
			</Provider>
		</QueryClientProvider>
	);
});

export default AsteriaWidget;
export { hooks, queryClient };

export { default as AuthValidate } from './components/auth-validate';
export { default as ErrorBoundary } from './components/error';
export { default as Redirect } from './components/redirect';
export { TranslationContext } from './context';
