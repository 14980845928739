import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as SnackbarStore from '@asteria/datalayer/stores/snackbar';

import BaseStep from './base';

const StepToken = React.memo(function StepToken(props) {
	const { onAction, onClose } = props;

	const dispatch = useDispatch();

	const { id } = useParams();

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, id),
		(a, b) => isEqual(a, b),
	);

	const onAbort = React.useCallback((event) => onClose?.(event), [onClose]);
	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(
		() =>
			onAction?.(
				'go',
				`/onboarding/erp/visma.administration/configuration/${id}/tray`,
			),
		[id, onAction],
	);

	const onCopy = React.useCallback(
		(value) => {
			navigator.clipboard.writeText(value);

			SnackbarStore.show(dispatch, {
				title: 'snackbar.integration.token.copy.title',
				content: 'snackbar.integration.token.copy.content',
				type: 'integration-token-copy',
				variant: 'success',
				icon: 'clipboard',
				hideActions: true,
			});
		},
		[dispatch],
	);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'copy') {
				return onCopy(data);
			}

			return onAction?.(action, data);
		},
		[onAction, onCopy],
	);

	const data = React.useMemo(
		() => ({ integration: integration }),
		[integration],
	);

	return (
		<BaseStep
			{...props}
			step="token"
			data={data}
			onAction={handleAction}
			onAbort={onAbort}
			onBack={onBack}
			onNext={onNext}
		/>
	);
});

StepToken.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

export default StepToken;
