import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { useFeature } from '@asteria/component-tools/featureflag';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/** @type { React.FC<{ type: 'printer' | 'upload' | 'report', className: string, size?: 'sm' | 'md', onAction: (action: string, data: unknown) => void, onSubmit: (action: string, data: unknown) => void }> } */
const MissingIntegrationBlock = React.memo(function Block(props) {
	const { type, className, size, onAction } = props;

	const click = React.useCallback(
		() => onAction?.('onboarding:missing:action', { type }),
		[onAction, type],
	);

	const hasActionFeature = useFeature(`onboarding-missing-action-${type}`);

	return (
		<div
			className={cn(
				'flex flex-col items-center justify-between gap-4 p-4 border border-solid border-border-normal rounded',
				className,
			)}
		>
			<div className="flex flex-col gap-4 items-center">
				{type === 'printer' ? <Icon icon="printer" size="xl" /> : null}
				{type === 'upload' ? <Icon icon="upload" size="xl" /> : null}
				{type === 'report' ? (
					<Icon icon="integrations" size="xl" />
				) : null}
				<TextGroup className="flex flex-col gap-2">
					<Translation
						translationKey="onboarding.section.missing.block.title"
						translationOptions={{
							postfix: { type, action: hasActionFeature },
						}}
						Component={Title}
						align="center"
						size="xxs"
					/>
					<Translation
						translationKey="onboarding.section.missing.block.content"
						translationOptions={{
							postfix: { type, action: hasActionFeature },
						}}
						Component={Text}
						align="center"
						size="sm"
					/>
				</TextGroup>
			</div>
			{hasActionFeature ? (
				<Button
					className="w-full justify-center"
					variant="secondary"
					label={TranslationService.getV2(
						['onboarding.section.missing.block.action'],
						{ postfix: { type } },
					)}
					onClick={click}
					size={size}
				/>
			) : null}
		</div>
	);
});

MissingIntegrationBlock.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	size: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<{ size: 'sm' | 'md', onAction: (action: string, data: unknown) => void, onSubmit: (action: string, data: unknown) => void }> } */
const MissingIntegrationSection = React.memo(function Section(props) {
	const { size = 'md', onAction, onSubmit } = props;

	return (
		<div
			className={cn(
				'flex flex-col gap-6',
				'asteria-component__onboarding-missing-section',
			)}
		>
			<TextGroup className="flex flex-col gap-1">
				<Translation
					translationKey="onboarding.section.missing.title"
					Component={Title}
					align="center"
					size={size === 'sm' ? 'xxs' : 'sm'}
				/>

				<Translation
					translationKey="onboarding.section.missing.content"
					Component={Text}
					align="center"
					size={size === 'sm' ? 'sm' : 'md'}
				/>
			</TextGroup>
			<div
				className={cn('grid gap-8', {
					'md:grid-cols-3 ': size === 'md',
				})}
			>
				<MissingIntegrationBlock
					size={size}
					type="printer"
					onAction={onAction}
					onSubmit={onSubmit}
				/>
				<MissingIntegrationBlock
					size={size}
					type="upload"
					onAction={onAction}
					onSubmit={onSubmit}
				/>
				<MissingIntegrationBlock
					size={size}
					type="report"
					onAction={onAction}
					onSubmit={onSubmit}
				/>
			</div>
		</div>
	);
});

MissingIntegrationSection.propTypes = {
	size: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default MissingIntegrationSection;
