import React from 'react';

import TextStep from './base-text';
import WaitingStep from './base-waiting';

const StepMacOSX = React.memo(function StepMacOSX(props) {
	return (
		<TextStep
			{...props}
			os="macosx"
			step="os/macosx/add"
			nextStep="os/macosx/continue"
		/>
	);
});

const StepMacOSXContinue = React.memo(function StepMacOSXContinue(props) {
	return (
		<TextStep
			{...props}
			os="macosx"
			step="os/macosx/continue"
			nextStep="os/macosx/print"
		/>
	);
});

const StepMacOSXPrint = React.memo(function StepMacOSXPrint(props) {
	return (
		<TextStep
			{...props}
			os="macosx"
			step="os/macosx/print"
			nextStep="os/macosx/waiting"
		/>
	);
});

const StepMacOSXWaiting = React.memo(function StepMacOSXWaiting(props) {
	return <WaitingStep {...props} os="macosx" step="os/macosx/waiting" />;
});

export { StepMacOSX, StepMacOSXContinue, StepMacOSXPrint, StepMacOSXWaiting };
