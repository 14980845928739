import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import { Circle } from '@asteria/component-core/progress';

import { TranslationService } from '@asteria/language';

import Wrapper from './wrapper';

/** @type { React.FC<{ status: string, id: string, onSubmit: () => void }> } */
const Status = React.memo(function Status(props) {
	const { status, id, onSubmit } = props;

	const cancel = useMutation({
		mutationFn: async () => onSubmit?.('integrations:delete', { id: id }),
	});

	if (!status) {
		return null;
	}

	if (status === 'connected') {
		return (
			<Wrapper {...props}>
				<div className="bg-alert-success-icon-normal-background p-2 rounded-full inline-flex">
					<Icon icon="check" />
				</div>
			</Wrapper>
		);
	}

	if (status === 'error') {
		return (
			<Wrapper {...props}>
				<div className="bg-alert-error-icon-normal-background p-2 rounded-full inline-flex">
					<Icon icon="warning" />
				</div>
			</Wrapper>
		);
	}

	if (status === 'outdated') {
		return (
			<Wrapper {...props}>
				<div className="bg-alert-warning-icon-normal-background p-2 rounded-full inline-flex">
					<Icon icon="warning" />
				</div>
			</Wrapper>
		);
	}

	if (status === 'disabled') {
		return (
			<Wrapper {...props}>
				<div className="bg-alert-warning-icon-normal-background p-2 rounded-full inline-flex">
					<Icon icon="warning" />
				</div>
			</Wrapper>
		);
	}

	if (status === 'importing' || status === 'initiating') {
		return (
			<Wrapper {...props}>
				<div className="flex items-center gap-2">
					<Circle progress={-1} />
					<Button
						variant="link"
						label={TranslationService.get([
							'button.cancel',
							'action.cancel',
						])}
						onClick={cancel.mutate}
						size="sm"
					/>
				</div>
			</Wrapper>
		);
	}

	return null;
});

Status.propTypes = {
	id: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	status: PropTypes.string,
};

export default Status;
