import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import { Circle } from '@asteria/component-core/progress';
import { Text, Title } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { useToggleState } from '@asteria/utils-funcs/state';
import useConfig from '@asteria/utils-hooks/useConfig';

import IntegrationGuide from '../../components/Guide';

import Slider from './slider';

/** @type { React.FC<{ action: unknown, integration: unknown, onAction: (action: string, data: unknown) => unknown, onSubmit: (action: string, data: unknown) => unknown }> } */
const Action = React.memo(function Action(props) {
	const { action, integration, onSubmit } = props;

	const [guideState, guideActions] = useToggleState(false);

	const event = action?.action;
	const data = action?.data;
	const status = action?.status;

	const config = useConfig([
		`integration.actions.${event}`,
		`integration.actions.${integration?.key}.${event}`,
	]);

	const mutations = {
		dismiss: useMutation({
			mutationFn: async () => {
				return onSubmit?.('onboarding:action:dismiss', {
					action,
					integration,
				});
			},
		}),
		solve: useMutation({
			mutationFn: async () => {
				return onSubmit?.('onboarding:action', { action, integration });
			},
		}),
	};

	return (
		<>
			<IntegrationGuide
				open={guideState}
				integration={integration}
				steps={config?.guide?.steps}
				onClose={guideActions.close}
			/>
			<div
				className={cn(
					'flex flex-col gap-2 relative',
					'asteria-component__onboarding-action',
				)}
			>
				{config?.guide?.steps?.length && status !== 'PROCESSED' ? (
					<Button
						className="absolute top-0 right-0"
						onClick={guideActions.open}
						icon="help"
					/>
				) : null}

				<div
					className={cn(
						'flex flex-col gap-2 items-center justify-center',
						'asteria-component__onboarding-action-content',
					)}
				>
					<Translation
						translationKey={[
							`integrations.actions.${event}`,
							`integrations.${integration.type}.actions.${event}`,
							`integrations.${integration.type}.${integration.key}.actions.${event}`,
						]}
						data={{ integration: integration }}
						Component={Title}
						align="center"
						size="xs"
					/>

					{status === 'PENDING' ? (
						<Translation
							translationKey={[
								`integrations.actions.message`,
								`integrations.actions.${event}.message`,
								`integrations.${integration.type}.actions.${event}.message`,
								`integrations.${integration.type}.${integration.key}.actions.${event}.message`,
								data?.message,
							]}
							defaultText={data?.message}
							data={{
								integration: integration,
								message: data?.message,
							}}
							Component={Text}
							align="center"
							size="sm"
						/>
					) : null}

					{status === 'PROGRESS' ? (
						<div className="flex flex-col gap-2 items-center justify-center">
							<Circle progress={-1} />
							<Translation
								translationKey={[
									'integrations.actions.status',
									`integrations.actions.${event}.status`,
								]}
								data={{
									integration: integration,
									message: data?.message,
								}}
								translationOptions={{
									postfix: {
										status,
										type: integration?.type,
										key: integration?.key,
										event,
									},
								}}
								Component={Text}
								size="sm"
							/>
							<Button
								size="sm"
								variant="link"
								label={TranslationService.getV2(
									[
										'button.cancel',
										'action.cancel',
										'integrations.actions.button',
									],
									{
										postfix: {
											action: 'progress-cancel',
											type: integration?.type,
											key: integration?.key,
											event,
										},
									},
								)}
								onClick={mutations.dismiss.mutate}
								disabled={
									mutations.dismiss.isLoading ||
									mutations.solve.isLoading
								}
							/>
						</div>
					) : null}

					{status === 'CANCELED' ? (
						<div className="flex flex-col gap-2 items-center justify-center">
							<div
								className={cn(
									'asteria-component__alert-icon',
									'asteria--status-error',
								)}
							>
								<Icon icon="warning" />
							</div>

							<Translation
								translationKey={[
									'integrations.actions.status',
									`integrations.actions.${event}.status`,
								]}
								data={{
									integration: integration,
									message: data?.message,
								}}
								translationOptions={{
									postfix: {
										status,
										type: integration?.type,
										key: integration?.key,
										event,
									},
								}}
								Component={Text}
								size="sm"
							/>
						</div>
					) : null}

					{status === 'APPROVED' ? (
						<div className="flex flex-col gap-2 items-center justify-center">
							<div
								className={cn(
									'asteria-component__alert-icon',
									'asteria--status-success',
								)}
							>
								<Icon icon="clock" />
							</div>

							<Translation
								translationKey={[
									'integrations.actions.status',
									`integrations.actions.${event}.status`,
								]}
								data={{
									integration: integration,
									message: data?.message,
								}}
								translationOptions={{
									postfix: {
										status,
										type: integration?.type,
										key: integration?.key,
										event,
									},
								}}
								Component={Text}
								size="sm"
							/>
						</div>
					) : null}

					{status === 'PROCESSED' ? (
						<div className="flex flex-col gap-2 items-center justify-center">
							<div
								className={cn(
									'asteria-component__alert-icon',
									'asteria--status-success',
								)}
							>
								<Icon icon="check" />
							</div>

							<Translation
								translationKey={[
									'integrations.actions.status',
									`integrations.actions.${event}.status`,
								]}
								data={{
									integration: integration,
									message: data?.message,
								}}
								translationOptions={{
									postfix: {
										status,
										type: integration?.type,
										key: integration?.key,
										event,
									},
								}}
								Component={Text}
								size="sm"
							/>
						</div>
					) : null}
				</div>

				{['PENDING', 'CANCELED', 'APPROVED'].includes(status) ? (
					<div
						className={cn('flex gap-2', {
							'justify-between': status === 'PENDING',
							'justify-start': status === 'APPROVED',
							'justify-end': status === 'CANCELED',
						})}
					>
						{status !== 'CANCELED' ? (
							<Button
								size="sm"
								variant="link"
								label={TranslationService.getV2(
									['integrations.actions.button'],
									{
										postfix: {
											action: 'dismiss',
											type: integration?.type,
											key: integration?.key,
											event,
										},
									},
								)}
								onClick={mutations.dismiss.mutate}
								loading={
									mutations.dismiss.isLoading ||
									mutations.solve.isLoading
								}
								disabled={
									mutations.dismiss.isLoading ||
									mutations.solve.isLoading
								}
							/>
						) : null}
						{status !== 'APPROVED' ? (
							<Button
								size="sm"
								variant="secondary"
								label={TranslationService.getV2(
									['integrations.actions.button'],
									{
										postfix: {
											action: 'solve',
											type: integration?.type,
											key: integration?.key,
											event,
										},
									},
								)}
								onClick={mutations.solve.mutate}
								loading={
									mutations.dismiss.isLoading ||
									mutations.solve.isLoading
								}
								disabled={
									mutations.dismiss.isLoading ||
									mutations.solve.isLoading
								}
							/>
						) : null}
					</div>
				) : null}
			</div>
		</>
	);
});

Action.propTypes = {
	action: PropTypes.object,
	integration: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<{ actions: unknown[], integration: unknown, onAction: (action: string, data: unknown) => unknown, onSubmit: (action: string, data: unknown) => unknown }> } */
const Actions = React.memo(function Actions(props) {
	const { actions, integration, onAction, onSubmit } = props;

	if (!actions?.length) {
		return null;
	}

	return (
		<div
			className={cn(
				'flex flex-col gap-4',
				'asteria-component__onboarding-actions',
			)}
		>
			<Translation
				translationKey="integrations.actions.placeholder"
				translationOptions={{
					postfix: {
						type: integration?.type,
						key: integration?.key,
					},
				}}
				Component={Text}
				align="center"
			/>
			<Slider>
				{actions.map((action) => (
					<Action
						key={action?._id ?? action?.action}
						action={action}
						integration={integration}
						onAction={onAction}
						onSubmit={onSubmit}
					/>
				))}
			</Slider>
		</div>
	);
});

Actions.propTypes = {
	actions: PropTypes.arrayOf(PropTypes.object),
	integration: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Actions;
