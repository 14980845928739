import AsteriaCore from '@asteria/core';

import { GraphQL } from '@asteria/backend-utils-services';

const Dataloaders = new Map();

const defaultCallback = async (...options) => {
	const requests = GraphQL.merge(options);

	let responses = [];

	for (const { data = [], ...request } of requests) {
		const response = await AsteriaCore.HTTP.graphql({
			...request,
			isBulk: false,
		});

		for (const { alias } of data) {
			const chunk = GraphQL.parse(response, alias);

			responses.push(...chunk);
		}
	}

	return responses;
};

async function fetch(options) {
	const { uri } = options;

	let dataloader = Dataloaders.get(uri);

	if (!dataloader) {
		dataloader = new AsteriaCore.DataLoader(defaultCallback, { period: 0 });
		Dataloaders.set(uri, dataloader);
	}

	return dataloader.execute(options, { waiting: true }).then((data) => {
		Dataloaders.clear();
		return { response: data };
	});
}

export default fetch;
export { fetch, defaultCallback };
