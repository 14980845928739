import React from 'react';

import { useSelector } from 'react-redux';

import {
	compareAsc,
	differenceInDays,
	isBefore,
	parseISO,
	subMonths,
} from 'date-fns';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';

import { useIntegrationName } from '../../hooks';

/**
 * @typedef { import('./types').Integration } Integration
 * @typedef { import('./types').onAction } onAction
 */

function isValidDate(object) {
	return isBefore(parseISO(object.lastSync), subMonths(new Date(), 1));
}

/**
 * @returns { Integration[] }
 */
function useIntegrationOutdated() {
	const available = useSelector(
		(store) => {
			const erp = IntegrationStore.selectors
				.integrations(store, {
					type: 'erp',
					filters: [{ status: 'IDLE', disabled: false }],
				})
				.filter(isValidDate);

			const bank = IntegrationStore.selectors
				.integrations(store, {
					type: 'bank',
					filters: [{ status: 'IDLE', disabled: false }],
				})
				.filter(isValidDate);

			return [].concat(erp).concat(bank);
		},
		(a, b) => isEqual(a, b),
	);

	return available;
}

/** @type { React.FC<{ integration: Integration, translationOptions: unknown, onClick: import('react').MouseEventHandler }> } */
const SingleTitle = React.memo(function SingleTitle(props) {
	const { integration, translationOptions, onClick } = props;

	const key = integration?.key;
	const type = integration?.type;

	const integrationName = useIntegrationName(type, key);

	return (
		<Text className="asteria-component__onboarding-outdated-line">
			{TranslationService.getV2(
				[
					'card.content.title',
					'card.content.title.onboarding-outdated',
					'card.content.title.onboarding-outdated.single',
				],
				{
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						'integration-key': key,
						'integration-type': type,
					},
					data: {
						...translationOptions?.data,
						integration: integration,
						integrationName: integrationName,
					},
				},
			)}
			<Button
				onClick={onClick}
				size="md"
				label={TranslationService.getV2(
					['card.content.onboarding-outdated.action'],
					{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'integration-key': key,
							'integration-type': type,
						},
						data: {
							...translationOptions?.data,
							integration: integration,
							integrationName: integrationName,
						},
					},
				)}
				variant="link"
			/>
			{TranslationService.getV2(
				[
					'card.content.title.last.onboarding-outdated',
					'card.content.title.last.onboarding-outdated.single',
				],
				{
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						'integration-key': key,
						'integration-type': type,
					},
					data: {
						...translationOptions?.data,
						integration: integration,
						integrationName: integrationName,
					},
				},
			)}
		</Text>
	);
});

SingleTitle.propTypes = {
	integration: PropTypes.object,
	translationOptions: PropTypes.object,
	onClick: PropTypes.func,
};

/** @type { React.FC<{ integrations: Integration[], type: 'bank' | 'erp', translationOptions: unknown, onClick: import('react').MouseEventHandler }> } */
const MultiTitle = React.memo(function MultiTitle(props) {
	const { integrations, type, translationOptions, onClick } = props;

	const first = integrations?.[0];
	const last = integrations?.[integrations?.length - 1];

	return (
		<Text className="asteria-component__onboarding-outdated-line">
			{TranslationService.getV2(
				[
					'card.content.title',
					'card.content.title.onboarding-outdated',
					'card.content.title.onboarding-outdated.multiple',
				],
				{
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						'integration-type': type,
					},
					data: {
						...translationOptions?.data,
						integrations: { first: first, last: last },
					},
				},
			)}
			<Button
				onClick={onClick}
				size="md"
				label={TranslationService.getV2(
					['card.content.onboarding-outdated.action'],
					{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'integration-type': type,
						},
						data: {
							...translationOptions?.data,
							integrations: { first: first, last: last },
						},
					},
				)}
				variant="link"
			/>
			{TranslationService.getV2(
				[
					'card.content.title.last.onboarding-outdated',
					'card.content.title.last.onboarding-outdated.multiple',
				],
				{
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						'integration-type': type,
					},
					data: {
						...translationOptions?.data,
						integrations: { first: first, last: last },
					},
				},
			)}
		</Text>
	);
});

MultiTitle.propTypes = {
	type: PropTypes.string,
	integrations: PropTypes.arrayOf(PropTypes.object),
	onClick: PropTypes.func,
	translationOptions: PropTypes.object,
};

/** @type { React.FC<{ integrations: Integration[], type: 'erp' | 'bank', translationOptions: unknown, onAction: onAction, onSubmit: onAction }> } */
const IntegrationTitle = React.memo(function IntegrationTitle(props) {
	const {
		integrations: $integrations,
		type,
		translationOptions,
		onAction,
	} = props;

	const integrations = React.useMemo(
		() =>
			$integrations
				.sort((a, b) =>
					compareAsc(parseISO(a?.lastSync), parseISO(b?.lastSync)),
				)
				.map((object) => ({
					...object,
					days: differenceInDays(
						new Date(),
						parseISO(object.lastSync),
					),
				})),
		[$integrations],
	);

	const handleClick = React.useCallback(
		() =>
			onAction?.('card:action', {
				type: 'onboarding-outdated',
				integrations: integrations,
			}),
		[integrations, onAction],
	);

	if (!integrations?.length) {
		return null;
	}

	if (integrations?.length === 1) {
		return (
			<SingleTitle
				integration={integrations?.[0]}
				translationOptions={translationOptions}
				onClick={handleClick}
			/>
		);
	}

	return (
		<MultiTitle
			type={type}
			integrations={integrations}
			translationOptions={translationOptions}
			onClick={handleClick}
		/>
	);
});

IntegrationTitle.propTypes = {
	type: PropTypes.oneOf(['erp', 'bank']),
	integrations: PropTypes.arrayOf(PropTypes.object),
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<{ visible: boolean, integrations: Integration[], onAction: onAction, onSubmit: onAction }> } */
const Title = React.memo(function OnboardingLoadingTitle(props) {
	const { visible = true, integrations } = props;

	const erpOutdated = integrations.filter(({ type }) => type === 'erp');
	const bankOutdated = integrations.filter(({ type }) => type === 'bank');

	if (!visible) {
		return null;
	}

	return [
		<IntegrationTitle
			key="onboarding-erp-outdated"
			{...props}
			type="erp"
			integrations={erpOutdated}
		/>,
		<IntegrationTitle
			key="onboarding-bank-outdated"
			{...props}
			type="bank"
			integrations={bankOutdated}
		/>,
	];
});

Title.propTypes = {
	translationOptions: PropTypes.object,
	visible: PropTypes.bool,
	integrations: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
};

export default Title;
export { useIntegrationOutdated };
