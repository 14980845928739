import React from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Form from '@asteria/component-form';
import Contenter from '@asteria/component-tools/contenter';

import { Translation, TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import { useToggleState } from '@asteria/utils-funcs/state';

import Guide from '../../components/Guide';
import ButtonHelp from '../../components/button-help';
import { OnboardingGuideSwitch } from '../../components/integration-guide';
import OnboardingWrapper from '../../components/onboarding-wrapper';
import { GuideContext } from '../../context';
import { useFlow, useIntegrationName, usePlaceholder } from '../../hooks';

import ConfigurationGateway from './gateway';

/**
 * @typedef Props
 * @property { string } className
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { import('react').MouseEventHandler } onClose
 * @property { string } type
 * @property { string } name
 * @property { unknown } form
 */

function getContent(flow) {
	return flow?.steps?.['configuration']?.content;
}

/** @type { React.FC<Props> } */
const Configuration = React.memo(function Configuration(props) {
	const { onAction, onSubmit, onClose, type, name: key, form } = props;

	const guided = React.useContext(GuideContext);

	const flow = useFlow(type, key);
	const content = useFlow(type, key, getContent);

	const integrationName = useIntegrationName(type, key);

	const [guideState, guideActions] = useToggleState();

	React.useLayoutEffect(() => {
		Analytics.startFlow('integrations.configuration', {
			integrationType: type,
			integrationKey: key,
		});

		return () => {
			Analytics.endFlow('integrations.configuration');
		};
	}, [key, type]);

	const close = React.useCallback(
		(event) => {
			Analytics.endFlow('integrations.configuration');

			return onClose?.(event);
		},
		[onClose],
	);

	const back = React.useCallback(() => {
		Analytics.endFlow('integrations.configuration');

		return onAction?.('go', -1);
	}, [onAction]);

	const next = useMutation({
		mutationFn: async (form) => onSubmit?.('onboarding:save', form),
		onSuccess: (data) => {
			Analytics.endFlow('integrations.configuration');

			const id = data?._id ?? data?.id;

			return onAction?.('go', `/onboarding/${id}/status`);
		},
	});

	const placeholder = usePlaceholder(type, key, 'configuration');

	const values = React.useMemo(
		() => ({
			$id: form?.$id,
			type: type,
			key: key,
			config: { client: form?.config?.client },
		}),
		[form?.$id, form?.config?.client, key, type],
	);

	return (
		<>
			{flow?.steps?.info?.guide ? (
				<Guide
					open={guideState}
					steps={flow?.steps?.info?.guide?.steps}
					onClose={guideActions.close}
					integration={{ type, key }}
				/>
			) : null}

			<Form values={values} onSubmit={next.mutate}>
				<Wrapper
					className={cn(
						'asteria-component__onboarding-step',
						'asteria--variant-configuration',
					)}
					variant="custom"
				>
					<Header onBack={back} onClose={close}>
						<div
							className={cn(
								'flex w-full justify-between flex-col tablet:flex-row items-start tablet:items-center gap-4',
							)}
						>
							<Translation
								translationKey={[
									`integrations.add.title`,
									`integrations.${type}.add.title`,
									`integrations.${key}.add.title`,
									`integrations.${type}.${key}.add.title`,
									`integrations.configuration.title`,
									`integrations.${type}.configuration.title`,
									`integrations.${key}.configuration.title`,
									`integrations.${type}.${key}.configuration.title`,
								]}
								data={{ name: integrationName }}
								Component={Title}
							/>

							{placeholder ? (
								<OnboardingGuideSwitch
									onClick={guided.toggle}
									active={guided.state}
								/>
							) : null}
						</div>
					</Header>
					<OnboardingWrapper placeholder={placeholder}>
						<Content>
							<div className="flex flex-col gap-4">
								{content ? (
									<Contenter content={content} />
								) : (
									<Contenter
										content={flow?.steps?.info?.content}
									/>
								)}

								<ConfigurationGateway
									{...props}
									type={type}
									name={key}
								/>

								{flow?.steps?.info?.guide ? (
									<Button
										className="asteria-component__onboarding-step__help"
										variant="assist"
										label={TranslationService.get(
											[
												`integrations.add.help`,
												`integrations.${type}.add.help`,
												`integrations.${type}.${key}.add.help`,
											],
											undefined,
											{ name: integrationName },
										)}
										// icon="triangle-right"
										// iconPosition="last"
										onClick={guideActions.open}
									/>
								) : null}
							</div>
						</Content>
						<Footer>
							<FooterSection position="first">
								<Button
									variant="tertiary"
									label={TranslationService.get(
										[
											'action.abort',
											'button.abort',
											'onboarding.modal.action',
											'onboarding.modal.action.abort',
											'onboarding.modal.flow-configuration.action',
											'onboarding.modal.flow-configuration.action.abort',
											`onboarding.modal.${type}.action`,
											`onboarding.modal.${type}.action.abort`,
											`onboarding.modal.${type}.flow-configuration.action`,
											`onboarding.modal.${type}.flow-configuration.action.abort`,
											`onboarding.modal.${key}.action`,
											`onboarding.modal.${key}.action.abort`,
											`onboarding.modal.${key}.flow-configuration.action`,
											`onboarding.modal.${key}.flow-configuration.action.abort`,
											`onboarding.modal.${type}.${key}.action`,
											`onboarding.modal.${type}.${key}.action.abort`,
											`onboarding.modal.${type}.${key}.flow-configuration.action`,
											`onboarding.modal.${type}.${key}.flow-configuration.action.abort`,
										],
										undefined,
										{ name: integrationName },
									)}
									onClick={close}
								/>
							</FooterSection>
							<FooterSection position="last">
								<ButtonHelp
									onAction={onAction}
									onSubmit={onSubmit}
									extra={{
										from: 'onboarding',
										step: 'configuration',
										type,
										key,
									}}
								/>
								<Button
									variant="primary"
									label={TranslationService.get(
										[
											'action.continue',
											'button.continue',
											'integrations.action.next',
											`integrations.action.next.${key}`,
											'onboarding.modal.action',
											'onboarding.modal.action.continue',
											'onboarding.modal.flow-configuration.action',
											'onboarding.modal.flow-configuration.action.continue',
											`onboarding.modal.${type}.action`,
											`onboarding.modal.${type}.action.continue`,
											`onboarding.modal.${type}.flow-configuration.action`,
											`onboarding.modal.${type}.flow-configuration.action.continue`,
											`onboarding.modal.${key}.action`,
											`onboarding.modal.${key}.action.continue`,
											`onboarding.modal.${key}.flow-configuration.action`,
											`onboarding.modal.${key}.flow-configuration.action.continue`,
											`onboarding.modal.${type}.${key}.action`,
											`onboarding.modal.${type}.${key}.action.continue`,
											`onboarding.modal.${type}.${key}.flow-configuration.action`,
											`onboarding.modal.${type}.${key}.flow-configuration.action.continue`,
										],
										undefined,
										{ name: integrationName },
									)}
									type="submit"
									disabled={next.isLoading}
									loading={next.isLoading}
								/>
							</FooterSection>
						</Footer>
						{guided.state && !!placeholder ? (
							<div className="asteria-component__onboarding-placeholder-wrapper">
								{placeholder}
							</div>
						) : null}
					</OnboardingWrapper>
				</Wrapper>
			</Form>
		</>
	);
});

Configuration.propTypes = {
	className: PropTypes.string,

	type: PropTypes.string,
	name: PropTypes.string,
	form: PropTypes.object,

	onAction: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<Props> } */
const PageConfiguration = React.memo(function PageConfiguration(props) {
	const { type, key } = useParams();
	const location = useLocation();

	const form = location?.state?.form;

	return <Configuration {...props} type={type} name={key} form={form} />;
});

PageConfiguration.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PageConfiguration;
export { Configuration };
