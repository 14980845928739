import AsteriaCore from '@asteria/core';

import { TagService } from '@asteria/backend-utils-services';

import { setTags, updateTags } from '@asteria/datalayer/stores/app';

export const DEFAULT_FIELDS = `
    _id
    id
    name
    color
    category {
        _id
        id
        name
    }
`;

TagService.tag.extend({
	key: 'fetchStatistics',
	getQuery: () => `
		query Request($id: ObjectId!, $search: JSON) {
			response: tag(id: $id) {
				_id
				statistics {
					transactions(search: $search) {
						count
						total
					}
				}
			}
		}
	`,
	loggerMethod: 'tag-service.tags.fetchStatistics',
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `tag-service.tags.fetchStatistics`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

export async function statistics({ accessToken, dispatch, _id, scenarioId }) {
	const response = await TagService.tag.extension.fetchStatistics(
		{ id: _id, search: { scenarioId: { $ne: scenarioId } } },
		{ token: accessToken },
	);

	dispatch?.(updateTags(response));

	return response;
}

/**
 * @param { { accessToken: string, dispatch?: unknown } } options
 */
export async function fetch(options) {
	const { accessToken, dispatch, dataloader } = options;

	const response = await TagService.tag
		.fetch({ fields: DEFAULT_FIELDS, dataloader }, { token: accessToken })

		.then((tags) => tags ?? [])
		.catch(() => []);

	dispatch?.(setTags(response));

	return response;
}
