import React from 'react';

import PropTypes from 'prop-types';

import { Text, Title } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useIntegrationName } from '../../hooks';

const IntegrationSuccessBox = React.memo(function IntegrationSuccessBox(props) {
	const { integration, children } = props;

	const type = integration?.type;
	const key = integration?.key ?? integration?.name;

	const integrationName = useIntegrationName(type, key);

	return (
		<div
			className={cn(
				'flex flex-col bg-alert-success-normal-background gap-2 p-4 rounded-lg',
				'asteria-component__onboarding-status-alert',
				'asteria--status-success',
			)}
		>
			<Text size="md" align="center">
				{TranslationService.get(
					[
						`integrations.success.subtitle`,
						`integrations.${type}.success.subtitle`,
						`integrations.${type}.${integration?.key}.success.subtitle`,
					],
					undefined,
					{ integration: integration, name: integrationName },
				)}
			</Text>
			<Title
				size="md"
				className="asteria-component__alert--highlight"
				align="center"
			>
				{TranslationService.get(
					[
						`integrations.success.integration`,
						`integrations.${type}.success.integration`,
						`integrations.${type}.${integration?.key}.success.integration`,
					],
					'{{ name | capitalize }}',
					{ integration: integration, name: integrationName },
				)}
			</Title>
			{children}
		</div>
	);
});

IntegrationSuccessBox.propTypes = {
	children: PropTypes.node,
	integration: PropTypes.object,
};

export default IntegrationSuccessBox;
