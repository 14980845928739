import { useCallback, useMemo, useState } from 'react';

import { DataLoader } from '@asteria/core';

export function useDataLoaderCallback(callback, options = { period: 10000 }) {
	const callbackFn = useCallback(
		(...args) => {
			callback(args);
		},
		[callback],
	);

	const dataLoader = new DataLoader(callbackFn, options);

	return dataLoader.execute.bind(dataLoader);
}

export function useDataLoader(options = { period: 500 }) {
	const [data, setData] = useState([]);

	const setDataWithDataLoader = useDataLoaderCallback((args) => {
		setData((data) => data.concat(args));
	}, options);

	return [data, setDataWithDataLoader, setData];
}

export function useRequestLoader({ onSubmit }, options) {
	return useMemo(
		() => ({
			fn: new DataLoader(
				(...args) => onSubmit?.('data:loader', args),
				options ?? { period: 0 },
			),
			context: { waiting: true },
			onSuccess: (response) => ({ response }),
		}),
		[onSubmit, options],
	);
}
