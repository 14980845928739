import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';

import Chip from '@asteria/component-chip';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import BaseStep from './base';

const StepOS = React.memo(function StepOS(props) {
	const { onAction, onClose } = props;

	const [os, setOS] = React.useState('windows');

	const onAbort = React.useCallback((event) => onClose?.(event), [onClose]);
	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onNext = React.useCallback(
		async () =>
			onAction?.('go', `/onboarding/erp/printer/configuration/os/${os}/`),
		[os, onAction],
	);

	return (
		<BaseStep
			{...props}
			step={`os/${os}`}
			onAbort={onAbort}
			onBack={onBack}
			onNext={onNext}
		>
			<Group
				className={cn('py-4 -order-1', 'asteria-component__chip-group')}
				direction="horizontal"
				horizontalAlign="left"
			>
				<Chip
					size="sm"
					active={os === 'windows'}
					onClick={() => setOS('windows')}
					label={TranslationService.get(
						'onboarding.vprint.step.os.windows',
					)}
				/>
				<Chip
					size="sm"
					active={os === 'macosx'}
					onClick={() => setOS('macosx')}
					label={TranslationService.get(
						'onboarding.vprint.step.os.macosx',
					)}
				/>
			</Group>
		</BaseStep>
	);
});

StepOS.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

export default StepOS;
