import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Form, {
	Wrapper as FormWrapper,
	Input,
	Option,
	Select,
} from '@asteria/component-form';

import { Translation, TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import { DEFAULT_INTEGRATIONS } from './constants';

/**
 * @typedef Props
 * @property { string } className
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { import('react').MouseEventHandler } onClose
 * @property { string } type
 * @property { boolean } loading
 * @property { (form: unknown) => unknown } submit
 */

/** @type { React.FC<Props> } */
const MissingForm = React.memo(function MissingForm(props) {
	const { type = 'both', onClose, loading } = props;

	const form = useWatch();
	const { unregister } = useFormContext();

	React.useLayoutEffect(() => {
		Analytics.startFlow('integrations.missing.form', {
			integrationType: type,
		});

		return () => {
			Analytics.endFlow('integrations.missing.form');
		};
	}, [type]);

	const handleClose = React.useCallback(
		(event) => {
			Analytics.endFlow('integrations.missing.form');

			return onClose?.(event);
		},
		[onClose],
	);

	const integrations =
		useConfig([
			'widget.integrations.missing',
			`widget.integrations.${type}.missing`,
		]) ?? DEFAULT_INTEGRATIONS;

	React.useEffect(() => {
		if (form?.software !== 'custom') {
			unregister('customSoftware');
		}
	}, [form?.software, unregister]);

	return (
		<Wrapper
			className={cn(
				'asteria-component__onboarding-step',
				'asteria--variant-missing',
				'asteria--step-form',
			)}
		>
			<Header onClose={onClose}>
				{TranslationService.get([
					'integrations.missing.form.header',
					`integrations.missing.${type}.form.header`,
				])}
			</Header>
			<Content className="gap-4">
				<Translation
					translationKey={[
						'integrations.missing.form.content',
						`integrations.missing.${type}.form.content`,
					]}
					Component={Text}
					size="sm"
				/>

				<FormWrapper>
					<Content>
						<Select
							placeholder={TranslationService.get([
								'integrations.missing.form.placeholder',
								`integrations.missing.${type}.form.placeholder`,
							])}
							name="software"
						>
							{integrations.map((value) => (
								<Option key={value} value={value}>
									{TranslationService.get(
										[
											'integrations.list.item',
											`integrations.missing.form.integrations.${value}`,
										],
										'{{ name | cap }}',
										{ key: value, name: value },
									)}
								</Option>
							))}
							<Option value="custom">
								{TranslationService.get([
									'integrations.missing.form.integrations.custom',
									`integrations.missing.${type}.form.integrations.custom`,
								])}
							</Option>
						</Select>
						{form?.software === 'custom' && (
							<Input
								direction="vertical"
								type="text"
								name="customSoftware"
								placeholder={TranslationService.get([
									'integrations.missing.form.integrations.custom.placeholder',
									`integrations.missing.${type}.form.integrations.custom.placeholder`,
								])}
							/>
						)}
					</Content>
				</FormWrapper>
			</Content>
			<Footer>
				<FooterSection position="first">
					<Button
						variant="secondary"
						label={TranslationService.get('action.close')}
						onClick={handleClose}
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						type="submit"
						variant="primary"
						label={TranslationService.get('action.next')}
						loading={loading}
						disabled={
							loading ||
							!form?.software ||
							(form?.software === 'custom' &&
								!form?.customSoftware)
						}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

MissingForm.propTypes = {
	type: PropTypes.string,
	onClose: PropTypes.func,
	loading: PropTypes.bool,
};

/** @type { React.FC<Props> } */
const MissingFormWrapper = React.memo(function MissingFormWrapper(props) {
	const { submit } = props;

	return (
		<Form onSubmit={submit}>
			<MissingForm {...props} />
		</Form>
	);
});

MissingFormWrapper.propTypes = {
	submit: PropTypes.func,
	type: PropTypes.string,
	onClose: PropTypes.func,
	loading: PropTypes.bool,
};

export default MissingFormWrapper;
