import { useEffect, useRef, useState } from 'react';

export const useInterval = (callback, delay) => {
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
};

/**
 *
 * @param { number } length
 * @param { number } [timeout]
 * @returns { number }
 */
export function useIntervalIndex(length, timeout = 3_000) {
	const [index, setIndex] = useState(0);

	useEffect(() => {
		if (length < 2) {
			return;
		}

		setIndex(0);

		const timeoutRef = setInterval(() => {
			setIndex((index) => {
				if (index >= length - 1) {
					return 0;
				}

				return index + 1;
			});
		}, timeout);

		return () => {
			clearInterval(timeoutRef);
		};
	}, [length, timeout]);

	return index;
}
