import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';
import useCombinedRefs from '@asteria/utils-hooks/useCombinedRefs';

import Button from '../button';

const STEP = 200;

const Slider = React.memo(
	React.forwardRef(function Slider(props, ref) {
		const { className, children } = props;

		const [{ left, right }, dispatch] = React.useReducer(
			(state, action) => {
				switch (action?.type) {
					case 'UPDATE':
						return {
							...state,
							left: action?.payload?.left ?? state?.left,
							right: action?.payload?.right ?? state?.right,
						};

					default:
						return state;
				}
			},
			{ left: false, right: false },
		);

		const wrapperRef = React.useRef(null);
		const innerRef = React.useRef(null);

		const scrollingRef = useCombinedRefs(innerRef, ref);

		const clickLeft = React.useCallback(() => {
			innerRef?.current?.scrollBy?.({ left: -STEP, behavior: 'smooth' });
		}, []);

		const clickRight = React.useCallback(() => {
			innerRef?.current?.scrollBy?.({ left: STEP, behavior: 'smooth' });
		}, []);

		React.useLayoutEffect(() => {
			function callback(event) {
				const clientWidth = event.target.clientWidth;
				const scrollWidth = event.target.scrollWidth;
				const scrollLeft = event.target.scrollLeft;

				dispatch({
					type: 'UPDATE',
					payload: {
						left: scrollLeft > 0,
						right: scrollLeft + clientWidth < scrollWidth,
					},
				});
			}

			const node = innerRef.current;

			if (node) {
				callback({ target: node });
				node.addEventListener('scroll', callback);

				return () => {
					node.removeEventListener('scroll', callback);
				};
			}
		}, []);

		return (
			<div
				className={cn(
					'relative overflow-hidden',
					'asteria-component__slider',
				)}
				ref={wrapperRef}
			>
				<div
					className={cn(
						'absolute top-1/2 -translate-y-1/2 left-2 opacity-0 pointer-events-none cursor-pointer bg-white rounded-full shadow z-[1]',
						{ 'pointer-events-auto opacity-100': left },
					)}
					onClick={clickLeft}
				>
					<Button
						size="sm"
						className="pointer-events-none"
						icon="chevron-left"
					/>
				</div>

				<div
					className={cn(
						'flex gap-2 overflow-x-auto',
						'asteria-component__slider-content',
						className,
					)}
					ref={scrollingRef}
				>
					{children}
				</div>
				<div
					className={cn(
						'absolute top-1/2 -translate-y-1/2 right-2 opacity-0 pointer-events-none cursor-pointer bg-white rounded-full shadow z-[1]',
						{ 'pointer-events-auto opacity-100': right },
					)}
					onClick={clickRight}
				>
					<Button
						size="sm"
						className="pointer-events-none"
						icon="chevron-right"
					/>
				</div>
			</div>
		);
	}),
);

Slider.displayName = 'Slider';

Slider.propTypes = { className: PropTypes.string, children: PropTypes.node };

export default Slider;
