import { useLocation } from 'react-router-dom';

export function getBackgroundLocation(location) {
	if (location?.state?.backgroundLocation) {
		return getBackgroundLocation(location?.state?.backgroundLocation);
	}

	return location;
}

export function useBackgroundLocation() {
	const location = useLocation();

	return getBackgroundLocation(location);
}
