export function getRedirectURI(integration) {
	return integration?.config?.server?.urls?.REDIRECT?.[0]?.formattedHref;
}

export function openWindow(URI, { onError, onSuccess } = {}) {
	const win = window.open('', '_blank');

	if (!win) {
		onError?.(URI);
		return;
	}

	win.location = URI;
	win.focus();

	onSuccess?.(URI, win);
}
