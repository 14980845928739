/**
 * @param { string[] } data
 * @returns { string[] }
 */
export function variants(data) {
	function combine(prefix, elements) {
		combinations.push(prefix);
		for (let i = 0; i < elements.length; i++) {
			combine(
				prefix + (prefix ? '.' : '') + elements[i],
				elements.slice(i + 1),
			);
		}
	}

	const combinations = [];
	combine('', data);
	return combinations.filter((combination) => combination !== '');
}

/**
 * @template TValue
 * @param { TValue } source
 * @param { TValue } target
 * @param { () => string[] } getAvailable
 */
export function unifyOptions(source, target, getAvailable) {
	const merged = { ...(source ?? {}), ...(target ?? {}) };
	const response = [];

	// Build response in a single pass:
	for (const [key, val] of Object.entries(merged)) {
		if (!val) continue; // skip falsy

		// Handle array or single value
		const values = Array.isArray(val) ? val : [val];
		for (const v of values) {
			if (v) {
				// If v is true, the "option" is just the key, otherwise "key-value"
				response.push(v === true ? key : `${key}-${v}`);
			}
		}
	}

	// If a getAvailable function is provided, filter the response:
	if (getAvailable && response.length > 0) {
		const available = (getAvailable() ?? []).map((item) =>
			item.toLowerCase(),
		);

		return response.filter((r) => {
			const lowerR = r.toLowerCase();
			// Keep r if at least one available item includes r (case-insensitive)
			return available.some((a) => a.includes(lowerR));
		});
	}

	return response;
}

const availableOptionsCache = {};

/**
 * @param { Record<string, unknown> } translations
 * @param { string | string[] } $keys
 * @returns { { features: string[], prefix: string[], postfix: string[] } }
 */
export function getAvailableOptions(translations, $keys) {
	const keys = Array.isArray($keys) ? $keys : [$keys];

	for (const key of keys) {
		if (availableOptionsCache[key]) {
			continue;
		}

		const source = Object.keys(translations)
			.filter((source) => source.includes(key))
			.map((source) => {
				const [prefix, postfix] = source.split(key).map((key) => {
					if (key.startsWith('.')) {
						return key.slice(1);
					}

					if (key.endsWith('.')) {
						return key.slice(0, -1);
					}

					return key;
				});

				return { prefix, postfix };
			})
			.reduce(
				(acc, { prefix, postfix }) => {
					if (prefix) {
						for (const key of prefix.split('.')) {
							acc.prefix.add(key);
						}
						for (const key of prefix.split('.')) {
							acc.features.add(key);
						}
					}

					if (postfix) {
						acc.postfix.add(postfix);
					}

					return acc;
				},
				{
					features: new Set(),
					prefix: new Set(),
					postfix: new Set(),
				},
			);

		availableOptionsCache[key] = source;
	}

	const response = keys.reduce(
		(acc, key) => {
			const {
				features = [],
				prefix = [],
				postfix = [],
			} = availableOptionsCache[key] ?? {};

			acc.features.push(...Array.from(features));
			acc.prefix.push(...Array.from(prefix));
			acc.postfix.push(...Array.from(postfix));

			return acc;
		},
		{ features: [], prefix: [], postfix: [] },
	);

	return response;
}

export function getKeys(keys = [], options) {
	/**
	 * @param { string } key
	 * @param { boolean } [reverse]
	 */
	function format(key, reverse = false) {
		/**
		 * @param { string } value
		 */
		return (value) => (reverse ? [key, value] : [value, key]).join('.');
	}

	const features = unifyOptions(
		options?.default?.features,
		options?.features,
		options?.available?.features,
	);

	const postfix = unifyOptions(
		options?.default?.postfix,
		options?.postfix,
		options?.available?.postfix,
	);

	const prefix = unifyOptions(
		options?.default?.prefix,
		options?.prefix,
		options?.available?.prefix,
	);

	return []
		.concat(keys)
		.map((key) => key?.trim?.())
		.filter(Boolean)
		.flatMap((key) => [key, ...variants(features).map(format(key))])
		.flatMap((key) => [key, ...variants(postfix).map(format(key, true))])
		.flatMap((key) => [key, ...variants(prefix).map(format(key))]);
}
