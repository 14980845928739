import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Contenter from '@asteria/component-tools/contenter';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import OnboardingWrapper from '../../components/onboarding-wrapper';
import { GuideContext } from '../../context';
import { useFlow, useIntegrationName, usePlaceholder } from '../../hooks';

/**
 * @typedef Props
 * @property { string } className
 * @property { string } step
 * @property { string } os
 * @property { (action: string, data?: unknown) => void } onAction
 * @property { (action: string, data?: unknown) => void } onSubmit
 * @property { React.MouseEventHandler } onClose
 * @property { React.MouseEventHandler } onNext
 * @property { React.MouseEventHandler } onAbort
 * @property { React.MouseEventHandler } onDone
 * @property { React.MouseEventHandler } onBack
 * @property { boolean } loading
 * @property { unknown } data
 */

const type = 'erp';
const key = 'printer';

/** @type { React.FC<React.PropsWithChildren<Props>> } */
const BaseStep = React.memo(function BaseStep(props) {
	const {
		className,
		step,

		children,

		onAction,
		onClose,

		onNext,
		onAbort,
		onDone,
		onBack,

		loading,
		data: $data,

		os,
	} = props;

	const guided = React.useContext(GuideContext);

	const user = useSelector(AppStore.selectors.user, isEqual);

	const flow = useFlow(type, key);
	const placeholder = usePlaceholder(type, key, step);

	const contenterActions = React.useMemo(
		() => ({
			onAbort: onAbort,
			onBack: onBack,
			onNext: onNext,
			onAction: onAction,
		}),
		[onAction, onAbort, onBack, onNext],
	);

	const integrationName = useIntegrationName(flow?.type, flow?.key);
	const data = React.useMemo(() => ({ ...$data, user }), [$data, user]);

	const shouldShowFooterFirst = !!onAbort;
	const shouldShowFooterLast = !!onDone || !!onNext;

	return (
		<Wrapper
			className={cn(
				'asteria-component__onboarding-step',
				'asteria--variant-printer',
				className,
			)}
			variant="custom"
		>
			<Header onBack={onBack} onClose={onClose}>
				{TranslationService.get(
					[
						`integrations.add.title`,
						`integrations.${type}.add.title`,
						`integrations.${key}.add.title`,
						`integrations.${type}.${key}.add.title`,
						`integrations.${step}.title`,
						`integrations.${type}.${step}.title`,
						`integrations.${key}.${step}.title`,
						`integrations.${type}.${os}.${step}.title`,
						`integrations.${type}.${key}.${step}.title`,
					],
					undefined,
					{ name: integrationName },
				)}
			</Header>
			<OnboardingWrapper placeholder={placeholder}>
				<Content className="gap-4">
					<Contenter
						content={flow?.steps?.[step]?.content}
						actions={contenterActions}
						data={data}
					/>
					{children}
				</Content>
				<Footer>
					{shouldShowFooterFirst ? (
						<FooterSection position="first">
							{onAbort ? (
								<Button
									analyticsKey={`integrations.${key}.abort`}
									variant="tertiary"
									label={TranslationService.get(
										[
											'button.abort',
											'action.abort',
											`integrations.add.action.abort`,
											`integrations.${type}.add.action.abort`,
											`integrations.${key}.add.action.abort`,
											`integrations.${type}.${key}.add.action.abort`,
											`integrations.${step}.action.abort`,
											`integrations.${type}.${step}.action.abort`,
											`integrations.${key}.${step}.action.abort`,
											`integrations.${type}.${os}.${step}.action.abort`,
											`integrations.${type}.${key}.${step}.action.abort`,
										],
										undefined,
										{ name: integrationName },
									)}
									onClick={onAbort}
								/>
							) : null}
						</FooterSection>
					) : null}

					{shouldShowFooterLast ? (
						<FooterSection position="last">
							{onDone ? (
								<Button
									analyticsKey={`integrations.${key}.done`}
									variant="primary"
									label={TranslationService.get(
										[
											'button.done',
											'action.done',
											`integrations.add.action.done`,
											`integrations.${type}.add.action.done`,
											`integrations.${key}.add.action.done`,
											`integrations.${type}.${key}.add.action.done`,
											`integrations.${step}.action.done`,
											`integrations.${type}.${step}.action.done`,
											`integrations.${key}.${step}.action.done`,
											`integrations.${type}.${os}.${step}.action.done`,
											`integrations.${type}.${key}.${step}.action.done`,
										],
										undefined,
										{ name: integrationName },
									)}
									onClick={onDone}
									loading={loading}
									disabled={loading}
								/>
							) : null}

							{onNext ? (
								<Button
									analyticsKey={`integrations.${key}.continue`}
									variant="primary"
									label={TranslationService.get(
										[
											'button.continue',
											'action.continue',
											`integrations.add.action.continue`,
											`integrations.${type}.add.action.continue`,
											`integrations.${key}.add.action.continue`,
											`integrations.${type}.${key}.add.action.continue`,
											`integrations.${step}.action.continue`,
											`integrations.${type}.${step}.action.continue`,
											`integrations.${key}.${step}.action.continue`,
											`integrations.${type}.${os}.${step}.action.continue`,
											`integrations.${type}.${key}.${step}.action.continue`,
										],
										undefined,
										{ name: integrationName },
									)}
									onClick={onNext}
									loading={loading}
									disabled={loading}
								/>
							) : null}
						</FooterSection>
					) : null}
				</Footer>
				{guided.state && !!placeholder ? (
					<div className="asteria-component__onboarding-placeholder-wrapper">
						{placeholder}
					</div>
				) : null}
			</OnboardingWrapper>
		</Wrapper>
	);
});

BaseStep.displayName = 'BaseStep';

BaseStep.propTypes = {
	className: PropTypes.string,
	step: PropTypes.string,
	os: PropTypes.string,

	children: PropTypes.node,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,

	onAbort: PropTypes.func,
	onBack: PropTypes.func,
	onDone: PropTypes.func,
	onNext: PropTypes.func,

	loading: PropTypes.bool,
	data: PropTypes.object,
};

export default BaseStep;
