import React from 'react';

import { useQueries } from '@tanstack/react-query';
import {
	eachMonthOfInterval,
	endOfYear,
	formatISO,
	isSameMonth,
	startOfYear,
} from 'date-fns';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';
import { parseDate } from '@asteria/utils-funcs/normalize';
import { useRequestLoader } from '@asteria/utils-hooks/useDataLoader';

import { DateContext } from '../../../context';
import { useOnboardingState } from '../../../hooks';
import Month from '../../month';

function isActive(source, target) {
	return isSameMonth(parseDate(source), parseDate(target));
}

const Months = React.memo(function Months(props) {
	const { onAction, visibleDate, onSubmit } = props;
	const { value, startDate, endDate } = React.useContext(DateContext);

	const onboarding = useOnboardingState();

	let rangeStartDate = startOfYear(parseDate(visibleDate));
	let rangeEndDate = endOfYear(parseDate(visibleDate));

	if (startDate && endDate) {
		rangeStartDate = parseDate(startDate);
		rangeEndDate = parseDate(endDate);
	}

	const range = eachMonthOfInterval({
		start: rangeStartDate,
		end: rangeEndDate,
	}).map((date) => formatISO(date, { representation: 'date' }));

	const dataloader = useRequestLoader({ onSubmit });

	const queries = useQueries({
		queries: range.map((date) => ({
			queryKey: ['time-selector', date, 'statistics'],
			queryFn: async ({ signal, meta }) => {
				return onSubmit?.('time:selector:statistics', {
					date,
					signal,
					dataloader: meta?.dataloader,
				});
			},

			refetchOnMount: true,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,

			meta: { dataloader },
		})),
	});

	return (
		<div
			className={cn('grid gap-2', {
				'lg:grid-cols-1': range.length === 1,
				'lg:grid-cols-2': range.length === 2,
				'lg:grid-cols-3': range.length === 3,
				'lg:grid-cols-4': range.length >= 4,
			})}
		>
			{range.map((month, index) => {
				const query = queries[index];

				return (
					<Month
						key={month}
						date={month}
						onAction={onAction}
						balance={query?.data?.balance}
						trends={query?.data?.trends}
						health={query?.data?.health}
						loading={
							(query.isFetching && !query.isRefetching) ||
							onboarding === 'none'
						}
						active={isActive(month, value)}
					/>
				);
			})}
		</div>
	);
});

Months.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	visibleDate: PropTypes.string,
};

export default Months;
