import React from 'react';

import { useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { Translation, TranslationService } from '@asteria/language';

import { useIntegrationName } from '../hooks';

/** @type { React.FC<{ open: boolean, onClose: import('react').MouseEventHandler, id: string, onSubmit: () => void, onAction: () => void }> } */
const IntegrationRemoveModal = React.memo(function IntegrationRemoveModal(
	props,
) {
	const { open, onClose, id, onSubmit } = props;

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, id),
		isEqual,
	);

	const type = integration?.type;
	const key = integration?.key ?? integration?.name;

	const integrationName = useIntegrationName(type, key);

	const submit = useMutation({
		mutationFn: async () => onSubmit?.('integrations:delete', { id: id }),
		onSuccess: onClose,
	});

	return (
		<Modal open={open} onClose={onClose} size="sm">
			<Wrapper>
				<Header onClose={onClose}>
					{TranslationService.getV2(
						['modal.onboarding.remove.title'],
						{
							postfix: { type, key },
							data: { integration, integrationName },
						},
					)}
				</Header>
				<Content>
					<Translation
						translationKey="modal.onboarding.remove.content"
						translationOptions={{
							postfix: { type, key },
							data: { integration, integrationName },
						}}
						Component={Text}
					/>
				</Content>
				<Footer>
					<FooterSection position="first">
						<Button
							variant="tertiary"
							label={TranslationService.getV2(
								[
									'button.cancel',
									'action.cancel',
									'modal.onboarding.remove.button.action-cancel',
								],
								{
									postfix: { type, key },
									data: { integration, integrationName },
								},
							)}
							onClick={onClose}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							label={TranslationService.getV2(
								[
									'button.remove',
									'action.remove',
									'modal.onboarding.remove.button.action-remove',
								],
								{
									postfix: { type, key },
									data: { integration, integrationName },
								},
							)}
							onClick={submit.mutate}
							disabled={submit.isLoading}
							loading={submit.isLoading}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Modal>
	);
});

IntegrationRemoveModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	id: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default IntegrationRemoveModal;
