import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { Switch } from '@asteria/component-form-v2';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const LABEL = {
	position: 'left',
	label: (
		<Translation
			translationKey={['integrations.guide.switch.label']}
			Component={Text}
		/>
	),
};

const OnboardingGuideSwitch = React.memo(function OnboardingGuideSwitch(props) {
	const { active, onClick } = props;

	return (
		<FeatureFlag feature="onboarding-guided">
			<Switch
				className={cn('asteria-component__onboarding-guide-switch')}
				onChange={onClick}
				label={LABEL}
				value={active}
				uncontrolled
			/>
		</FeatureFlag>
	);
});

OnboardingGuideSwitch.displayName = 'OnboardingGuideSwitch';

OnboardingGuideSwitch.propTypes = {
	active: PropTypes.string,
	onClick: PropTypes.func,
};

export default OnboardingGuideSwitch;
