import React from 'react';

import { format, formatDistanceToNow, parseISO } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import ptLocale from 'date-fns/locale/pt';
import svLocale from 'date-fns/locale/sv';
import Mustache from 'mustache';
import PropTypes from 'prop-types';

import Contenter from '@asteria/component-tools/contenter';
import ComponentService from '@asteria/component-tools/contenter/service';

import { isObject } from '@asteria/utils-funcs/compare';
import formatNumber from '@asteria/utils-funcs/formatNumber';

import Formatter from './formatter';
import Default from './languages/default';
import { getAvailableOptions, unifyOptions, variants } from './utils';

const LOCALES = {
	en: enLocale,
	sv: svLocale,
	// br: ptLocale,
	// fr: frLocale,
	// es: esLocale,
	// de: deLocale,
	pt: ptLocale,
};

const FormattedMustache = Formatter(Mustache, {
	number: (
		inputNumber,
		invert,
		divide,
		skipSign,
		round = true,
		remainder = false,
		separator,
	) => {
		return formatNumber(inputNumber, invert, divide, skipSign, {
			round: round,
			defaultFractionPart: round ? '' : '.00',
			getRemainder: remainder
				? (scale) =>
						TranslationService.get(
							[
								'number.remainder',
								`number.remainder.scale.${scale}`,
							],
							'',
						)
				: null,
			separator: separator,
		});
	},
	mul: (val, times) => val * times,
	round: (val) => Math.round(val),
	toFixed: (val = null, fraction = 2) =>
		val !== null ? Number(val).toFixed(fraction) : '',
	lowercase: (val) => (!val && val !== 0 ? '' : val.toString().toLowerCase()),
	highlight: (val, tag = 'span', property = 'color') => {
		return `<${tag} class="asteria--state-highlight-${property}">${val}</${tag}>`;
	},
	cap: (val) =>
		!val && val !== 0
			? ''
			: val.toString()[0].toUpperCase() + val.toString().slice(1),
	capitalize: (val) =>
		!val && val !== 0
			? ''
			: val.toString()[0].toUpperCase() + val.toString().slice(1),
	currencyFlag: (currency) =>
		`<div class="currency-flag currency-flag-${currency.toLowerCase()}"></div>`,

	isEqual: (source, target) => source == target,
	first: (vals) => (vals ?? [])?.[0],
	last: (vals) => (vals ?? []).slice(-1)?.[0],
	empty: (value, defaultValue) => value ?? defaultValue,
	join: (vals, separator = '') => (vals ?? []).join(separator),
});

const Context = React.createContext({ translations: [] });

/**
 * @param { string | string[] } keys
 * @param { { postfix?: Record<string, boolean | string>, prefix?: Record<string, boolean | string>, features?: Record<string, boolean | string>, debug?: boolean } } [options]
 */
function getKeys(keys = [], options) {
	/**
	 * @param { string } key
	 * @param { boolean } [reverse]
	 */
	function format(key, reverse = false) {
		/**
		 * @param { string } value
		 */
		return (value) => (reverse ? [key, value] : [value, key]).join('.');
	}

	const features = unifyOptions(
		TranslationService._features,
		options?.features,
		!options?.all &&
			function () {
				const available = getAvailableOptions(
					TranslationService.translations,
					keys,
				);

				return available.features;
			},
	);

	const postfix = unifyOptions(
		TranslationService._postfix,
		options?.postfix,
		!options?.all &&
			function () {
				const available = getAvailableOptions(
					TranslationService.translations,
					keys,
					options,
				);

				return available.postfix;
			},
		options,
	);

	const prefix = unifyOptions(
		TranslationService._prefix,
		options?.prefix,
		!options?.all &&
			function () {
				const available = getAvailableOptions(
					TranslationService.translations,
					keys,
				);

				return available.prefix;
			},
	);

	return []
		.concat(keys)
		.map((key) => key?.trim?.())
		.filter(Boolean)
		.flatMap((key) => [key, ...variants(features).map(format(key))])
		.flatMap((key) => [key, ...variants(postfix).map(format(key, true))])
		.flatMap((key) => [key, ...variants(prefix).map(format(key))]);
}

function translateFn(defaultText, data) {
	return function () {
		return function (text, render) {
			const keys = text.split(',').map((value) => value.trim());

			return render(TranslationService.get(keys, defaultText, data));
		};
	};
}

function dateFormatter(d, f, o = {}, ...rest) {
	try {
		return format(
			d instanceof Date ? d : parseISO(d),
			f,
			{ ...o, locale: LOCALES[TranslationService.code] || svLocale },
			...rest,
		);
	} catch (e) {
		return '';
	}
}

function relativeDateFormatter(d, o = {}, ...rest) {
	try {
		return formatDistanceToNow(
			d instanceof Date ? d : parseISO(d),
			{ ...o, locale: LOCALES[TranslationService.code] || svLocale },
			...rest,
		);
	} catch (e) {
		return '';
	}
}

const TranslationService = {
	translations: { ...Default },
	_features: {},
	_postfix: {},
	_prefix: {},
	onRegister: null,
	code: 'sv',
	globals: {},

	clearGlobals() {
		TranslationService.globals = {};
	},

	updateGlobals(value) {
		TranslationService.globals = {
			...TranslationService.globals,
			...value,
		};
	},

	setGlobals(value) {
		TranslationService.globals = value;
	},

	/**
	 * @template TValue
	 * @param { TValue | TValue[] } value
	 * @returns {{ value: TValue, index: number, first: boolean, last: boolean }}
	 */
	toIterable: (value) =>
		[].concat(value).map((value, index, arr) => ({
			value: value,
			index: index,
			first: index === 0,
			'not-first': index !== 0,
			last: index === arr.length - 1,
			'not-last': index !== arr.length - 1,
		})),

	setTranslations: (translations) => {
		const cleanTranslations = Object.fromEntries(
			Object.entries(translations || {}).map(([key, value]) => [
				key.trim(),
				value,
			]),
		);
		TranslationService.translations = { ...Default, ...cleanTranslations };
	},

	postfix: {
		reset() {
			TranslationService._postfix = {};
		},
		set(key, value) {
			TranslationService._postfix[key] = value;
		},
		update(value) {
			TranslationService._postfix = {
				...TranslationService._postfix,
				...value,
			};
		},
	},

	prefix: {
		reset() {
			TranslationService._prefix = {};
		},
		set(key, value) {
			TranslationService._prefix[key] = value;
		},
		update(value) {
			TranslationService._prefix = {
				...TranslationService._prefix,
				...value,
			};
		},
	},

	/**
	 * @param { string | string[] } translationKey
	 * @param { { postfix?: Record<string, boolean | string>, prefix?: Record<string, boolean | string>, features?: Record<string, boolean | string>, debug?: boolean } } options
	 * @return { boolean }
	 */
	hasKey: (translationKey, options) => {
		const keys = getKeys(translationKey, options);

		const text = keys.reduce((translation, key) => {
			if (!key || !key.toLowerCase) {
				return translation;
			}

			if (
				TranslationService.translations &&
				TranslationService.translations[key.toLowerCase()] !== undefined
			) {
				return TranslationService.translations[key.toLowerCase()];
			}

			if (
				TranslationService.translations &&
				TranslationService.translations[key] !== undefined
			) {
				return TranslationService.translations[key];
			}

			return translation;
		}, '');

		if (!text) {
			return false;
		}

		return true;
	},
	/**
	 * @param { string | string[] } translationKey
	 * @param { string } [defaultText]
	 * @param { object } [data]
	 * @param { { postfix?: Record<string, boolean | string>, prefix?: Record<string, boolean | string>, features?: Record<string, boolean | string>, debug?: boolean } } [options]
	 * @returns { string | undefined }
	 */
	get: (translationKey = '', defaultText, data, options) => {
		const keys = getKeys(translationKey, options);

		if (options?.debug) {
			/* eslint-disable no-console */
			console.group();
			console.log('keys', keys);
			console.log(
				'keys:raw',
				getKeys(translationKey, { ...options, all: true }),
			);
			console.log('defaultText', defaultText);
			console.log('data', data);
			console.groupEnd();
			/* eslint-enable no-console */
		}

		if (typeof defaultText !== 'string' && !data) {
			data = defaultText;
			defaultText = keys?.[0];
		}

		if (!data) {
			data = {};
		}

		if (defaultText === undefined && typeof data === 'object') {
			defaultText = '{{ __key }}';
			data = { ...data, __key: keys[0] };
		}

		let usedKey = null;
		const keyMatches = {};

		const text = keys.reduce(
			(translation, key) => {
				if (!key || !key.toLowerCase) {
					return translation;
				}

				if (
					TranslationService.translations &&
					TranslationService.translations[key.toLowerCase()] !==
						undefined
				) {
					usedKey = key.toLowerCase();
					keyMatches[usedKey] =
						TranslationService.translations[key.toLowerCase()];
					return TranslationService.translations[key.toLowerCase()];
				}

				if (
					TranslationService.translations &&
					TranslationService.translations[key] !== undefined
				) {
					usedKey = key;
					keyMatches[usedKey] = TranslationService.translations[key];
					return TranslationService.translations[key];
				}

				return translation;
			},
			Array.isArray(defaultText)
				? defaultText[defaultText.length - 1]
				: defaultText,
		);

		if (options?.debug) {
			/* eslint-disable no-console */
			console.group();
			console.log('found key', usedKey);
			console.log('all matches', keyMatches);
			console.log('Result', text);
			console.groupEnd();
			/* eslint-enable no-console */
		}

		if (!text) {
			return text;
		}

		if (isObject(text) && text.type === 'contenter') {
			return <Contenter content={text.content} data={data} />;
		}

		if (Array.isArray(text)) {
			return text;
		}

		try {
			return FormattedMustache.render(text.toString(), {
				...data,
				globals: TranslationService.globals,
				translate: translateFn(defaultText, data),
				formatters: {
					date: dateFormatter,
					relativeDate: relativeDateFormatter,
				},
			});
		} catch (err) {
			/* eslint-disable */
			console.group('translation:error');
			console.warn(err);
			console.log(keys, text);
			console.groupEnd();
			/* eslint-enable */

			return null;
		}
	},

	/**
	 * @param { string | string[] } translationKey
	 * @param { { data?: unknown, default?: unknown, postfix?: Record<string, boolean | string>, prefix?: Record<string, boolean | string>, features?: Record<string, boolean | string>, debug?: boolean } } options
	 * @returns
	 */
	getV2: (translationKey, options) => {
		const keys = getKeys(translationKey, options);
		const data = options?.data ?? {};
		const defaultText = options?.default ?? keys[0];

		(Array.isArray(translationKey)
			? translationKey
			: [translationKey]
		).forEach((key) => {
			if (TranslationService.onRegister) {
				TranslationService.onRegister({
					key: key,
					options: {
						...options,
						postfix: Object.entries(options?.postfix ?? {}),
					},
				});
			}
		});

		if (options?.debug) {
			/* eslint-disable no-console */
			console.group();
			console.log('keys', keys);
			console.log('options', options);
			console.log(
				'keys:raw',
				getKeys(translationKey, { ...options, all: true }),
			);
			console.log('defaultText', defaultText);
			console.log('data', data);
			console.groupEnd();
			/* eslint-enable no-console */
		}

		const text = keys.reduce((translation, key) => {
			if (!key?.toLowerCase) {
				return translation;
			}

			const translations = TranslationService.translations;

			if (translations?.[key.toLowerCase()] !== undefined) {
				if (options?.debug) {
					console.log('usedkey', key);
				}

				return translations?.[key.toLowerCase()];
			}

			if (translations?.[key] !== undefined) {
				if (options?.debug) {
					console.log('usedkey', key);
				}
				return translations?.[key];
			}

			return translation;
		}, defaultText);

		if (!text) {
			return text;
		}

		if (isObject(text) && text.type === 'contenter') {
			return <Contenter content={text.content} data={data} />;
		}

		if (Array.isArray(text)) {
			return text;
		}

		if (options?.debug) {
			console.log('finalText', text);
		}

		try {
			return FormattedMustache.render(text.toString(), {
				...data,
				globals: TranslationService.globals,
				translate: translateFn(defaultText, data),
				formatters: {
					date: dateFormatter,
					relativeDate: relativeDateFormatter,
				},
			});
		} catch (err) {
			/* eslint-disable */
			console.group('translation:error');
			console.warn(err);
			console.log(keys, text);
			console.groupEnd();
			/* eslint-enable */

			return null;
		}
	},

	getLocale: () => LOCALES[TranslationService.code],
	getByObject: (obj = {}) => {
		const lookup = Object.fromEntries(
			Object.entries(obj?.lookup || {}).map(([key, obj]) => {
				return [key, TranslationService.getByObject(obj)];
			}),
		);

		return TranslationService.get(
			obj.key || obj,
			obj.default || obj.key || obj || '',
			{
				...(obj?.data || {}),
				lookup: lookup,
			},
		);
	},
};

export { TranslationService };

/** @type { React.FC<{ translationKey: string | string[], defaultText?: string, data: unknown, translationOptions: { postfix: unknown, data: unknown }, Component: string, show: boolean, showWhenEmpty: boolean }> } */
const Translation = React.memo(
	React.forwardRef(function Translation(props, ref) {
		const {
			translationKey,
			className,
			defaultText,
			data,
			translationOptions,
			Component = 'span',
			show = true,
			showWhenEmpty = true,
			...args
		} = props;

		const html = TranslationService.getV2(translationKey, {
			data: data,
			default: defaultText,
			...translationOptions,
		});

		const content = React.useMemo(() => ({ __html: html }), [html]);

		if (!show) {
			return null;
		}

		if (!showWhenEmpty && !html) {
			return null;
		}

		if (typeof html === 'object') {
			return html;
		}

		// eslint-disable-next-line react/no-danger
		return (
			<Component
				className={className}
				ref={ref}
				dangerouslySetInnerHTML={content}
				{...args}
			/>
		);
	}),
);

Translation.displayName = 'Translation';

Translation.propTypes = {
	className: PropTypes.string,
	translationKey: PropTypes.string,
	defaultText: PropTypes.string,
	data: PropTypes.object,
	Component: PropTypes.any,
	translationOptions: PropTypes.object,
	show: PropTypes.bool,
	showWhenEmpty: PropTypes.bool,
};

ComponentService.register('Translation', Translation, {
	translationKey: { type: 'string' },
	defaultText: { type: 'string' },
	data: { type: 'object' },
	Component: { type: 'component' },
	translationOptions: { type: 'object' },
	show: { type: 'boolean' },
	showWhenEmpty: { type: 'boolean' },
});

window.__ASTERIA_TRANSLATIONS__ = TranslationService;

export default Context;
export { Translation };
