import React from 'react';

import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

import * as AppStore from '@asteria/datalayer/stores/app';

import * as ThemeAPI from '../api/theme';

import { useTheme } from '@asteria/themes';

const ThemeGlobalStyle = createGlobalStyle`
	${({ style }) => style}
`;

/** @type { React.FC<React.PropsWithChildren<{ theme: string, partnerId: string }>> } */
const ThemeWrapper = React.memo(function ThemeWrapper(props) {
	const { children, theme, partnerId } = props;

	const accessToken = useSelector(AppStore.selectors.accessToken);
	const loggedIn = useSelector(
		(store) => AppStore.selectors.auth(store)?.loggedIn,
	);

	useTheme(theme);

	const query = useQuery({
		queryKey: [
			'widget',
			'theme',
			partnerId ?? 'NONE',
			accessToken ?? 'NONE',
			{ loggedIn },
		],
		queryFn: async ({ signal }) =>
			ThemeAPI.fetch({
				accessToken: accessToken,
				partnerId: partnerId,
				signal: signal,
			}),
		enabled: !!partnerId && !!loggedIn,
		placeholderData: '',
	});

	return (
		<>
			<ThemeGlobalStyle style={query?.data} />
			{children}
		</>
	);
});

ThemeWrapper.propTypes = {
	children: PropTypes.node,
	theme: PropTypes.string,
	partnerId: PropTypes.string,
};

export default ThemeWrapper;
