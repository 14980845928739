import React from 'react';

import { useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';

/** @type { React.FC<{ id: string, status: string, onSubmit: () => void }> } */
const FixButton = React.memo(function FixButton(props) {
	const { id, status, onAction } = props;

	const integration = useSelector((store) =>
		IntegrationStore.selectors.integration(store, id),
	);

	const actions = (integration?.actions ?? []).filter(
		(object) => object?.status === 'PENDING',
	);

	const type = integration?.type;
	const key = integration?.key ?? integration?.name;

	const click = useMutation({
		mutationFn: async () =>
			onAction?.('onboarding:fix', { id, status, integration }),
	});

	if (!actions?.length) {
		return null;
	}

	return (
		<Button
			variant="secondary"
			label={TranslationService.getV2(['onboarding.box.button'], {
				postfix: { action: 'fix', type, key, status },
			})}
			size="sm"
			onClick={click.mutate}
		/>
	);
});

FixButton.propTypes = {
	id: PropTypes.string,
	onSubmit: PropTypes.func,
	onAction: PropTypes.func,
	status: PropTypes.string,
};

export default FixButton;
