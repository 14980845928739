import React from 'react';

import PropTypes from 'prop-types';

import { useACL } from './hooks';

/**
 * @typedef Props
 * @property { React.ReactNode } children
 * @property { string | string[] } roles
 */

/** @type { React.FC<Props> } */
const ACL = React.memo(function ACL({ children, roles }) {
	const hasAccess = useACL(roles);

	if (!hasAccess) {
		return null;
	}

	return children;
});

ACL.propTypes = {
	children: PropTypes.node,
	roles: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
};

export default ACL;
