import React from 'react';

import PropTypes from 'prop-types';

import Feedback from '@asteria/component-feedback';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';

const IntegrationSuccessFeedback = React.memo(
	function IntegrationSuccessFeedback(props) {
		const { type, name, onAction } = props;

		const [showFeedback, setShowFeedback] = React.useState(true);

		const onFeedbackAction = React.useCallback(
			(action, data) => {
				if (data?.dismissed) {
					setShowFeedback(false);
				}

				return onAction?.(action, data);
			},
			[onAction],
		);

		const onFeedbackSupportRequest = React.useCallback(
			() => onAction?.('go', '/support'),
			[onAction],
		);

		return (
			<FeatureFlag feature="feedback">
				{showFeedback ? (
					<Feedback
						description={TranslationService.get([
							'feedback.text',
							'feedback.integration.text',
							`feedback.integration.${type}.text`,
							`feedback.integration.${type}.${name}.text`,
						])}
						feedbackKey={`add-${type}`}
						maxText={TranslationService.get([
							'feedback.rating.max',
							'feedback.integration.rating.max',
							`feedback.integration.${type}.rating.max`,
							`feedback.integration.${type}.${name}.rating.max`,
						])}
						minText={TranslationService.get([
							'feedback.rating.min',
							'feedback.integration.rating.min',
							`feedback.integration.${type}.rating.min`,
							`feedback.integration.${type}.${name}.rating.min`,
						])}
						values={[1, 2, 3, 4, 5]}
						size="lg"
						title=""
						onAction={onFeedbackAction}
						onSupportRequest={onFeedbackSupportRequest}
						onEnd={() => setShowFeedback(false)}
						validate
						type={type}
					/>
				) : null}
			</FeatureFlag>
		);
	},
);

IntegrationSuccessFeedback.propTypes = {
	type: PropTypes.string,
	onAction: PropTypes.func,
	name: PropTypes.string,
};

export default IntegrationSuccessFeedback;
