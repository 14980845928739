import React from 'react';

import { SnackbarWrapper } from '@asteria/component-snackbar';

import { LayoutContext } from '../context';

import * as LayoutHooks from './hooks';

const SnackbarActionWrapper = React.memo(function SnackbarActionWrapper() {
	const { onAction } = React.useContext(LayoutContext);

	const onSnackbarAction = LayoutHooks.snackbar.useAction({
		onAction: onAction,
	});

	return <SnackbarWrapper onAction={onSnackbarAction} />;
});

export default SnackbarActionWrapper;
