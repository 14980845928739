import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQueries } from '@tanstack/react-query';
import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import Loading from '@asteria/layout/loading';
import { decode } from '@asteria/utils-funcs/jwt';
import { useQueryDoneCallback } from '@asteria/utils-hooks/useQueryDoneCallback';

import * as CompanyAPI from '../api/company';
import * as IntegrationAPI from '../api/integrations';
import * as UserAPI from '../api/user';

/**
 * @typedef { import('../types').Props } Props
 */

/** @type { React.FC<React.PropsWithChildren<Props['datalayer']>> } */
const DatalayerWrapper = React.memo(function DatalayerWrapper(props) {
	const {
		children,
		partnerId,
		skip,

		loader = true,

		onUserLoad,
		onCompanyLoad,
		onIntegrationsLoad,

		useExtraRequest,
	} = props;

	const dispatch = useDispatch();

	const accessToken = useSelector(AppStore.selectors.accessToken);
	const companyId = decode(accessToken)?.companyId ?? null;
	const loggedIn = useSelector(
		(store) => AppStore.selectors.auth(store)?.loggedIn,
	);

	const [userQuery, companyQuery, integrationQuery] = useQueries({
		queries: [
			{
				queryKey: [
					'widget',
					'datalayer',
					partnerId ?? 'NONE',
					accessToken ?? 'NONE',
					{ loggedIn },
					'me',
				],
				queryFn: async ({ signal }) =>
					UserAPI.me({ accessToken, signal, partnerId }),
				enabled: !!loggedIn && !skip?.me,
			},
			{
				queryKey: [
					'widget',
					'datalayer',
					partnerId ?? 'NONE',
					accessToken ?? 'NONE',
					{ loggedIn },
					'company',
				],
				queryFn: async ({ signal }) =>
					CompanyAPI.fetch({ accessToken, signal, partnerId }),
				enabled: !!loggedIn && !skip?.company,
			},
			{
				queryKey: [
					'widget',
					'datalayer',
					partnerId ?? 'NONE',
					accessToken ?? 'NONE',
					{ loggedIn },
					'integrations',
				],
				queryFn: async ({ signal }) =>
					IntegrationAPI.fetch({ accessToken, signal, partnerId }),
				enabled: !!loggedIn && !skip?.integrations,
			},
		],
	});

	const isUserQueryReady = useQueryDoneCallback(
		userQuery,
		React.useCallback(
			($data) => {
				const data = cloneDeep($data);

				dispatch?.(AppStore.setUser(data));
				dispatch?.(AppStore.setUserCompanyId(companyId));

				onUserLoad?.(data);
			},
			[companyId, dispatch, onUserLoad],
		),
		!(!!loggedIn && !skip?.me),
	);

	const isCompanyQueryReady = useQueryDoneCallback(
		companyQuery,
		React.useCallback(
			($data) => {
				const data = cloneDeep($data);

				dispatch?.(AppStore.setCompany(data));
				onCompanyLoad?.(data);
			},
			[dispatch, onCompanyLoad],
		),
		!(!!loggedIn && !skip?.company),
	);

	const isIntegrationsQueryReady = useQueryDoneCallback(
		integrationQuery,
		React.useCallback(
			($data) => {
				const data = cloneDeep($data);

				dispatch?.(IntegrationStore.setIntegrations(data));
				onIntegrationsLoad?.(data);
			},
			[dispatch, onIntegrationsLoad],
		),
		!(!!loggedIn && !skip?.integrations),
	);

	const isExtraQueryReady =
		useExtraRequest?.({ partnerId, accessToken }) ?? true;

	const isReady =
		isUserQueryReady &&
		isCompanyQueryReady &&
		isIntegrationsQueryReady &&
		isExtraQueryReady;

	if (!isReady) {
		if (!loader) {
			return null;
		}

		return <Loading />;
	}

	return <>{children}</>;
});

DatalayerWrapper.propTypes = {
	children: PropTypes.node,

	partnerId: PropTypes.string,
	skip: PropTypes.object,
	loader: PropTypes.bool,

	onUserLoad: PropTypes.func,
	onCompanyLoad: PropTypes.func,
	onIntegrationsLoad: PropTypes.func,
	useExtraRequest: PropTypes.func,
};

export default DatalayerWrapper;
